:root {
  --ds-spacing: 20px;
  --ds-border-radius: 2px;
  --ds-icon-size: 24px;
  --ds-duration: 500ms;
  --ds-timing-function: cubic-bezier(0.35, 0, 0.25, 1);
  --ds-header-height: calc(2.85 * var(--ds-spacing));
  --ds-header-mobile-height: calc(4.25 * var(--ds-spacing));
  --ds-white: rgb(255, 255, 255);
  --ds-black: rgb(0, 0, 0);
  --ds-primary-50: #f9e2e3;
  --ds-primary-100: #efb7ba;
  --ds-primary-200: #e4888c;
  --ds-primary-300: #d9585e;
  --ds-primary-400: #d1343c;
  --ds-primary-500: #c91019;
  --ds-primary-600: #c30e16;
  --ds-primary-700: #bc0c12;
  --ds-primary-800: #b5090e;
  --ds-primary-900: #a90508;
  --ds-primary-A100: #ffd4d4;
  --ds-primary-A200: #ffa1a1;
  --ds-primary-A400: #ff6e6f;
  --ds-primary-A700: #ff5456;
  --ds-primary-contrast-50: #707070;
  --ds-primary-contrast-100: #707070;
  --ds-primary-contrast-200: #707070;
  --ds-primary-contrast-300: #ffffff;
  --ds-primary-contrast-400: #ffffff;
  --ds-primary-contrast-500: #ffffff;
  --ds-primary-contrast-600: #ffffff;
  --ds-primary-contrast-700: #ffffff;
  --ds-primary-contrast-800: #ffffff;
  --ds-primary-contrast-900: #ffffff;
  --ds-primary-contrast-A100: #707070;
  --ds-primary-contrast-A200: #707070;
  --ds-primary-contrast-A400: #ffffff;
  --ds-primary-contrast-A700: #ffffff;
  --ds-success-50: #e4f5e9;
  --ds-success-100: #bce5c8;
  --ds-success-200: #8fd4a4;
  --ds-success-300: #62c27f;
  --ds-success-400: #41b563;
  --ds-success-500: #1fa848;
  --ds-success-600: #1ba041;
  --ds-success-700: #179738;
  --ds-success-800: #128d30;
  --ds-success-900: #0a7d21;
  --ds-success-A100: #adffba;
  --ds-success-A200: #7aff8f;
  --ds-success-A400: #47ff64;
  --ds-success-A700: #2dff4e;
  --ds-success-contrast-50: #707070;
  --ds-success-contrast-100: #707070;
  --ds-success-contrast-200: #707070;
  --ds-success-contrast-300: #ffffff;
  --ds-success-contrast-400: #ffffff;
  --ds-success-contrast-500: #ffffff;
  --ds-success-contrast-600: #ffffff;
  --ds-success-contrast-700: #ffffff;
  --ds-success-contrast-800: #ffffff;
  --ds-success-contrast-900: #ffffff;
  --ds-success-contrast-A100: #707070;
  --ds-success-contrast-A200: #707070;
  --ds-success-contrast-A400: #ffffff;
  --ds-success-contrast-A700: #ffffff;
  --ds-warning-50: #fceee0;
  --ds-warning-100: #f8d5b3;
  --ds-warning-200: #f3b980;
  --ds-warning-300: #ee9d4d;
  --ds-warning-400: #ea8826;
  --ds-warning-500: #e67300;
  --ds-warning-600: #e36b00;
  --ds-warning-700: #df6000;
  --ds-warning-800: #db5600;
  --ds-warning-900: #d54300;
  --ds-warning-A100: #fffdfc;
  --ds-warning-A200: #ffd7c9;
  --ds-warning-A400: #ffb096;
  --ds-warning-A700: #ff9d7d;
  --ds-warning-contrast-50: #707070;
  --ds-warning-contrast-100: #707070;
  --ds-warning-contrast-200: #707070;
  --ds-warning-contrast-300: #ffffff;
  --ds-warning-contrast-400: #ffffff;
  --ds-warning-contrast-500: #ffffff;
  --ds-warning-contrast-600: #ffffff;
  --ds-warning-contrast-700: #ffffff;
  --ds-warning-contrast-800: #ffffff;
  --ds-warning-contrast-900: #ffffff;
  --ds-warning-contrast-A100: #707070;
  --ds-warning-contrast-A200: #707070;
  --ds-warning-contrast-A400: #ffffff;
  --ds-warning-contrast-A700: #ffffff;
  --ds-error-50: #ffe7e9;
  --ds-error-100: #ffc4c8;
  --ds-error-200: #ff9da4;
  --ds-error-300: #ff7680;
  --ds-error-400: #ff5864;
  --ds-error-500: #ff3b49;
  --ds-error-600: #ff3542;
  --ds-error-700: #ff2d39;
  --ds-error-800: #ff2631;
  --ds-error-900: #ff1921;
  --ds-error-A100: #ffffff;
  --ds-error-A200: #fffdfd;
  --ds-error-A400: #ffcacc;
  --ds-error-A700: #ffb1b3;
  --ds-error-contrast-50: #707070;
  --ds-error-contrast-100: #707070;
  --ds-error-contrast-200: #707070;
  --ds-error-contrast-300: #ffffff;
  --ds-error-contrast-400: #ffffff;
  --ds-error-contrast-500: #ffffff;
  --ds-error-contrast-600: #ffffff;
  --ds-error-contrast-700: #ffffff;
  --ds-error-contrast-800: #ffffff;
  --ds-error-contrast-900: #ffffff;
  --ds-error-contrast-A100: #707070;
  --ds-error-contrast-A200: #707070;
  --ds-error-contrast-A400: #ffffff;
  --ds-error-contrast-A700: #ffffff;
  --ds-info-50: #e4eefc;
  --ds-info-100: #bad5f8;
  --ds-info-200: #8db9f4;
  --ds-info-300: #5f9df0;
  --ds-info-400: #3c88ec;
  --ds-info-500: #1a73e9;
  --ds-info-600: #176be6;
  --ds-info-700: #1360e3;
  --ds-info-800: #0f56df;
  --ds-info-900: #0843d9;
  --ds-info-A100: #ffffff;
  --ds-info-A200: #cfdaff;
  --ds-info-A400: #9cb3ff;
  --ds-info-A700: #83a0ff;
  --ds-info-contrast-50: #707070;
  --ds-info-contrast-100: #707070;
  --ds-info-contrast-200: #707070;
  --ds-info-contrast-300: #ffffff;
  --ds-info-contrast-400: #ffffff;
  --ds-info-contrast-500: #ffffff;
  --ds-info-contrast-600: #ffffff;
  --ds-info-contrast-700: #ffffff;
  --ds-info-contrast-800: #ffffff;
  --ds-info-contrast-900: #ffffff;
  --ds-info-contrast-A100: #707070;
  --ds-info-contrast-A200: #707070;
  --ds-info-contrast-A400: #ffffff;
  --ds-info-contrast-A700: #ffffff;
  --ds-neutral-10: #f8f9fb;
  --ds-neutral-50: #ecedee;
  --ds-neutral-100: #ced2d5;
  --ds-neutral-200: #aeb5b9;
  --ds-neutral-300: #8e979c;
  --ds-neutral-400: #758087;
  --ds-neutral-500: #5d6a72;
  --ds-neutral-600: #55626a;
  --ds-neutral-700: #4b575f;
  --ds-neutral-800: #414d55;
  --ds-neutral-900: #303c42;
  --ds-neutral-A100: #93d9ff;
  --ds-neutral-A200: #60c7ff;
  --ds-neutral-A400: #2db5ff;
  --ds-neutral-A700: #14acff;
  --ds-neutral-contrast-50: #707070;
  --ds-neutral-contrast-100: #707070;
  --ds-neutral-contrast-200: #707070;
  --ds-neutral-contrast-300: #ffffff;
  --ds-neutral-contrast-400: #ffffff;
  --ds-neutral-contrast-500: #ffffff;
  --ds-neutral-contrast-600: #ffffff;
  --ds-neutral-contrast-700: #ffffff;
  --ds-neutral-contrast-800: #ffffff;
  --ds-neutral-contrast-900: #ffffff;
  --ds-neutral-contrast-A100: #707070;
  --ds-neutral-contrast-A200: #707070;
  --ds-neutral-contrast-A400: #ffffff;
  --ds-neutral-contrast-A700: #ffffff;
}

html {
  --mat-badge-text-font: Roboto;
  --mat-badge-line-height: 22px;
  --mat-badge-text-size: 12px;
  --mat-badge-text-weight: 600;
  --mat-badge-small-size-text-size: 9px;
  --mat-badge-small-size-line-height: 16px;
  --mat-badge-large-size-text-size: 24px;
  --mat-badge-large-size-line-height: 28px;
}

.mat-h1,
.mat-headline-5,
.mat-typography .mat-h1,
.mat-typography .mat-headline-5,
.mat-typography h1 {
  font: 500 1.875rem / 1 PalfingerHandelVersalien;
  letter-spacing: normal;
  margin: 0 0 16px;
}

.mat-h2,
.mat-headline-6,
.mat-typography .mat-h2,
.mat-typography .mat-headline-6,
.mat-typography h2 {
  font: 500 1.5rem / 1 PalfingerHandelVersalien;
  letter-spacing: normal;
  margin: 0 0 16px;
}

.mat-h3,
.mat-subtitle-1,
.mat-typography .mat-h3,
.mat-typography .mat-subtitle-1,
.mat-typography h3 {
  font: 400 1rem / 0.875 Roboto;
  letter-spacing: normal;
  margin: 0 0 16px;
}

.mat-h4,
.mat-body-1,
.mat-typography .mat-h4,
.mat-typography .mat-body-1,
.mat-typography h4 {
  font: 500 1rem / 1 Roboto;
  letter-spacing: normal;
  margin: 0 0 16px;
}

.mat-h5,
.mat-typography .mat-h5,
.mat-typography h5 {
  font: 400 calc(0.875rem * 0.83) / 1.57 Roboto;
  margin: 0 0 12px;
}

.mat-h6,
.mat-typography .mat-h6,
.mat-typography h6 {
  font: 400 calc(0.875rem * 0.67) / 1.57 Roboto;
  margin: 0 0 12px;
}

.mat-body-strong,
.mat-subtitle-2,
.mat-typography .mat-body-strong,
.mat-typography .mat-subtitle-2 {
  font: 500 14px / 22px Roboto, sans-serif;
  letter-spacing: 0.0071428571em;
}

.mat-body,
.mat-body-2,
.mat-typography .mat-body,
.mat-typography .mat-body-2,
.mat-typography {
  font: 400 0.875rem / 1.57 Roboto;
  letter-spacing: normal;
}
.mat-body p,
.mat-body-2 p,
.mat-typography .mat-body p,
.mat-typography .mat-body-2 p,
.mat-typography p {
  margin: 0 0 12px;
}

.mat-small,
.mat-caption,
.mat-typography .mat-small,
.mat-typography .mat-caption {
  font: 400 12px / 20px Roboto, sans-serif;
  letter-spacing: 0.0333333333em;
}

.mat-headline-1,
.mat-typography .mat-headline-1 {
  font: 300 96px / 96px Roboto, sans-serif;
  letter-spacing: -0.015625em;
  margin: 0 0 56px;
}

.mat-headline-2,
.mat-typography .mat-headline-2 {
  font: 300 60px / 60px Roboto, sans-serif;
  letter-spacing: -0.0083333333em;
  margin: 0 0 64px;
}

.mat-headline-3,
.mat-typography .mat-headline-3 {
  font: 400 48px / 50px Roboto, sans-serif;
  letter-spacing: normal;
  margin: 0 0 64px;
}

.mat-headline-4,
.mat-typography .mat-headline-4 {
  font: 400 34px / 40px Roboto, sans-serif;
  letter-spacing: 0.0073529412em;
  margin: 0 0 64px;
}

html {
  --mat-bottom-sheet-container-text-font: Roboto;
  --mat-bottom-sheet-container-text-line-height: 1.57;
  --mat-bottom-sheet-container-text-size: 0.875rem;
  --mat-bottom-sheet-container-text-tracking: normal;
  --mat-bottom-sheet-container-text-weight: 400;
}

html {
  --mat-legacy-button-toggle-label-text-font: Roboto;
  --mat-legacy-button-toggle-label-text-line-height: 1;
  --mat-legacy-button-toggle-label-text-size: 1rem;
  --mat-legacy-button-toggle-label-text-tracking: normal;
  --mat-legacy-button-toggle-label-text-weight: 500;
}
html {
  --mat-standard-button-toggle-label-text-font: Roboto;
  --mat-standard-button-toggle-label-text-line-height: 1;
  --mat-standard-button-toggle-label-text-size: 1rem;
  --mat-standard-button-toggle-label-text-tracking: normal;
  --mat-standard-button-toggle-label-text-weight: 500;
}

html {
  --mat-datepicker-calendar-text-font: Roboto;
  --mat-datepicker-calendar-text-size: 13px;
  --mat-datepicker-calendar-body-label-text-size: 14px;
  --mat-datepicker-calendar-body-label-text-weight: 500;
  --mat-datepicker-calendar-period-button-text-size: 14px;
  --mat-datepicker-calendar-period-button-text-weight: 500;
  --mat-datepicker-calendar-header-text-size: 11px;
  --mat-datepicker-calendar-header-text-weight: 500;
}

html {
  --mat-expansion-header-text-font: Roboto, sans-serif;
  --mat-expansion-header-text-size: 14px;
  --mat-expansion-header-text-weight: 500;
  --mat-expansion-header-text-line-height: inherit;
  --mat-expansion-header-text-tracking: inherit;
  --mat-expansion-container-text-font: Roboto;
  --mat-expansion-container-text-line-height: 1.57;
  --mat-expansion-container-text-size: 0.875rem;
  --mat-expansion-container-text-tracking: normal;
  --mat-expansion-container-text-weight: 400;
}

html {
  --mat-grid-list-tile-header-primary-text-size: 0.875rem;
  --mat-grid-list-tile-header-secondary-text-size: 12px;
  --mat-grid-list-tile-footer-primary-text-size: 0.875rem;
  --mat-grid-list-tile-footer-secondary-text-size: 12px;
}

html {
  --mat-stepper-container-text-font: Roboto;
  --mat-stepper-header-label-text-font: Roboto;
  --mat-stepper-header-label-text-size: 0.875rem;
  --mat-stepper-header-label-text-weight: 400;
  --mat-stepper-header-error-state-label-text-size: 1rem;
  --mat-stepper-header-selected-state-label-text-size: 1rem;
  --mat-stepper-header-selected-state-label-text-weight: 500;
}

html {
  --mat-toolbar-title-text-font: PalfingerHandelVersalien;
  --mat-toolbar-title-text-line-height: 1;
  --mat-toolbar-title-text-size: 1.5rem;
  --mat-toolbar-title-text-tracking: normal;
  --mat-toolbar-title-text-weight: 500;
}

html {
  --mat-tree-node-text-font: Roboto;
  --mat-tree-node-text-size: 0.875rem;
  --mat-tree-node-text-weight: 400;
}

html {
  --mat-option-label-text-font: Roboto;
  --mat-option-label-text-line-height: 1;
  --mat-option-label-text-size: 1rem;
  --mat-option-label-text-tracking: normal;
  --mat-option-label-text-weight: 500;
}

html {
  --mat-optgroup-label-text-font: Roboto;
  --mat-optgroup-label-text-line-height: 1;
  --mat-optgroup-label-text-size: 1rem;
  --mat-optgroup-label-text-tracking: normal;
  --mat-optgroup-label-text-weight: 500;
}

html {
  --mat-card-title-text-font: PalfingerHandelVersalien;
  --mat-card-title-text-line-height: 1;
  --mat-card-title-text-size: 1.5rem;
  --mat-card-title-text-tracking: normal;
  --mat-card-title-text-weight: 500;
  --mat-card-subtitle-text-font: Roboto, sans-serif;
  --mat-card-subtitle-text-line-height: 22px;
  --mat-card-subtitle-text-size: 14px;
  --mat-card-subtitle-text-tracking: 0.0071428571em;
  --mat-card-subtitle-text-weight: 500;
}

html {
  --mdc-plain-tooltip-supporting-text-font: Roboto, sans-serif;
  --mdc-plain-tooltip-supporting-text-size: 12px;
  --mdc-plain-tooltip-supporting-text-weight: 400;
  --mdc-plain-tooltip-supporting-text-tracking: 0.0333333333em;
}

html {
  --mdc-filled-text-field-label-text-font: Roboto;
  --mdc-filled-text-field-label-text-size: 1rem;
  --mdc-filled-text-field-label-text-tracking: normal;
  --mdc-filled-text-field-label-text-weight: 500;
}
html {
  --mdc-outlined-text-field-label-text-font: Roboto;
  --mdc-outlined-text-field-label-text-size: 1rem;
  --mdc-outlined-text-field-label-text-tracking: normal;
  --mdc-outlined-text-field-label-text-weight: 500;
}
html {
  --mat-form-field-container-text-font: Roboto;
  --mat-form-field-container-text-line-height: 1;
  --mat-form-field-container-text-size: 1rem;
  --mat-form-field-container-text-tracking: normal;
  --mat-form-field-container-text-weight: 500;
  --mat-form-field-outlined-label-text-populated-size: 1rem;
  --mat-form-field-subscript-text-font: Roboto, sans-serif;
  --mat-form-field-subscript-text-line-height: 20px;
  --mat-form-field-subscript-text-size: 12px;
  --mat-form-field-subscript-text-tracking: 0.0333333333em;
  --mat-form-field-subscript-text-weight: 400;
}

html {
  --mat-select-trigger-text-font: Roboto;
  --mat-select-trigger-text-line-height: 1;
  --mat-select-trigger-text-size: 1rem;
  --mat-select-trigger-text-tracking: normal;
  --mat-select-trigger-text-weight: 500;
}

html {
  --mdc-dialog-subhead-font: PalfingerHandelVersalien;
  --mdc-dialog-subhead-line-height: 1;
  --mdc-dialog-subhead-size: 1.5rem;
  --mdc-dialog-subhead-weight: 500;
  --mdc-dialog-subhead-tracking: normal;
  --mdc-dialog-supporting-text-font: Roboto;
  --mdc-dialog-supporting-text-line-height: 1;
  --mdc-dialog-supporting-text-size: 1rem;
  --mdc-dialog-supporting-text-weight: 500;
  --mdc-dialog-supporting-text-tracking: normal;
}
.mat-mdc-standard-chip {
  --mdc-chip-label-text-font: Roboto;
  --mdc-chip-label-text-line-height: 1.57;
  --mdc-chip-label-text-size: 0.875rem;
  --mdc-chip-label-text-tracking: normal;
  --mdc-chip-label-text-weight: 400;
}
html .mat-mdc-slide-toggle {
  --mat-switch-label-text-font: Roboto;
  --mat-switch-label-text-line-height: 1.57;
  --mat-switch-label-text-size: 0.875rem;
  --mat-switch-label-text-tracking: normal;
  --mat-switch-label-text-weight: 400;
}

html {
  --mat-radio-label-text-font: Roboto;
  --mat-radio-label-text-line-height: 1.57;
  --mat-radio-label-text-size: 0.875rem;
  --mat-radio-label-text-tracking: normal;
  --mat-radio-label-text-weight: 400;
}

html {
  --mdc-slider-label-label-text-font: Roboto, sans-serif;
  --mdc-slider-label-label-text-size: 14px;
  --mdc-slider-label-label-text-line-height: 22px;
  --mdc-slider-label-label-text-tracking: 0.0071428571em;
  --mdc-slider-label-label-text-weight: 500;
}

html {
  --mat-menu-item-label-text-font: Roboto;
  --mat-menu-item-label-text-size: 1rem;
  --mat-menu-item-label-text-tracking: normal;
  --mat-menu-item-label-text-line-height: 1;
  --mat-menu-item-label-text-weight: 500;
}

html {
  --mdc-list-list-item-label-text-font: Roboto;
  --mdc-list-list-item-label-text-line-height: 1;
  --mdc-list-list-item-label-text-size: 1rem;
  --mdc-list-list-item-label-text-tracking: normal;
  --mdc-list-list-item-label-text-weight: 500;
  --mdc-list-list-item-supporting-text-font: Roboto;
  --mdc-list-list-item-supporting-text-line-height: 1.57;
  --mdc-list-list-item-supporting-text-size: 0.875rem;
  --mdc-list-list-item-supporting-text-tracking: normal;
  --mdc-list-list-item-supporting-text-weight: 400;
  --mdc-list-list-item-trailing-supporting-text-font: Roboto, sans-serif;
  --mdc-list-list-item-trailing-supporting-text-line-height: 20px;
  --mdc-list-list-item-trailing-supporting-text-size: 12px;
  --mdc-list-list-item-trailing-supporting-text-tracking: 0.0333333333em;
  --mdc-list-list-item-trailing-supporting-text-weight: 400;
}
.mdc-list-group__subheader {
  font: 400 1rem / 0.875 Roboto;
  letter-spacing: normal;
}

html {
  --mat-paginator-container-text-font: Roboto, sans-serif;
  --mat-paginator-container-text-line-height: 20px;
  --mat-paginator-container-text-size: 12px;
  --mat-paginator-container-text-tracking: 0.0333333333em;
  --mat-paginator-container-text-weight: 400;
  --mat-paginator-select-trigger-text-size: 12px;
}

.mat-mdc-tab-header {
  --mat-tab-header-label-text-font: Roboto, sans-serif;
  --mat-tab-header-label-text-size: 14px;
  --mat-tab-header-label-text-tracking: 0.0892857143em;
  --mat-tab-header-label-text-line-height: 36px;
  --mat-tab-header-label-text-weight: 500;
}
html {
  --mat-checkbox-label-text-font: Roboto;
  --mat-checkbox-label-text-line-height: 1.57;
  --mat-checkbox-label-text-size: 0.875rem;
  --mat-checkbox-label-text-tracking: normal;
  --mat-checkbox-label-text-weight: 400;
}

html {
  --mdc-text-button-label-text-font: Roboto, sans-serif;
  --mdc-text-button-label-text-size: 14px;
  --mdc-text-button-label-text-tracking: 0.0892857143em;
  --mdc-text-button-label-text-weight: 500;
  --mdc-text-button-label-text-transform: none;
}
html {
  --mdc-filled-button-label-text-font: Roboto, sans-serif;
  --mdc-filled-button-label-text-size: 14px;
  --mdc-filled-button-label-text-tracking: 0.0892857143em;
  --mdc-filled-button-label-text-weight: 500;
  --mdc-filled-button-label-text-transform: none;
}
html {
  --mdc-protected-button-label-text-font: Roboto, sans-serif;
  --mdc-protected-button-label-text-size: 14px;
  --mdc-protected-button-label-text-tracking: 0.0892857143em;
  --mdc-protected-button-label-text-weight: 500;
  --mdc-protected-button-label-text-transform: none;
}
html {
  --mdc-outlined-button-label-text-font: Roboto, sans-serif;
  --mdc-outlined-button-label-text-size: 14px;
  --mdc-outlined-button-label-text-tracking: 0.0892857143em;
  --mdc-outlined-button-label-text-weight: 500;
  --mdc-outlined-button-label-text-transform: none;
}
html {
  --mdc-extended-fab-label-text-font: Roboto, sans-serif;
  --mdc-extended-fab-label-text-size: 14px;
  --mdc-extended-fab-label-text-tracking: 0.0892857143em;
  --mdc-extended-fab-label-text-weight: 500;
}
html {
  --mdc-snackbar-supporting-text-font: Roboto;
  --mdc-snackbar-supporting-text-line-height: 1.57;
  --mdc-snackbar-supporting-text-size: 0.875rem;
  --mdc-snackbar-supporting-text-weight: 400;
}

html {
  --mat-table-header-headline-font: Roboto, sans-serif;
  --mat-table-header-headline-line-height: 22px;
  --mat-table-header-headline-size: 14px;
  --mat-table-header-headline-weight: 500;
  --mat-table-header-headline-tracking: 0.0071428571em;
  --mat-table-row-item-label-text-font: Roboto;
  --mat-table-row-item-label-text-line-height: 1.57;
  --mat-table-row-item-label-text-size: 0.875rem;
  --mat-table-row-item-label-text-weight: 400;
  --mat-table-row-item-label-text-tracking: normal;
  --mat-table-footer-supporting-text-font: Roboto;
  --mat-table-footer-supporting-text-line-height: 1.57;
  --mat-table-footer-supporting-text-size: 0.875rem;
  --mat-table-footer-supporting-text-weight: 400;
  --mat-table-footer-supporting-text-tracking: normal;
}

html {
  --mat-ripple-color: rgba(0, 0, 0, 0.1);
}

html {
  --mat-option-selected-state-label-text-color: #c91019;
  --mat-option-label-text-color: rgba(0, 0, 0, 0.87);
  --mat-option-hover-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-option-focus-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-option-selected-state-layer-color: rgba(0, 0, 0, 0.04);
}

.mat-accent {
  --mat-option-selected-state-label-text-color: #5d6a72;
  --mat-option-label-text-color: rgba(0, 0, 0, 0.87);
  --mat-option-hover-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-option-focus-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-option-selected-state-layer-color: rgba(0, 0, 0, 0.04);
}

.mat-warn {
  --mat-option-selected-state-label-text-color: #e67300;
  --mat-option-label-text-color: rgba(0, 0, 0, 0.87);
  --mat-option-hover-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-option-focus-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-option-selected-state-layer-color: rgba(0, 0, 0, 0.04);
}

html {
  --mat-optgroup-label-text-color: rgba(0, 0, 0, 0.87);
}

html {
  --mat-full-pseudo-checkbox-selected-icon-color: #5d6a72;
  --mat-full-pseudo-checkbox-selected-checkmark-color: #fafafa;
  --mat-full-pseudo-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mat-full-pseudo-checkbox-disabled-selected-checkmark-color: #fafafa;
  --mat-full-pseudo-checkbox-disabled-unselected-icon-color: #b0b0b0;
  --mat-full-pseudo-checkbox-disabled-selected-icon-color: #b0b0b0;
}
html {
  --mat-minimal-pseudo-checkbox-selected-checkmark-color: #5d6a72;
  --mat-minimal-pseudo-checkbox-disabled-selected-checkmark-color: #b0b0b0;
}

.mat-primary {
  --mat-full-pseudo-checkbox-selected-icon-color: #c91019;
  --mat-full-pseudo-checkbox-selected-checkmark-color: #fafafa;
  --mat-full-pseudo-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mat-full-pseudo-checkbox-disabled-selected-checkmark-color: #fafafa;
  --mat-full-pseudo-checkbox-disabled-unselected-icon-color: #b0b0b0;
  --mat-full-pseudo-checkbox-disabled-selected-icon-color: #b0b0b0;
}
.mat-primary {
  --mat-minimal-pseudo-checkbox-selected-checkmark-color: #c91019;
  --mat-minimal-pseudo-checkbox-disabled-selected-checkmark-color: #b0b0b0;
}

.mat-accent {
  --mat-full-pseudo-checkbox-selected-icon-color: #5d6a72;
  --mat-full-pseudo-checkbox-selected-checkmark-color: #fafafa;
  --mat-full-pseudo-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mat-full-pseudo-checkbox-disabled-selected-checkmark-color: #fafafa;
  --mat-full-pseudo-checkbox-disabled-unselected-icon-color: #b0b0b0;
  --mat-full-pseudo-checkbox-disabled-selected-icon-color: #b0b0b0;
}
.mat-accent {
  --mat-minimal-pseudo-checkbox-selected-checkmark-color: #5d6a72;
  --mat-minimal-pseudo-checkbox-disabled-selected-checkmark-color: #b0b0b0;
}

.mat-warn {
  --mat-full-pseudo-checkbox-selected-icon-color: #e67300;
  --mat-full-pseudo-checkbox-selected-checkmark-color: #fafafa;
  --mat-full-pseudo-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mat-full-pseudo-checkbox-disabled-selected-checkmark-color: #fafafa;
  --mat-full-pseudo-checkbox-disabled-unselected-icon-color: #b0b0b0;
  --mat-full-pseudo-checkbox-disabled-selected-icon-color: #b0b0b0;
}
.mat-warn {
  --mat-minimal-pseudo-checkbox-selected-checkmark-color: #e67300;
  --mat-minimal-pseudo-checkbox-disabled-selected-checkmark-color: #b0b0b0;
}

html {
  --mat-app-background-color: #fafafa;
  --mat-app-text-color: rgba(0, 0, 0, 0.87);
  --mat-app-elevation-shadow-level-0: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-1: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-2: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-3: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-4: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-5: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 5px 8px 0px rgba(0, 0, 0, 0.14), 0px 1px 14px 0px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-6: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-7: 0px 4px 5px -2px rgba(0, 0, 0, 0.2), 0px 7px 10px 1px rgba(0, 0, 0, 0.14), 0px 2px 16px 1px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-8: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-9: 0px 5px 6px -3px rgba(0, 0, 0, 0.2), 0px 9px 12px 1px rgba(0, 0, 0, 0.14), 0px 3px 16px 2px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-10: 0px 6px 6px -3px rgba(0, 0, 0, 0.2), 0px 10px 14px 1px rgba(0, 0, 0, 0.14), 0px 4px 18px 3px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-11: 0px 6px 7px -4px rgba(0, 0, 0, 0.2), 0px 11px 15px 1px rgba(0, 0, 0, 0.14), 0px 4px 20px 3px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-12: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-13: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 13px 19px 2px rgba(0, 0, 0, 0.14), 0px 5px 24px 4px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-14: 0px 7px 9px -4px rgba(0, 0, 0, 0.2), 0px 14px 21px 2px rgba(0, 0, 0, 0.14), 0px 5px 26px 4px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-15: 0px 8px 9px -5px rgba(0, 0, 0, 0.2), 0px 15px 22px 2px rgba(0, 0, 0, 0.14), 0px 6px 28px 5px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-16: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-17: 0px 8px 11px -5px rgba(0, 0, 0, 0.2), 0px 17px 26px 2px rgba(0, 0, 0, 0.14), 0px 6px 32px 5px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-18: 0px 9px 11px -5px rgba(0, 0, 0, 0.2), 0px 18px 28px 2px rgba(0, 0, 0, 0.14), 0px 7px 34px 6px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-19: 0px 9px 12px -6px rgba(0, 0, 0, 0.2), 0px 19px 29px 2px rgba(0, 0, 0, 0.14), 0px 7px 36px 6px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-20: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 20px 31px 3px rgba(0, 0, 0, 0.14), 0px 8px 38px 7px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-21: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 21px 33px 3px rgba(0, 0, 0, 0.14), 0px 8px 40px 7px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-22: 0px 10px 14px -6px rgba(0, 0, 0, 0.2), 0px 22px 35px 3px rgba(0, 0, 0, 0.14), 0px 8px 42px 7px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-23: 0px 11px 14px -7px rgba(0, 0, 0, 0.2), 0px 23px 36px 3px rgba(0, 0, 0, 0.14), 0px 9px 44px 8px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-24: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
}

html {
  --mat-option-label-text-font: Roboto;
  --mat-option-label-text-line-height: 1;
  --mat-option-label-text-size: 1rem;
  --mat-option-label-text-tracking: normal;
  --mat-option-label-text-weight: 500;
}

html {
  --mat-optgroup-label-text-font: Roboto;
  --mat-optgroup-label-text-line-height: 1;
  --mat-optgroup-label-text-size: 1rem;
  --mat-optgroup-label-text-tracking: normal;
  --mat-optgroup-label-text-weight: 500;
}

html {
  --mdc-elevated-card-container-shape: 4px;
}
html {
  --mdc-outlined-card-container-shape: 4px;
  --mdc-outlined-card-outline-width: 1px;
}
html {
  --mdc-elevated-card-container-color: white;
  --mdc-elevated-card-container-elevation: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}
html {
  --mdc-outlined-card-container-color: white;
  --mdc-outlined-card-outline-color: rgba(0, 0, 0, 0.12);
  --mdc-outlined-card-container-elevation: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
html {
  --mat-card-subtitle-text-color: rgba(0, 0, 0, 0.54);
}

html {
  --mat-card-title-text-font: PalfingerHandelVersalien;
  --mat-card-title-text-line-height: 1;
  --mat-card-title-text-size: 1.5rem;
  --mat-card-title-text-tracking: normal;
  --mat-card-title-text-weight: 500;
  --mat-card-subtitle-text-font: Roboto, sans-serif;
  --mat-card-subtitle-text-line-height: 22px;
  --mat-card-subtitle-text-size: 14px;
  --mat-card-subtitle-text-tracking: 0.0071428571em;
  --mat-card-subtitle-text-weight: 500;
}

html {
  --mdc-linear-progress-active-indicator-height: 4px;
  --mdc-linear-progress-track-height: 4px;
  --mdc-linear-progress-track-shape: 0;
}

.mat-mdc-progress-bar {
  --mdc-linear-progress-active-indicator-color: #c91019;
  --mdc-linear-progress-track-color: rgba(201, 16, 25, 0.25);
}
.mat-mdc-progress-bar.mat-accent {
  --mdc-linear-progress-active-indicator-color: #5d6a72;
  --mdc-linear-progress-track-color: rgba(93, 106, 114, 0.25);
}
.mat-mdc-progress-bar.mat-warn {
  --mdc-linear-progress-active-indicator-color: #e67300;
  --mdc-linear-progress-track-color: rgba(230, 115, 0, 0.25);
}

html {
  --mdc-plain-tooltip-container-shape: 4px;
  --mdc-plain-tooltip-supporting-text-line-height: 16px;
}

html {
  --mdc-plain-tooltip-container-color: #616161;
  --mdc-plain-tooltip-supporting-text-color: #fff;
}

html {
  --mdc-plain-tooltip-supporting-text-font: Roboto, sans-serif;
  --mdc-plain-tooltip-supporting-text-size: 12px;
  --mdc-plain-tooltip-supporting-text-weight: 400;
  --mdc-plain-tooltip-supporting-text-tracking: 0.0333333333em;
}

html {
  --mdc-filled-text-field-active-indicator-height: 1px;
  --mdc-filled-text-field-focus-active-indicator-height: 2px;
  --mdc-filled-text-field-container-shape: 4px;
}
html {
  --mdc-outlined-text-field-outline-width: 1px;
  --mdc-outlined-text-field-focus-outline-width: 2px;
  --mdc-outlined-text-field-container-shape: 4px;
}
html {
  --mdc-filled-text-field-caret-color: #c91019;
  --mdc-filled-text-field-focus-active-indicator-color: #c91019;
  --mdc-filled-text-field-focus-label-text-color: rgba(201, 16, 25, 0.87);
  --mdc-filled-text-field-container-color: rgb(244.8, 244.8, 244.8);
  --mdc-filled-text-field-disabled-container-color: rgb(249.9, 249.9, 249.9);
  --mdc-filled-text-field-label-text-color: rgba(0, 0, 0, 0.6);
  --mdc-filled-text-field-hover-label-text-color: rgba(0, 0, 0, 0.6);
  --mdc-filled-text-field-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-filled-text-field-input-text-color: rgba(0, 0, 0, 0.87);
  --mdc-filled-text-field-disabled-input-text-color: rgba(0, 0, 0, 0.38);
  --mdc-filled-text-field-input-text-placeholder-color: rgba(0, 0, 0, 0.6);
  --mdc-filled-text-field-error-hover-label-text-color: #e67300;
  --mdc-filled-text-field-error-focus-label-text-color: #e67300;
  --mdc-filled-text-field-error-label-text-color: #e67300;
  --mdc-filled-text-field-error-caret-color: #e67300;
  --mdc-filled-text-field-active-indicator-color: rgba(0, 0, 0, 0.42);
  --mdc-filled-text-field-disabled-active-indicator-color: rgba(0, 0, 0, 0.06);
  --mdc-filled-text-field-hover-active-indicator-color: rgba(0, 0, 0, 0.87);
  --mdc-filled-text-field-error-active-indicator-color: #e67300;
  --mdc-filled-text-field-error-focus-active-indicator-color: #e67300;
  --mdc-filled-text-field-error-hover-active-indicator-color: #e67300;
}
html {
  --mdc-outlined-text-field-caret-color: #c91019;
  --mdc-outlined-text-field-focus-outline-color: #c91019;
  --mdc-outlined-text-field-focus-label-text-color: rgba(201, 16, 25, 0.87);
  --mdc-outlined-text-field-label-text-color: rgba(0, 0, 0, 0.6);
  --mdc-outlined-text-field-hover-label-text-color: rgba(0, 0, 0, 0.6);
  --mdc-outlined-text-field-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-outlined-text-field-input-text-color: rgba(0, 0, 0, 0.87);
  --mdc-outlined-text-field-disabled-input-text-color: rgba(0, 0, 0, 0.38);
  --mdc-outlined-text-field-input-text-placeholder-color: rgba(0, 0, 0, 0.6);
  --mdc-outlined-text-field-error-caret-color: #e67300;
  --mdc-outlined-text-field-error-focus-label-text-color: #e67300;
  --mdc-outlined-text-field-error-label-text-color: #e67300;
  --mdc-outlined-text-field-error-hover-label-text-color: #e67300;
  --mdc-outlined-text-field-outline-color: rgba(0, 0, 0, 0.38);
  --mdc-outlined-text-field-disabled-outline-color: rgba(0, 0, 0, 0.06);
  --mdc-outlined-text-field-hover-outline-color: rgba(0, 0, 0, 0.87);
  --mdc-outlined-text-field-error-focus-outline-color: #e67300;
  --mdc-outlined-text-field-error-hover-outline-color: #e67300;
  --mdc-outlined-text-field-error-outline-color: #e67300;
}
html {
  --mat-form-field-focus-select-arrow-color: rgba(201, 16, 25, 0.87);
  --mat-form-field-disabled-input-text-placeholder-color: rgba(0, 0, 0, 0.38);
  --mat-form-field-state-layer-color: rgba(0, 0, 0, 0.87);
  --mat-form-field-error-text-color: #e67300;
  --mat-form-field-select-option-text-color: inherit;
  --mat-form-field-select-disabled-option-text-color: GrayText;
  --mat-form-field-leading-icon-color: unset;
  --mat-form-field-disabled-leading-icon-color: unset;
  --mat-form-field-trailing-icon-color: unset;
  --mat-form-field-disabled-trailing-icon-color: unset;
  --mat-form-field-error-focus-trailing-icon-color: unset;
  --mat-form-field-error-hover-trailing-icon-color: unset;
  --mat-form-field-error-trailing-icon-color: unset;
  --mat-form-field-enabled-select-arrow-color: rgba(0, 0, 0, 0.54);
  --mat-form-field-disabled-select-arrow-color: rgba(0, 0, 0, 0.38);
  --mat-form-field-hover-state-layer-opacity: 0.04;
  --mat-form-field-focus-state-layer-opacity: 0.08;
}

.mat-mdc-form-field.mat-accent {
  --mdc-filled-text-field-caret-color: #5d6a72;
  --mdc-filled-text-field-focus-active-indicator-color: #5d6a72;
  --mdc-filled-text-field-focus-label-text-color: rgba(93, 106, 114, 0.87);
}
.mat-mdc-form-field.mat-accent {
  --mdc-outlined-text-field-caret-color: #5d6a72;
  --mdc-outlined-text-field-focus-outline-color: #5d6a72;
  --mdc-outlined-text-field-focus-label-text-color: rgba(93, 106, 114, 0.87);
}
.mat-mdc-form-field.mat-accent {
  --mat-form-field-focus-select-arrow-color: rgba(93, 106, 114, 0.87);
}

.mat-mdc-form-field.mat-warn {
  --mdc-filled-text-field-caret-color: #e67300;
  --mdc-filled-text-field-focus-active-indicator-color: #e67300;
  --mdc-filled-text-field-focus-label-text-color: rgba(230, 115, 0, 0.87);
}
.mat-mdc-form-field.mat-warn {
  --mdc-outlined-text-field-caret-color: #e67300;
  --mdc-outlined-text-field-focus-outline-color: #e67300;
  --mdc-outlined-text-field-focus-label-text-color: rgba(230, 115, 0, 0.87);
}
.mat-mdc-form-field.mat-warn {
  --mat-form-field-focus-select-arrow-color: rgba(230, 115, 0, 0.87);
}

html {
  --mat-form-field-container-height: 56px;
  --mat-form-field-filled-label-display: block;
  --mat-form-field-container-vertical-padding: 16px;
  --mat-form-field-filled-with-label-container-padding-top: 24px;
  --mat-form-field-filled-with-label-container-padding-bottom: 8px;
}

html {
  --mdc-filled-text-field-label-text-font: Roboto;
  --mdc-filled-text-field-label-text-size: 1rem;
  --mdc-filled-text-field-label-text-tracking: normal;
  --mdc-filled-text-field-label-text-weight: 500;
}
html {
  --mdc-outlined-text-field-label-text-font: Roboto;
  --mdc-outlined-text-field-label-text-size: 1rem;
  --mdc-outlined-text-field-label-text-tracking: normal;
  --mdc-outlined-text-field-label-text-weight: 500;
}
html {
  --mat-form-field-container-text-font: Roboto;
  --mat-form-field-container-text-line-height: 1;
  --mat-form-field-container-text-size: 1rem;
  --mat-form-field-container-text-tracking: normal;
  --mat-form-field-container-text-weight: 500;
  --mat-form-field-outlined-label-text-populated-size: 1rem;
  --mat-form-field-subscript-text-font: Roboto, sans-serif;
  --mat-form-field-subscript-text-line-height: 20px;
  --mat-form-field-subscript-text-size: 12px;
  --mat-form-field-subscript-text-tracking: 0.0333333333em;
  --mat-form-field-subscript-text-weight: 400;
}

html {
  --mat-select-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}

html {
  --mat-select-panel-background-color: white;
  --mat-select-enabled-trigger-text-color: rgba(0, 0, 0, 0.87);
  --mat-select-disabled-trigger-text-color: rgba(0, 0, 0, 0.38);
  --mat-select-placeholder-text-color: rgba(0, 0, 0, 0.6);
  --mat-select-enabled-arrow-color: rgba(0, 0, 0, 0.54);
  --mat-select-disabled-arrow-color: rgba(0, 0, 0, 0.38);
  --mat-select-focused-arrow-color: rgba(201, 16, 25, 0.87);
  --mat-select-invalid-arrow-color: rgba(230, 115, 0, 0.87);
}
html .mat-mdc-form-field.mat-accent {
  --mat-select-panel-background-color: white;
  --mat-select-enabled-trigger-text-color: rgba(0, 0, 0, 0.87);
  --mat-select-disabled-trigger-text-color: rgba(0, 0, 0, 0.38);
  --mat-select-placeholder-text-color: rgba(0, 0, 0, 0.6);
  --mat-select-enabled-arrow-color: rgba(0, 0, 0, 0.54);
  --mat-select-disabled-arrow-color: rgba(0, 0, 0, 0.38);
  --mat-select-focused-arrow-color: rgba(93, 106, 114, 0.87);
  --mat-select-invalid-arrow-color: rgba(230, 115, 0, 0.87);
}
html .mat-mdc-form-field.mat-warn {
  --mat-select-panel-background-color: white;
  --mat-select-enabled-trigger-text-color: rgba(0, 0, 0, 0.87);
  --mat-select-disabled-trigger-text-color: rgba(0, 0, 0, 0.38);
  --mat-select-placeholder-text-color: rgba(0, 0, 0, 0.6);
  --mat-select-enabled-arrow-color: rgba(0, 0, 0, 0.54);
  --mat-select-disabled-arrow-color: rgba(0, 0, 0, 0.38);
  --mat-select-focused-arrow-color: rgba(230, 115, 0, 0.87);
  --mat-select-invalid-arrow-color: rgba(230, 115, 0, 0.87);
}

html {
  --mat-select-arrow-transform: translateY(-8px);
}

html {
  --mat-select-trigger-text-font: Roboto;
  --mat-select-trigger-text-line-height: 1;
  --mat-select-trigger-text-size: 1rem;
  --mat-select-trigger-text-tracking: normal;
  --mat-select-trigger-text-weight: 500;
}

html {
  --mat-autocomplete-container-shape: 4px;
  --mat-autocomplete-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}

html {
  --mat-autocomplete-background-color: white;
}

html {
  --mdc-dialog-container-shape: 4px;
}
html {
  --mat-dialog-container-elevation-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
  --mat-dialog-container-max-width: 80vw;
  --mat-dialog-container-small-max-width: 80vw;
  --mat-dialog-container-min-width: 0;
  --mat-dialog-actions-alignment: start;
  --mat-dialog-actions-padding: 8px;
  --mat-dialog-content-padding: 20px 24px;
  --mat-dialog-with-actions-content-padding: 20px 24px;
  --mat-dialog-headline-padding: 0 24px 9px;
}

html {
  --mdc-dialog-container-color: white;
  --mdc-dialog-subhead-color: rgba(0, 0, 0, 0.87);
  --mdc-dialog-supporting-text-color: rgba(0, 0, 0, 0.6);
}
html {
  --mdc-dialog-subhead-font: PalfingerHandelVersalien;
  --mdc-dialog-subhead-line-height: 1;
  --mdc-dialog-subhead-size: 1.5rem;
  --mdc-dialog-subhead-weight: 500;
  --mdc-dialog-subhead-tracking: normal;
  --mdc-dialog-supporting-text-font: Roboto;
  --mdc-dialog-supporting-text-line-height: 1;
  --mdc-dialog-supporting-text-size: 1rem;
  --mdc-dialog-supporting-text-weight: 500;
  --mdc-dialog-supporting-text-tracking: normal;
}
.mat-mdc-standard-chip {
  --mdc-chip-container-shape-radius: 16px;
  --mdc-chip-with-avatar-avatar-shape-radius: 14px;
  --mdc-chip-with-avatar-avatar-size: 28px;
  --mdc-chip-with-icon-icon-size: 18px;
  --mdc-chip-outline-width: 0;
  --mdc-chip-outline-color: transparent;
  --mdc-chip-disabled-outline-color: transparent;
  --mdc-chip-focus-outline-color: transparent;
  --mdc-chip-hover-state-layer-opacity: 0.04;
  --mdc-chip-with-avatar-disabled-avatar-opacity: 1;
  --mdc-chip-flat-selected-outline-width: 0;
  --mdc-chip-selected-hover-state-layer-opacity: 0.04;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-opacity: 1;
  --mdc-chip-with-icon-disabled-icon-opacity: 1;
}
.mat-mdc-standard-chip {
  --mat-chip-disabled-container-opacity: 0.4;
  --mat-chip-trailing-action-opacity: 0.54;
  --mat-chip-trailing-action-focus-opacity: 1;
  --mat-chip-trailing-action-state-layer-color: transparent;
  --mat-chip-selected-trailing-action-state-layer-color: transparent;
  --mat-chip-trailing-action-hover-state-layer-opacity: 0;
  --mat-chip-trailing-action-focus-state-layer-opacity: 0;
}

.mat-mdc-standard-chip {
  --mdc-chip-disabled-label-text-color: #212121;
  --mdc-chip-elevated-container-color: rgb(224.4, 224.4, 224.4);
  --mdc-chip-elevated-selected-container-color: rgb(224.4, 224.4, 224.4);
  --mdc-chip-elevated-disabled-container-color: rgb(224.4, 224.4, 224.4);
  --mdc-chip-flat-disabled-selected-container-color: rgb(224.4, 224.4, 224.4);
  --mdc-chip-focus-state-layer-color: black;
  --mdc-chip-hover-state-layer-color: black;
  --mdc-chip-selected-hover-state-layer-color: black;
  --mdc-chip-focus-state-layer-opacity: 0.12;
  --mdc-chip-selected-focus-state-layer-color: black;
  --mdc-chip-selected-focus-state-layer-opacity: 0.12;
  --mdc-chip-label-text-color: #212121;
  --mdc-chip-selected-label-text-color: #212121;
  --mdc-chip-with-icon-icon-color: #212121;
  --mdc-chip-with-icon-disabled-icon-color: #212121;
  --mdc-chip-with-icon-selected-icon-color: #212121;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: #212121;
  --mdc-chip-with-trailing-icon-trailing-icon-color: #212121;
}
.mat-mdc-standard-chip {
  --mat-chip-selected-disabled-trailing-icon-color: #212121;
  --mat-chip-selected-trailing-icon-color: #212121;
}
.mat-mdc-standard-chip.mat-mdc-chip-selected.mat-primary, .mat-mdc-standard-chip.mat-mdc-chip-highlighted.mat-primary {
  --mdc-chip-disabled-label-text-color: white;
  --mdc-chip-elevated-container-color: #c91019;
  --mdc-chip-elevated-selected-container-color: #c91019;
  --mdc-chip-elevated-disabled-container-color: #c91019;
  --mdc-chip-flat-disabled-selected-container-color: #c91019;
  --mdc-chip-focus-state-layer-color: black;
  --mdc-chip-hover-state-layer-color: black;
  --mdc-chip-selected-hover-state-layer-color: black;
  --mdc-chip-focus-state-layer-opacity: 0.12;
  --mdc-chip-selected-focus-state-layer-color: black;
  --mdc-chip-selected-focus-state-layer-opacity: 0.12;
  --mdc-chip-label-text-color: white;
  --mdc-chip-selected-label-text-color: white;
  --mdc-chip-with-icon-icon-color: white;
  --mdc-chip-with-icon-disabled-icon-color: white;
  --mdc-chip-with-icon-selected-icon-color: white;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: white;
  --mdc-chip-with-trailing-icon-trailing-icon-color: white;
}
.mat-mdc-standard-chip.mat-mdc-chip-selected.mat-primary, .mat-mdc-standard-chip.mat-mdc-chip-highlighted.mat-primary {
  --mat-chip-selected-disabled-trailing-icon-color: white;
  --mat-chip-selected-trailing-icon-color: white;
}
.mat-mdc-standard-chip.mat-mdc-chip-selected.mat-accent, .mat-mdc-standard-chip.mat-mdc-chip-highlighted.mat-accent {
  --mdc-chip-disabled-label-text-color: white;
  --mdc-chip-elevated-container-color: #5d6a72;
  --mdc-chip-elevated-selected-container-color: #5d6a72;
  --mdc-chip-elevated-disabled-container-color: #5d6a72;
  --mdc-chip-flat-disabled-selected-container-color: #5d6a72;
  --mdc-chip-focus-state-layer-color: black;
  --mdc-chip-hover-state-layer-color: black;
  --mdc-chip-selected-hover-state-layer-color: black;
  --mdc-chip-focus-state-layer-opacity: 0.12;
  --mdc-chip-selected-focus-state-layer-color: black;
  --mdc-chip-selected-focus-state-layer-opacity: 0.12;
  --mdc-chip-label-text-color: white;
  --mdc-chip-selected-label-text-color: white;
  --mdc-chip-with-icon-icon-color: white;
  --mdc-chip-with-icon-disabled-icon-color: white;
  --mdc-chip-with-icon-selected-icon-color: white;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: white;
  --mdc-chip-with-trailing-icon-trailing-icon-color: white;
}
.mat-mdc-standard-chip.mat-mdc-chip-selected.mat-accent, .mat-mdc-standard-chip.mat-mdc-chip-highlighted.mat-accent {
  --mat-chip-selected-disabled-trailing-icon-color: white;
  --mat-chip-selected-trailing-icon-color: white;
}
.mat-mdc-standard-chip.mat-mdc-chip-selected.mat-warn, .mat-mdc-standard-chip.mat-mdc-chip-highlighted.mat-warn {
  --mdc-chip-disabled-label-text-color: white;
  --mdc-chip-elevated-container-color: #e67300;
  --mdc-chip-elevated-selected-container-color: #e67300;
  --mdc-chip-elevated-disabled-container-color: #e67300;
  --mdc-chip-flat-disabled-selected-container-color: #e67300;
  --mdc-chip-focus-state-layer-color: black;
  --mdc-chip-hover-state-layer-color: black;
  --mdc-chip-selected-hover-state-layer-color: black;
  --mdc-chip-focus-state-layer-opacity: 0.12;
  --mdc-chip-selected-focus-state-layer-color: black;
  --mdc-chip-selected-focus-state-layer-opacity: 0.12;
  --mdc-chip-label-text-color: white;
  --mdc-chip-selected-label-text-color: white;
  --mdc-chip-with-icon-icon-color: white;
  --mdc-chip-with-icon-disabled-icon-color: white;
  --mdc-chip-with-icon-selected-icon-color: white;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: white;
  --mdc-chip-with-trailing-icon-trailing-icon-color: white;
}
.mat-mdc-standard-chip.mat-mdc-chip-selected.mat-warn, .mat-mdc-standard-chip.mat-mdc-chip-highlighted.mat-warn {
  --mat-chip-selected-disabled-trailing-icon-color: white;
  --mat-chip-selected-trailing-icon-color: white;
}

.mat-mdc-chip.mat-mdc-standard-chip {
  --mdc-chip-container-height: 32px;
}
.mat-mdc-standard-chip {
  --mdc-chip-label-text-font: Roboto;
  --mdc-chip-label-text-line-height: 1.57;
  --mdc-chip-label-text-size: 0.875rem;
  --mdc-chip-label-text-tracking: normal;
  --mdc-chip-label-text-weight: 400;
}
html {
  --mdc-switch-disabled-selected-icon-opacity: 0.38;
  --mdc-switch-disabled-track-opacity: 0.12;
  --mdc-switch-disabled-unselected-icon-opacity: 0.38;
  --mdc-switch-handle-height: 20px;
  --mdc-switch-handle-shape: 10px;
  --mdc-switch-handle-width: 20px;
  --mdc-switch-selected-icon-size: 18px;
  --mdc-switch-track-height: 14px;
  --mdc-switch-track-shape: 7px;
  --mdc-switch-track-width: 36px;
  --mdc-switch-unselected-icon-size: 18px;
  --mdc-switch-selected-focus-state-layer-opacity: 0.12;
  --mdc-switch-selected-hover-state-layer-opacity: 0.04;
  --mdc-switch-selected-pressed-state-layer-opacity: 0.1;
  --mdc-switch-unselected-focus-state-layer-opacity: 0.12;
  --mdc-switch-unselected-hover-state-layer-opacity: 0.04;
  --mdc-switch-unselected-pressed-state-layer-opacity: 0.1;
}
html .mat-mdc-slide-toggle {
  --mat-switch-disabled-selected-handle-opacity: 0.38;
  --mat-switch-disabled-unselected-handle-opacity: 0.38;
  --mat-switch-unselected-handle-size: 20px;
  --mat-switch-selected-handle-size: 20px;
  --mat-switch-pressed-handle-size: 20px;
  --mat-switch-with-icon-handle-size: 20px;
  --mat-switch-selected-handle-horizontal-margin: 0;
  --mat-switch-selected-with-icon-handle-horizontal-margin: 0;
  --mat-switch-selected-pressed-handle-horizontal-margin: 0;
  --mat-switch-unselected-handle-horizontal-margin: 0;
  --mat-switch-unselected-with-icon-handle-horizontal-margin: 0;
  --mat-switch-unselected-pressed-handle-horizontal-margin: 0;
  --mat-switch-visible-track-opacity: 1;
  --mat-switch-hidden-track-opacity: 1;
  --mat-switch-visible-track-transition: transform 75ms 0ms cubic-bezier(0, 0, 0.2, 1);
  --mat-switch-hidden-track-transition: transform 75ms 0ms cubic-bezier(0.4, 0, 0.6, 1);
  --mat-switch-track-outline-width: 1px;
  --mat-switch-track-outline-color: transparent;
  --mat-switch-selected-track-outline-width: 1px;
  --mat-switch-selected-track-outline-color: transparent;
  --mat-switch-disabled-unselected-track-outline-width: 1px;
  --mat-switch-disabled-unselected-track-outline-color: transparent;
}

html {
  --mdc-switch-selected-focus-state-layer-color: #c30e16;
  --mdc-switch-selected-handle-color: #c30e16;
  --mdc-switch-selected-hover-state-layer-color: #c30e16;
  --mdc-switch-selected-pressed-state-layer-color: #c30e16;
  --mdc-switch-selected-focus-handle-color: #a90508;
  --mdc-switch-selected-hover-handle-color: #a90508;
  --mdc-switch-selected-pressed-handle-color: #a90508;
  --mdc-switch-selected-focus-track-color: #d9585e;
  --mdc-switch-selected-hover-track-color: #d9585e;
  --mdc-switch-selected-pressed-track-color: #d9585e;
  --mdc-switch-selected-track-color: #d9585e;
  --mdc-switch-disabled-selected-handle-color: #424242;
  --mdc-switch-disabled-selected-icon-color: #fff;
  --mdc-switch-disabled-selected-track-color: #424242;
  --mdc-switch-disabled-unselected-handle-color: #424242;
  --mdc-switch-disabled-unselected-icon-color: #fff;
  --mdc-switch-disabled-unselected-track-color: #424242;
  --mdc-switch-handle-surface-color: #fff;
  --mdc-switch-selected-icon-color: #fff;
  --mdc-switch-unselected-focus-handle-color: #212121;
  --mdc-switch-unselected-focus-state-layer-color: #424242;
  --mdc-switch-unselected-focus-track-color: #e0e0e0;
  --mdc-switch-unselected-handle-color: #616161;
  --mdc-switch-unselected-hover-handle-color: #212121;
  --mdc-switch-unselected-hover-state-layer-color: #424242;
  --mdc-switch-unselected-hover-track-color: #e0e0e0;
  --mdc-switch-unselected-icon-color: #fff;
  --mdc-switch-unselected-pressed-handle-color: #212121;
  --mdc-switch-unselected-pressed-state-layer-color: #424242;
  --mdc-switch-unselected-pressed-track-color: #e0e0e0;
  --mdc-switch-unselected-track-color: #e0e0e0;
  --mdc-switch-handle-elevation-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  --mdc-switch-disabled-handle-elevation-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
html {
  --mdc-switch-disabled-label-text-color: rgba(0, 0, 0, 0.38);
}
html .mat-mdc-slide-toggle {
  --mat-switch-label-text-color: rgba(0, 0, 0, 0.87);
}
html .mat-mdc-slide-toggle.mat-accent {
  --mdc-switch-selected-focus-state-layer-color: #55626a;
  --mdc-switch-selected-handle-color: #55626a;
  --mdc-switch-selected-hover-state-layer-color: #55626a;
  --mdc-switch-selected-pressed-state-layer-color: #55626a;
  --mdc-switch-selected-focus-handle-color: #303c42;
  --mdc-switch-selected-hover-handle-color: #303c42;
  --mdc-switch-selected-pressed-handle-color: #303c42;
  --mdc-switch-selected-focus-track-color: #8e979c;
  --mdc-switch-selected-hover-track-color: #8e979c;
  --mdc-switch-selected-pressed-track-color: #8e979c;
  --mdc-switch-selected-track-color: #8e979c;
}
html .mat-mdc-slide-toggle.mat-warn {
  --mdc-switch-selected-focus-state-layer-color: #e36b00;
  --mdc-switch-selected-handle-color: #e36b00;
  --mdc-switch-selected-hover-state-layer-color: #e36b00;
  --mdc-switch-selected-pressed-state-layer-color: #e36b00;
  --mdc-switch-selected-focus-handle-color: #d54300;
  --mdc-switch-selected-hover-handle-color: #d54300;
  --mdc-switch-selected-pressed-handle-color: #d54300;
  --mdc-switch-selected-focus-track-color: #ee9d4d;
  --mdc-switch-selected-hover-track-color: #ee9d4d;
  --mdc-switch-selected-pressed-track-color: #ee9d4d;
  --mdc-switch-selected-track-color: #ee9d4d;
}

html {
  --mdc-switch-state-layer-size: 40px;
}
html .mat-mdc-slide-toggle {
  --mat-switch-label-text-font: Roboto;
  --mat-switch-label-text-line-height: 1.57;
  --mat-switch-label-text-size: 0.875rem;
  --mat-switch-label-text-tracking: normal;
  --mat-switch-label-text-weight: 400;
}

html {
  --mdc-radio-disabled-selected-icon-opacity: 0.38;
  --mdc-radio-disabled-unselected-icon-opacity: 0.38;
  --mdc-radio-state-layer-size: 40px;
}
.mat-mdc-radio-button.mat-primary {
  --mdc-radio-disabled-selected-icon-color: black;
  --mdc-radio-disabled-unselected-icon-color: black;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-focus-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-selected-focus-icon-color: #c91019;
  --mdc-radio-selected-hover-icon-color: #c91019;
  --mdc-radio-selected-icon-color: #c91019;
  --mdc-radio-selected-pressed-icon-color: #c91019;
}
.mat-mdc-radio-button.mat-primary {
  --mat-radio-ripple-color: black;
  --mat-radio-checked-ripple-color: #c91019;
  --mat-radio-disabled-label-color: rgba(0, 0, 0, 0.38);
  --mat-radio-label-text-color: rgba(0, 0, 0, 0.87);
}
.mat-mdc-radio-button.mat-accent {
  --mdc-radio-disabled-selected-icon-color: black;
  --mdc-radio-disabled-unselected-icon-color: black;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-focus-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-selected-focus-icon-color: #5d6a72;
  --mdc-radio-selected-hover-icon-color: #5d6a72;
  --mdc-radio-selected-icon-color: #5d6a72;
  --mdc-radio-selected-pressed-icon-color: #5d6a72;
}
.mat-mdc-radio-button.mat-accent {
  --mat-radio-ripple-color: black;
  --mat-radio-checked-ripple-color: #5d6a72;
  --mat-radio-disabled-label-color: rgba(0, 0, 0, 0.38);
  --mat-radio-label-text-color: rgba(0, 0, 0, 0.87);
}
.mat-mdc-radio-button.mat-warn {
  --mdc-radio-disabled-selected-icon-color: black;
  --mdc-radio-disabled-unselected-icon-color: black;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-focus-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-selected-focus-icon-color: #e67300;
  --mdc-radio-selected-hover-icon-color: #e67300;
  --mdc-radio-selected-icon-color: #e67300;
  --mdc-radio-selected-pressed-icon-color: #e67300;
}
.mat-mdc-radio-button.mat-warn {
  --mat-radio-ripple-color: black;
  --mat-radio-checked-ripple-color: #e67300;
  --mat-radio-disabled-label-color: rgba(0, 0, 0, 0.38);
  --mat-radio-label-text-color: rgba(0, 0, 0, 0.87);
}

html {
  --mdc-radio-state-layer-size: 40px;
}
html {
  --mat-radio-touch-target-display: block;
}

html {
  --mat-radio-label-text-font: Roboto;
  --mat-radio-label-text-line-height: 1.57;
  --mat-radio-label-text-size: 0.875rem;
  --mat-radio-label-text-tracking: normal;
  --mat-radio-label-text-weight: 400;
}

html {
  --mdc-slider-active-track-height: 6px;
  --mdc-slider-active-track-shape: 9999px;
  --mdc-slider-handle-height: 20px;
  --mdc-slider-handle-shape: 50%;
  --mdc-slider-handle-width: 20px;
  --mdc-slider-inactive-track-height: 4px;
  --mdc-slider-inactive-track-shape: 9999px;
  --mdc-slider-with-overlap-handle-outline-width: 1px;
  --mdc-slider-with-tick-marks-active-container-opacity: 0.6;
  --mdc-slider-with-tick-marks-container-shape: 50%;
  --mdc-slider-with-tick-marks-container-size: 2px;
  --mdc-slider-with-tick-marks-inactive-container-opacity: 0.6;
  --mdc-slider-handle-elevation: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}
html {
  --mat-slider-value-indicator-width: auto;
  --mat-slider-value-indicator-height: 32px;
  --mat-slider-value-indicator-caret-display: block;
  --mat-slider-value-indicator-border-radius: 4px;
  --mat-slider-value-indicator-padding: 0 12px;
  --mat-slider-value-indicator-text-transform: none;
  --mat-slider-value-indicator-container-transform: translateX(-50%);
}

html {
  --mdc-slider-handle-color: #c91019;
  --mdc-slider-focus-handle-color: #c91019;
  --mdc-slider-hover-handle-color: #c91019;
  --mdc-slider-active-track-color: #c91019;
  --mdc-slider-inactive-track-color: #c91019;
  --mdc-slider-with-tick-marks-inactive-container-color: #c91019;
  --mdc-slider-with-tick-marks-active-container-color: white;
  --mdc-slider-disabled-active-track-color: #000;
  --mdc-slider-disabled-handle-color: #000;
  --mdc-slider-disabled-inactive-track-color: #000;
  --mdc-slider-label-container-color: #000;
  --mdc-slider-label-label-text-color: #fff;
  --mdc-slider-with-overlap-handle-outline-color: #fff;
  --mdc-slider-with-tick-marks-disabled-container-color: #000;
}
html {
  --mat-slider-ripple-color: #c91019;
  --mat-slider-hover-state-layer-color: rgba(201, 16, 25, 0.05);
  --mat-slider-focus-state-layer-color: rgba(201, 16, 25, 0.2);
  --mat-slider-value-indicator-opacity: 0.6;
}
html .mat-accent {
  --mdc-slider-handle-color: #5d6a72;
  --mdc-slider-focus-handle-color: #5d6a72;
  --mdc-slider-hover-handle-color: #5d6a72;
  --mdc-slider-active-track-color: #5d6a72;
  --mdc-slider-inactive-track-color: #5d6a72;
  --mdc-slider-with-tick-marks-inactive-container-color: #5d6a72;
  --mdc-slider-with-tick-marks-active-container-color: white;
}
html .mat-accent {
  --mat-slider-ripple-color: #5d6a72;
  --mat-slider-hover-state-layer-color: rgba(93, 106, 114, 0.05);
  --mat-slider-focus-state-layer-color: rgba(93, 106, 114, 0.2);
}
html .mat-warn {
  --mdc-slider-handle-color: #e67300;
  --mdc-slider-focus-handle-color: #e67300;
  --mdc-slider-hover-handle-color: #e67300;
  --mdc-slider-active-track-color: #e67300;
  --mdc-slider-inactive-track-color: #e67300;
  --mdc-slider-with-tick-marks-inactive-container-color: #e67300;
  --mdc-slider-with-tick-marks-active-container-color: white;
}
html .mat-warn {
  --mat-slider-ripple-color: #e67300;
  --mat-slider-hover-state-layer-color: rgba(230, 115, 0, 0.05);
  --mat-slider-focus-state-layer-color: rgba(230, 115, 0, 0.2);
}

html {
  --mdc-slider-label-label-text-font: Roboto, sans-serif;
  --mdc-slider-label-label-text-size: 14px;
  --mdc-slider-label-label-text-line-height: 22px;
  --mdc-slider-label-label-text-tracking: 0.0071428571em;
  --mdc-slider-label-label-text-weight: 500;
}

html {
  --mat-menu-container-shape: 4px;
  --mat-menu-divider-bottom-spacing: 0;
  --mat-menu-divider-top-spacing: 0;
  --mat-menu-item-spacing: 16px;
  --mat-menu-item-icon-size: 24px;
  --mat-menu-item-leading-spacing: 16px;
  --mat-menu-item-trailing-spacing: 16px;
  --mat-menu-item-with-icon-leading-spacing: 16px;
  --mat-menu-item-with-icon-trailing-spacing: 16px;
  --mat-menu-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}

html {
  --mat-menu-item-label-text-color: rgba(0, 0, 0, 0.87);
  --mat-menu-item-icon-color: rgba(0, 0, 0, 0.87);
  --mat-menu-item-hover-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-menu-item-focus-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-menu-container-color: white;
  --mat-menu-divider-color: rgba(0, 0, 0, 0.12);
}

html {
  --mat-menu-item-label-text-font: Roboto;
  --mat-menu-item-label-text-size: 1rem;
  --mat-menu-item-label-text-tracking: normal;
  --mat-menu-item-label-text-line-height: 1;
  --mat-menu-item-label-text-weight: 500;
}

html {
  --mdc-list-list-item-container-shape: 0;
  --mdc-list-list-item-leading-avatar-shape: 50%;
  --mdc-list-list-item-container-color: transparent;
  --mdc-list-list-item-selected-container-color: transparent;
  --mdc-list-list-item-leading-avatar-color: transparent;
  --mdc-list-list-item-leading-icon-size: 24px;
  --mdc-list-list-item-leading-avatar-size: 40px;
  --mdc-list-list-item-trailing-icon-size: 24px;
  --mdc-list-list-item-disabled-state-layer-color: transparent;
  --mdc-list-list-item-disabled-state-layer-opacity: 0;
  --mdc-list-list-item-disabled-label-text-opacity: 0.38;
  --mdc-list-list-item-disabled-leading-icon-opacity: 0.38;
  --mdc-list-list-item-disabled-trailing-icon-opacity: 0.38;
}
html {
  --mat-list-active-indicator-color: transparent;
  --mat-list-active-indicator-shape: 4px;
}

html {
  --mdc-list-list-item-label-text-color: rgba(0, 0, 0, 0.87);
  --mdc-list-list-item-supporting-text-color: rgba(0, 0, 0, 0.54);
  --mdc-list-list-item-leading-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-list-list-item-trailing-supporting-text-color: rgba(0, 0, 0, 0.38);
  --mdc-list-list-item-trailing-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-list-list-item-selected-trailing-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-list-list-item-disabled-label-text-color: black;
  --mdc-list-list-item-disabled-leading-icon-color: black;
  --mdc-list-list-item-disabled-trailing-icon-color: black;
  --mdc-list-list-item-hover-label-text-color: rgba(0, 0, 0, 0.87);
  --mdc-list-list-item-hover-leading-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-list-list-item-hover-trailing-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-list-list-item-focus-label-text-color: rgba(0, 0, 0, 0.87);
  --mdc-list-list-item-hover-state-layer-color: black;
  --mdc-list-list-item-hover-state-layer-opacity: 0.04;
  --mdc-list-list-item-focus-state-layer-color: black;
  --mdc-list-list-item-focus-state-layer-opacity: 0.12;
}
.mdc-list-item__start,
.mdc-list-item__end {
  --mdc-radio-disabled-selected-icon-color: black;
  --mdc-radio-disabled-unselected-icon-color: black;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-focus-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-selected-focus-icon-color: #c91019;
  --mdc-radio-selected-hover-icon-color: #c91019;
  --mdc-radio-selected-icon-color: #c91019;
  --mdc-radio-selected-pressed-icon-color: #c91019;
}

.mat-accent .mdc-list-item__start,
.mat-accent .mdc-list-item__end {
  --mdc-radio-disabled-selected-icon-color: black;
  --mdc-radio-disabled-unselected-icon-color: black;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-focus-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-selected-focus-icon-color: #5d6a72;
  --mdc-radio-selected-hover-icon-color: #5d6a72;
  --mdc-radio-selected-icon-color: #5d6a72;
  --mdc-radio-selected-pressed-icon-color: #5d6a72;
}

.mat-warn .mdc-list-item__start,
.mat-warn .mdc-list-item__end {
  --mdc-radio-disabled-selected-icon-color: black;
  --mdc-radio-disabled-unselected-icon-color: black;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-focus-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-selected-focus-icon-color: #e67300;
  --mdc-radio-selected-hover-icon-color: #e67300;
  --mdc-radio-selected-icon-color: #e67300;
  --mdc-radio-selected-pressed-icon-color: #e67300;
}

.mat-mdc-list-option {
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-selected-checkmark-color: white;
  --mdc-checkbox-selected-focus-icon-color: #c91019;
  --mdc-checkbox-selected-hover-icon-color: #c91019;
  --mdc-checkbox-selected-icon-color: #c91019;
  --mdc-checkbox-selected-pressed-icon-color: #c91019;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #c91019;
  --mdc-checkbox-selected-hover-state-layer-color: #c91019;
  --mdc-checkbox-selected-pressed-state-layer-color: #c91019;
  --mdc-checkbox-unselected-focus-state-layer-color: black;
  --mdc-checkbox-unselected-hover-state-layer-color: black;
  --mdc-checkbox-unselected-pressed-state-layer-color: black;
}

.mat-mdc-list-option.mat-accent {
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-selected-checkmark-color: white;
  --mdc-checkbox-selected-focus-icon-color: #5d6a72;
  --mdc-checkbox-selected-hover-icon-color: #5d6a72;
  --mdc-checkbox-selected-icon-color: #5d6a72;
  --mdc-checkbox-selected-pressed-icon-color: #5d6a72;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #5d6a72;
  --mdc-checkbox-selected-hover-state-layer-color: #5d6a72;
  --mdc-checkbox-selected-pressed-state-layer-color: #5d6a72;
  --mdc-checkbox-unselected-focus-state-layer-color: black;
  --mdc-checkbox-unselected-hover-state-layer-color: black;
  --mdc-checkbox-unselected-pressed-state-layer-color: black;
}

.mat-mdc-list-option.mat-warn {
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-selected-checkmark-color: white;
  --mdc-checkbox-selected-focus-icon-color: #e67300;
  --mdc-checkbox-selected-hover-icon-color: #e67300;
  --mdc-checkbox-selected-icon-color: #e67300;
  --mdc-checkbox-selected-pressed-icon-color: #e67300;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #e67300;
  --mdc-checkbox-selected-hover-state-layer-color: #e67300;
  --mdc-checkbox-selected-pressed-state-layer-color: #e67300;
  --mdc-checkbox-unselected-focus-state-layer-color: black;
  --mdc-checkbox-unselected-hover-state-layer-color: black;
  --mdc-checkbox-unselected-pressed-state-layer-color: black;
}

.mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--selected .mdc-list-item__primary-text,
.mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--selected .mdc-list-item__start,
.mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--activated .mdc-list-item__primary-text,
.mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--activated .mdc-list-item__start {
  color: #c91019;
}

.mat-mdc-list-base .mdc-list-item--disabled .mdc-list-item__start,
.mat-mdc-list-base .mdc-list-item--disabled .mdc-list-item__content,
.mat-mdc-list-base .mdc-list-item--disabled .mdc-list-item__end {
  opacity: 1;
}

html {
  --mdc-list-list-item-one-line-container-height: 48px;
  --mdc-list-list-item-two-line-container-height: 64px;
  --mdc-list-list-item-three-line-container-height: 88px;
}
html {
  --mat-list-list-item-leading-icon-start-space: 16px;
  --mat-list-list-item-leading-icon-end-space: 32px;
}

.mdc-list-item__start,
.mdc-list-item__end {
  --mdc-radio-state-layer-size: 40px;
}

.mat-mdc-list-item.mdc-list-item--with-leading-avatar.mdc-list-item--with-one-line, .mat-mdc-list-item.mdc-list-item--with-leading-checkbox.mdc-list-item--with-one-line, .mat-mdc-list-item.mdc-list-item--with-leading-icon.mdc-list-item--with-one-line {
  height: 56px;
}
.mat-mdc-list-item.mdc-list-item--with-leading-avatar.mdc-list-item--with-two-lines, .mat-mdc-list-item.mdc-list-item--with-leading-checkbox.mdc-list-item--with-two-lines, .mat-mdc-list-item.mdc-list-item--with-leading-icon.mdc-list-item--with-two-lines {
  height: 72px;
}

html {
  --mdc-list-list-item-label-text-font: Roboto;
  --mdc-list-list-item-label-text-line-height: 1;
  --mdc-list-list-item-label-text-size: 1rem;
  --mdc-list-list-item-label-text-tracking: normal;
  --mdc-list-list-item-label-text-weight: 500;
  --mdc-list-list-item-supporting-text-font: Roboto;
  --mdc-list-list-item-supporting-text-line-height: 1.57;
  --mdc-list-list-item-supporting-text-size: 0.875rem;
  --mdc-list-list-item-supporting-text-tracking: normal;
  --mdc-list-list-item-supporting-text-weight: 400;
  --mdc-list-list-item-trailing-supporting-text-font: Roboto, sans-serif;
  --mdc-list-list-item-trailing-supporting-text-line-height: 20px;
  --mdc-list-list-item-trailing-supporting-text-size: 12px;
  --mdc-list-list-item-trailing-supporting-text-tracking: 0.0333333333em;
  --mdc-list-list-item-trailing-supporting-text-weight: 400;
}
.mdc-list-group__subheader {
  font: 400 1rem / 0.875 Roboto;
  letter-spacing: normal;
}

html {
  --mat-paginator-container-text-color: rgba(0, 0, 0, 0.87);
  --mat-paginator-container-background-color: white;
  --mat-paginator-enabled-icon-color: rgba(0, 0, 0, 0.54);
  --mat-paginator-disabled-icon-color: rgba(0, 0, 0, 0.12);
}

html {
  --mat-paginator-container-size: 56px;
  --mat-paginator-form-field-container-height: 40px;
  --mat-paginator-form-field-container-vertical-padding: 8px;
  --mat-paginator-touch-target-display: block;
}

html {
  --mat-paginator-container-text-font: Roboto, sans-serif;
  --mat-paginator-container-text-line-height: 20px;
  --mat-paginator-container-text-size: 12px;
  --mat-paginator-container-text-tracking: 0.0333333333em;
  --mat-paginator-container-text-weight: 400;
  --mat-paginator-select-trigger-text-size: 12px;
}

html {
  --mdc-secondary-navigation-tab-container-height: 48px;
}
html {
  --mdc-tab-indicator-active-indicator-height: 2px;
  --mdc-tab-indicator-active-indicator-shape: 0;
}
html {
  --mat-tab-header-divider-color: transparent;
  --mat-tab-header-divider-height: 0;
}
.mat-mdc-tab-group,
.mat-mdc-tab-nav-bar {
  --mdc-tab-indicator-active-indicator-color: #c91019;
}
.mat-mdc-tab-group,
.mat-mdc-tab-nav-bar {
  --mat-tab-header-disabled-ripple-color: rgba(0, 0, 0, 0.38);
  --mat-tab-header-pagination-icon-color: black;
  --mat-tab-header-inactive-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-active-label-text-color: #c91019;
  --mat-tab-header-active-ripple-color: #c91019;
  --mat-tab-header-inactive-ripple-color: #c91019;
  --mat-tab-header-inactive-focus-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-inactive-hover-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-active-focus-label-text-color: #c91019;
  --mat-tab-header-active-hover-label-text-color: #c91019;
  --mat-tab-header-active-focus-indicator-color: #c91019;
  --mat-tab-header-active-hover-indicator-color: #c91019;
}
.mat-mdc-tab-group.mat-accent,
.mat-mdc-tab-nav-bar.mat-accent {
  --mdc-tab-indicator-active-indicator-color: #5d6a72;
}
.mat-mdc-tab-group.mat-accent,
.mat-mdc-tab-nav-bar.mat-accent {
  --mat-tab-header-disabled-ripple-color: rgba(0, 0, 0, 0.38);
  --mat-tab-header-pagination-icon-color: black;
  --mat-tab-header-inactive-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-active-label-text-color: #5d6a72;
  --mat-tab-header-active-ripple-color: #5d6a72;
  --mat-tab-header-inactive-ripple-color: #5d6a72;
  --mat-tab-header-inactive-focus-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-inactive-hover-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-active-focus-label-text-color: #5d6a72;
  --mat-tab-header-active-hover-label-text-color: #5d6a72;
  --mat-tab-header-active-focus-indicator-color: #5d6a72;
  --mat-tab-header-active-hover-indicator-color: #5d6a72;
}
.mat-mdc-tab-group.mat-warn,
.mat-mdc-tab-nav-bar.mat-warn {
  --mdc-tab-indicator-active-indicator-color: #e67300;
}
.mat-mdc-tab-group.mat-warn,
.mat-mdc-tab-nav-bar.mat-warn {
  --mat-tab-header-disabled-ripple-color: rgba(0, 0, 0, 0.38);
  --mat-tab-header-pagination-icon-color: black;
  --mat-tab-header-inactive-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-active-label-text-color: #e67300;
  --mat-tab-header-active-ripple-color: #e67300;
  --mat-tab-header-inactive-ripple-color: #e67300;
  --mat-tab-header-inactive-focus-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-inactive-hover-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-active-focus-label-text-color: #e67300;
  --mat-tab-header-active-hover-label-text-color: #e67300;
  --mat-tab-header-active-focus-indicator-color: #e67300;
  --mat-tab-header-active-hover-indicator-color: #e67300;
}
.mat-mdc-tab-group.mat-background-primary,
.mat-mdc-tab-nav-bar.mat-background-primary {
  --mat-tab-header-with-background-background-color: #c91019;
  --mat-tab-header-with-background-foreground-color: white;
}
.mat-mdc-tab-group.mat-background-accent,
.mat-mdc-tab-nav-bar.mat-background-accent {
  --mat-tab-header-with-background-background-color: #5d6a72;
  --mat-tab-header-with-background-foreground-color: white;
}
.mat-mdc-tab-group.mat-background-warn,
.mat-mdc-tab-nav-bar.mat-background-warn {
  --mat-tab-header-with-background-background-color: #e67300;
  --mat-tab-header-with-background-foreground-color: white;
}

.mat-mdc-tab-header {
  --mdc-secondary-navigation-tab-container-height: 48px;
}
.mat-mdc-tab-header {
  --mat-tab-header-label-text-font: Roboto, sans-serif;
  --mat-tab-header-label-text-size: 14px;
  --mat-tab-header-label-text-tracking: 0.0892857143em;
  --mat-tab-header-label-text-line-height: 36px;
  --mat-tab-header-label-text-weight: 500;
}
html {
  --mdc-checkbox-disabled-selected-checkmark-color: #fff;
  --mdc-checkbox-selected-focus-state-layer-opacity: 0.16;
  --mdc-checkbox-selected-hover-state-layer-opacity: 0.04;
  --mdc-checkbox-selected-pressed-state-layer-opacity: 0.16;
  --mdc-checkbox-unselected-focus-state-layer-opacity: 0.16;
  --mdc-checkbox-unselected-hover-state-layer-opacity: 0.04;
  --mdc-checkbox-unselected-pressed-state-layer-opacity: 0.16;
}
html {
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-selected-checkmark-color: white;
  --mdc-checkbox-selected-focus-icon-color: #5d6a72;
  --mdc-checkbox-selected-hover-icon-color: #5d6a72;
  --mdc-checkbox-selected-icon-color: #5d6a72;
  --mdc-checkbox-selected-pressed-icon-color: #5d6a72;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #5d6a72;
  --mdc-checkbox-selected-hover-state-layer-color: #5d6a72;
  --mdc-checkbox-selected-pressed-state-layer-color: #5d6a72;
  --mdc-checkbox-unselected-focus-state-layer-color: black;
  --mdc-checkbox-unselected-hover-state-layer-color: black;
  --mdc-checkbox-unselected-pressed-state-layer-color: black;
}
html {
  --mat-checkbox-disabled-label-color: rgba(0, 0, 0, 0.38);
  --mat-checkbox-label-text-color: rgba(0, 0, 0, 0.87);
}

.mat-mdc-checkbox.mat-primary {
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-selected-checkmark-color: white;
  --mdc-checkbox-selected-focus-icon-color: #c91019;
  --mdc-checkbox-selected-hover-icon-color: #c91019;
  --mdc-checkbox-selected-icon-color: #c91019;
  --mdc-checkbox-selected-pressed-icon-color: #c91019;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #c91019;
  --mdc-checkbox-selected-hover-state-layer-color: #c91019;
  --mdc-checkbox-selected-pressed-state-layer-color: #c91019;
  --mdc-checkbox-unselected-focus-state-layer-color: black;
  --mdc-checkbox-unselected-hover-state-layer-color: black;
  --mdc-checkbox-unselected-pressed-state-layer-color: black;
}
.mat-mdc-checkbox.mat-warn {
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-selected-checkmark-color: white;
  --mdc-checkbox-selected-focus-icon-color: #e67300;
  --mdc-checkbox-selected-hover-icon-color: #e67300;
  --mdc-checkbox-selected-icon-color: #e67300;
  --mdc-checkbox-selected-pressed-icon-color: #e67300;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #e67300;
  --mdc-checkbox-selected-hover-state-layer-color: #e67300;
  --mdc-checkbox-selected-pressed-state-layer-color: #e67300;
  --mdc-checkbox-unselected-focus-state-layer-color: black;
  --mdc-checkbox-unselected-hover-state-layer-color: black;
  --mdc-checkbox-unselected-pressed-state-layer-color: black;
}

html {
  --mdc-checkbox-state-layer-size: 40px;
}
html {
  --mat-checkbox-touch-target-display: block;
}

html {
  --mat-checkbox-label-text-font: Roboto;
  --mat-checkbox-label-text-line-height: 1.57;
  --mat-checkbox-label-text-size: 0.875rem;
  --mat-checkbox-label-text-tracking: normal;
  --mat-checkbox-label-text-weight: 400;
}

html {
  --mdc-text-button-container-shape: 4px;
  --mdc-text-button-keep-touch-target: false;
}
html {
  --mdc-filled-button-container-shape: 4px;
  --mdc-filled-button-keep-touch-target: false;
}
html {
  --mdc-protected-button-container-shape: 4px;
  --mdc-protected-button-container-elevation-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
  --mdc-protected-button-disabled-container-elevation-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
  --mdc-protected-button-focus-container-elevation-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  --mdc-protected-button-hover-container-elevation-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  --mdc-protected-button-pressed-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}
html {
  --mdc-outlined-button-keep-touch-target: false;
  --mdc-outlined-button-outline-width: 1px;
  --mdc-outlined-button-container-shape: 4px;
}
html {
  --mat-text-button-horizontal-padding: 8px;
  --mat-text-button-with-icon-horizontal-padding: 8px;
  --mat-text-button-icon-spacing: 8px;
  --mat-text-button-icon-offset: 0;
}
html {
  --mat-filled-button-horizontal-padding: 16px;
  --mat-filled-button-icon-spacing: 8px;
  --mat-filled-button-icon-offset: -4px;
}
html {
  --mat-protected-button-horizontal-padding: 16px;
  --mat-protected-button-icon-spacing: 8px;
  --mat-protected-button-icon-offset: -4px;
}
html {
  --mat-outlined-button-horizontal-padding: 15px;
  --mat-outlined-button-icon-spacing: 8px;
  --mat-outlined-button-icon-offset: -4px;
}

html {
  --mdc-text-button-label-text-color: black;
  --mdc-text-button-disabled-label-text-color: rgba(0, 0, 0, 0.38);
}
html {
  --mat-text-button-state-layer-color: black;
  --mat-text-button-disabled-state-layer-color: black;
  --mat-text-button-ripple-color: rgba(0, 0, 0, 0.1);
  --mat-text-button-hover-state-layer-opacity: 0.04;
  --mat-text-button-focus-state-layer-opacity: 0.12;
  --mat-text-button-pressed-state-layer-opacity: 0.12;
}
html {
  --mdc-filled-button-container-color: white;
  --mdc-filled-button-label-text-color: black;
  --mdc-filled-button-disabled-container-color: rgba(0, 0, 0, 0.12);
  --mdc-filled-button-disabled-label-text-color: rgba(0, 0, 0, 0.38);
}
html {
  --mat-filled-button-state-layer-color: black;
  --mat-filled-button-disabled-state-layer-color: black;
  --mat-filled-button-ripple-color: rgba(0, 0, 0, 0.1);
  --mat-filled-button-hover-state-layer-opacity: 0.04;
  --mat-filled-button-focus-state-layer-opacity: 0.12;
  --mat-filled-button-pressed-state-layer-opacity: 0.12;
}
html {
  --mdc-protected-button-container-color: white;
  --mdc-protected-button-label-text-color: black;
  --mdc-protected-button-disabled-container-color: rgba(0, 0, 0, 0.12);
  --mdc-protected-button-disabled-label-text-color: rgba(0, 0, 0, 0.38);
}
html {
  --mat-protected-button-state-layer-color: black;
  --mat-protected-button-disabled-state-layer-color: black;
  --mat-protected-button-ripple-color: rgba(0, 0, 0, 0.1);
  --mat-protected-button-hover-state-layer-opacity: 0.04;
  --mat-protected-button-focus-state-layer-opacity: 0.12;
  --mat-protected-button-pressed-state-layer-opacity: 0.12;
}
html {
  --mdc-outlined-button-disabled-outline-color: rgba(0, 0, 0, 0.12);
  --mdc-outlined-button-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-outlined-button-label-text-color: black;
  --mdc-outlined-button-outline-color: rgba(0, 0, 0, 0.12);
}
html {
  --mat-outlined-button-state-layer-color: black;
  --mat-outlined-button-disabled-state-layer-color: black;
  --mat-outlined-button-ripple-color: rgba(0, 0, 0, 0.1);
  --mat-outlined-button-hover-state-layer-opacity: 0.04;
  --mat-outlined-button-focus-state-layer-opacity: 0.12;
  --mat-outlined-button-pressed-state-layer-opacity: 0.12;
}

.mat-mdc-button.mat-primary {
  --mdc-text-button-label-text-color: #c91019;
}
.mat-mdc-button.mat-primary {
  --mat-text-button-state-layer-color: #c91019;
  --mat-text-button-ripple-color: rgba(201, 16, 25, 0.1);
}
.mat-mdc-button.mat-accent {
  --mdc-text-button-label-text-color: #5d6a72;
}
.mat-mdc-button.mat-accent {
  --mat-text-button-state-layer-color: #5d6a72;
  --mat-text-button-ripple-color: rgba(93, 106, 114, 0.1);
}
.mat-mdc-button.mat-warn {
  --mdc-text-button-label-text-color: #e67300;
}
.mat-mdc-button.mat-warn {
  --mat-text-button-state-layer-color: #e67300;
  --mat-text-button-ripple-color: rgba(230, 115, 0, 0.1);
}

.mat-mdc-unelevated-button.mat-primary {
  --mdc-filled-button-container-color: #c91019;
  --mdc-filled-button-label-text-color: white;
}
.mat-mdc-unelevated-button.mat-primary {
  --mat-filled-button-state-layer-color: white;
  --mat-filled-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.mat-mdc-unelevated-button.mat-accent {
  --mdc-filled-button-container-color: #5d6a72;
  --mdc-filled-button-label-text-color: white;
}
.mat-mdc-unelevated-button.mat-accent {
  --mat-filled-button-state-layer-color: white;
  --mat-filled-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.mat-mdc-unelevated-button.mat-warn {
  --mdc-filled-button-container-color: #e67300;
  --mdc-filled-button-label-text-color: white;
}
.mat-mdc-unelevated-button.mat-warn {
  --mat-filled-button-state-layer-color: white;
  --mat-filled-button-ripple-color: rgba(255, 255, 255, 0.1);
}

.mat-mdc-raised-button.mat-primary {
  --mdc-protected-button-container-color: #c91019;
  --mdc-protected-button-label-text-color: white;
}
.mat-mdc-raised-button.mat-primary {
  --mat-protected-button-state-layer-color: white;
  --mat-protected-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.mat-mdc-raised-button.mat-accent {
  --mdc-protected-button-container-color: #5d6a72;
  --mdc-protected-button-label-text-color: white;
}
.mat-mdc-raised-button.mat-accent {
  --mat-protected-button-state-layer-color: white;
  --mat-protected-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.mat-mdc-raised-button.mat-warn {
  --mdc-protected-button-container-color: #e67300;
  --mdc-protected-button-label-text-color: white;
}
.mat-mdc-raised-button.mat-warn {
  --mat-protected-button-state-layer-color: white;
  --mat-protected-button-ripple-color: rgba(255, 255, 255, 0.1);
}

.mat-mdc-outlined-button.mat-primary {
  --mdc-outlined-button-label-text-color: #c91019;
  --mdc-outlined-button-outline-color: rgba(0, 0, 0, 0.12);
}
.mat-mdc-outlined-button.mat-primary {
  --mat-outlined-button-state-layer-color: #c91019;
  --mat-outlined-button-ripple-color: rgba(201, 16, 25, 0.1);
}
.mat-mdc-outlined-button.mat-accent {
  --mdc-outlined-button-label-text-color: #5d6a72;
  --mdc-outlined-button-outline-color: rgba(0, 0, 0, 0.12);
}
.mat-mdc-outlined-button.mat-accent {
  --mat-outlined-button-state-layer-color: #5d6a72;
  --mat-outlined-button-ripple-color: rgba(93, 106, 114, 0.1);
}
.mat-mdc-outlined-button.mat-warn {
  --mdc-outlined-button-label-text-color: #e67300;
  --mdc-outlined-button-outline-color: rgba(0, 0, 0, 0.12);
}
.mat-mdc-outlined-button.mat-warn {
  --mat-outlined-button-state-layer-color: #e67300;
  --mat-outlined-button-ripple-color: rgba(230, 115, 0, 0.1);
}

html {
  --mdc-text-button-container-height: 36px;
}
html {
  --mdc-filled-button-container-height: 36px;
}
html {
  --mdc-protected-button-container-height: 36px;
}
html {
  --mdc-outlined-button-container-height: 36px;
}
html {
  --mat-text-button-touch-target-display: block;
}
html {
  --mat-filled-button-touch-target-display: block;
}
html {
  --mat-protected-button-touch-target-display: block;
}
html {
  --mat-outlined-button-touch-target-display: block;
}

html {
  --mdc-text-button-label-text-font: Roboto, sans-serif;
  --mdc-text-button-label-text-size: 14px;
  --mdc-text-button-label-text-tracking: 0.0892857143em;
  --mdc-text-button-label-text-weight: 500;
  --mdc-text-button-label-text-transform: none;
}
html {
  --mdc-filled-button-label-text-font: Roboto, sans-serif;
  --mdc-filled-button-label-text-size: 14px;
  --mdc-filled-button-label-text-tracking: 0.0892857143em;
  --mdc-filled-button-label-text-weight: 500;
  --mdc-filled-button-label-text-transform: none;
}
html {
  --mdc-protected-button-label-text-font: Roboto, sans-serif;
  --mdc-protected-button-label-text-size: 14px;
  --mdc-protected-button-label-text-tracking: 0.0892857143em;
  --mdc-protected-button-label-text-weight: 500;
  --mdc-protected-button-label-text-transform: none;
}
html {
  --mdc-outlined-button-label-text-font: Roboto, sans-serif;
  --mdc-outlined-button-label-text-size: 14px;
  --mdc-outlined-button-label-text-tracking: 0.0892857143em;
  --mdc-outlined-button-label-text-weight: 500;
  --mdc-outlined-button-label-text-transform: none;
}
html {
  --mdc-icon-button-icon-size: 24px;
}

html {
  --mdc-icon-button-icon-color: inherit;
  --mdc-icon-button-disabled-icon-color: rgba(0, 0, 0, 0.38);
}
html {
  --mat-icon-button-state-layer-color: black;
  --mat-icon-button-disabled-state-layer-color: black;
  --mat-icon-button-ripple-color: rgba(0, 0, 0, 0.1);
  --mat-icon-button-hover-state-layer-opacity: 0.04;
  --mat-icon-button-focus-state-layer-opacity: 0.12;
  --mat-icon-button-pressed-state-layer-opacity: 0.12;
}
html .mat-mdc-icon-button.mat-primary {
  --mdc-icon-button-icon-color: #c91019;
}
html .mat-mdc-icon-button.mat-primary {
  --mat-icon-button-state-layer-color: #c91019;
  --mat-icon-button-ripple-color: rgba(201, 16, 25, 0.1);
}
html .mat-mdc-icon-button.mat-accent {
  --mdc-icon-button-icon-color: #5d6a72;
}
html .mat-mdc-icon-button.mat-accent {
  --mat-icon-button-state-layer-color: #5d6a72;
  --mat-icon-button-ripple-color: rgba(93, 106, 114, 0.1);
}
html .mat-mdc-icon-button.mat-warn {
  --mdc-icon-button-icon-color: #e67300;
}
html .mat-mdc-icon-button.mat-warn {
  --mat-icon-button-state-layer-color: #e67300;
  --mat-icon-button-ripple-color: rgba(230, 115, 0, 0.1);
}

html {
  --mat-icon-button-touch-target-display: block;
}

.mat-mdc-icon-button.mat-mdc-button-base {
  --mdc-icon-button-state-layer-size: 48px;
  width: var(--mdc-icon-button-state-layer-size);
  height: var(--mdc-icon-button-state-layer-size);
  padding: 12px;
}

html {
  --mdc-fab-container-shape: 50%;
  --mdc-fab-container-elevation-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
  --mdc-fab-focus-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mdc-fab-hover-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mdc-fab-pressed-container-elevation-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
}
html {
  --mdc-fab-small-container-shape: 50%;
  --mdc-fab-small-container-elevation-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
  --mdc-fab-small-focus-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mdc-fab-small-hover-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mdc-fab-small-pressed-container-elevation-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
}
html {
  --mdc-extended-fab-container-height: 48px;
  --mdc-extended-fab-container-shape: 24px;
  --mdc-extended-fab-container-elevation-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
  --mdc-extended-fab-focus-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mdc-extended-fab-hover-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mdc-extended-fab-pressed-container-elevation-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
}

html {
  --mdc-fab-container-color: white;
}
html {
  --mat-fab-foreground-color: black;
  --mat-fab-state-layer-color: black;
  --mat-fab-disabled-state-layer-color: black;
  --mat-fab-ripple-color: rgba(0, 0, 0, 0.1);
  --mat-fab-hover-state-layer-opacity: 0.04;
  --mat-fab-focus-state-layer-opacity: 0.12;
  --mat-fab-pressed-state-layer-opacity: 0.12;
  --mat-fab-disabled-state-container-color: rgba(0, 0, 0, 0.12);
  --mat-fab-disabled-state-foreground-color: rgba(0, 0, 0, 0.38);
}
html {
  --mdc-fab-small-container-color: white;
}
html {
  --mat-fab-small-foreground-color: black;
  --mat-fab-small-state-layer-color: black;
  --mat-fab-small-disabled-state-layer-color: black;
  --mat-fab-small-ripple-color: rgba(0, 0, 0, 0.1);
  --mat-fab-small-hover-state-layer-opacity: 0.04;
  --mat-fab-small-focus-state-layer-opacity: 0.12;
  --mat-fab-small-pressed-state-layer-opacity: 0.12;
  --mat-fab-small-disabled-state-container-color: rgba(0, 0, 0, 0.12);
  --mat-fab-small-disabled-state-foreground-color: rgba(0, 0, 0, 0.38);
}
html .mat-mdc-fab.mat-primary {
  --mdc-fab-container-color: #c91019;
}
html .mat-mdc-fab.mat-primary {
  --mat-fab-foreground-color: white;
  --mat-fab-state-layer-color: white;
  --mat-fab-ripple-color: rgba(255, 255, 255, 0.1);
}
html .mat-mdc-fab.mat-accent {
  --mdc-fab-container-color: #5d6a72;
}
html .mat-mdc-fab.mat-accent {
  --mat-fab-foreground-color: white;
  --mat-fab-state-layer-color: white;
  --mat-fab-ripple-color: rgba(255, 255, 255, 0.1);
}
html .mat-mdc-fab.mat-warn {
  --mdc-fab-container-color: #e67300;
}
html .mat-mdc-fab.mat-warn {
  --mat-fab-foreground-color: white;
  --mat-fab-state-layer-color: white;
  --mat-fab-ripple-color: rgba(255, 255, 255, 0.1);
}
html .mat-mdc-mini-fab.mat-primary {
  --mdc-fab-small-container-color: #c91019;
}
html .mat-mdc-mini-fab.mat-primary {
  --mat-fab-small-foreground-color: white;
  --mat-fab-small-state-layer-color: white;
  --mat-fab-small-ripple-color: rgba(255, 255, 255, 0.1);
}
html .mat-mdc-mini-fab.mat-accent {
  --mdc-fab-small-container-color: #5d6a72;
}
html .mat-mdc-mini-fab.mat-accent {
  --mat-fab-small-foreground-color: white;
  --mat-fab-small-state-layer-color: white;
  --mat-fab-small-ripple-color: rgba(255, 255, 255, 0.1);
}
html .mat-mdc-mini-fab.mat-warn {
  --mdc-fab-small-container-color: #e67300;
}
html .mat-mdc-mini-fab.mat-warn {
  --mat-fab-small-foreground-color: white;
  --mat-fab-small-state-layer-color: white;
  --mat-fab-small-ripple-color: rgba(255, 255, 255, 0.1);
}

html {
  --mat-fab-touch-target-display: block;
}
html {
  --mat-fab-small-touch-target-display: block;
}

html {
  --mdc-extended-fab-label-text-font: Roboto, sans-serif;
  --mdc-extended-fab-label-text-size: 14px;
  --mdc-extended-fab-label-text-tracking: 0.0892857143em;
  --mdc-extended-fab-label-text-weight: 500;
}
html {
  --mdc-snackbar-container-shape: 4px;
}

html {
  --mdc-snackbar-container-color: #333333;
  --mdc-snackbar-supporting-text-color: rgba(255, 255, 255, 0.87);
}
html {
  --mat-snack-bar-button-color: #5d6a72;
}

html {
  --mdc-snackbar-supporting-text-font: Roboto;
  --mdc-snackbar-supporting-text-line-height: 1.57;
  --mdc-snackbar-supporting-text-size: 0.875rem;
  --mdc-snackbar-supporting-text-weight: 400;
}

html {
  --mat-table-row-item-outline-width: 1px;
}

html {
  --mat-table-background-color: white;
  --mat-table-header-headline-color: rgba(0, 0, 0, 0.87);
  --mat-table-row-item-label-text-color: rgba(0, 0, 0, 0.87);
  --mat-table-row-item-outline-color: rgba(0, 0, 0, 0.12);
}

html {
  --mat-table-header-container-height: 56px;
  --mat-table-footer-container-height: 52px;
  --mat-table-row-item-container-height: 52px;
}

html {
  --mat-table-header-headline-font: Roboto, sans-serif;
  --mat-table-header-headline-line-height: 22px;
  --mat-table-header-headline-size: 14px;
  --mat-table-header-headline-weight: 500;
  --mat-table-header-headline-tracking: 0.0071428571em;
  --mat-table-row-item-label-text-font: Roboto;
  --mat-table-row-item-label-text-line-height: 1.57;
  --mat-table-row-item-label-text-size: 0.875rem;
  --mat-table-row-item-label-text-weight: 400;
  --mat-table-row-item-label-text-tracking: normal;
  --mat-table-footer-supporting-text-font: Roboto;
  --mat-table-footer-supporting-text-line-height: 1.57;
  --mat-table-footer-supporting-text-size: 0.875rem;
  --mat-table-footer-supporting-text-weight: 400;
  --mat-table-footer-supporting-text-tracking: normal;
}

html {
  --mdc-circular-progress-active-indicator-width: 4px;
  --mdc-circular-progress-size: 48px;
}

html {
  --mdc-circular-progress-active-indicator-color: #c91019;
}
html .mat-accent {
  --mdc-circular-progress-active-indicator-color: #5d6a72;
}
html .mat-warn {
  --mdc-circular-progress-active-indicator-color: #e67300;
}

html {
  --mat-badge-container-shape: 50%;
  --mat-badge-container-size: unset;
  --mat-badge-small-size-container-size: unset;
  --mat-badge-large-size-container-size: unset;
  --mat-badge-legacy-container-size: 22px;
  --mat-badge-legacy-small-size-container-size: 16px;
  --mat-badge-legacy-large-size-container-size: 28px;
  --mat-badge-container-offset: -11px 0;
  --mat-badge-small-size-container-offset: -8px 0;
  --mat-badge-large-size-container-offset: -14px 0;
  --mat-badge-container-overlap-offset: -11px;
  --mat-badge-small-size-container-overlap-offset: -8px;
  --mat-badge-large-size-container-overlap-offset: -14px;
  --mat-badge-container-padding: 0;
  --mat-badge-small-size-container-padding: 0;
  --mat-badge-large-size-container-padding: 0;
}

html {
  --mat-badge-background-color: #c91019;
  --mat-badge-text-color: white;
  --mat-badge-disabled-state-background-color: #b9b9b9;
  --mat-badge-disabled-state-text-color: rgba(0, 0, 0, 0.38);
}

.mat-badge-accent {
  --mat-badge-background-color: #5d6a72;
  --mat-badge-text-color: white;
}

.mat-badge-warn {
  --mat-badge-background-color: #e67300;
  --mat-badge-text-color: white;
}

html {
  --mat-badge-text-font: Roboto;
  --mat-badge-line-height: 22px;
  --mat-badge-text-size: 12px;
  --mat-badge-text-weight: 600;
  --mat-badge-small-size-text-size: 9px;
  --mat-badge-small-size-line-height: 16px;
  --mat-badge-large-size-text-size: 24px;
  --mat-badge-large-size-line-height: 28px;
}

html {
  --mat-bottom-sheet-container-shape: 4px;
}

html {
  --mat-bottom-sheet-container-text-color: rgba(0, 0, 0, 0.87);
  --mat-bottom-sheet-container-background-color: white;
}

html {
  --mat-bottom-sheet-container-text-font: Roboto;
  --mat-bottom-sheet-container-text-line-height: 1.57;
  --mat-bottom-sheet-container-text-size: 0.875rem;
  --mat-bottom-sheet-container-text-tracking: normal;
  --mat-bottom-sheet-container-text-weight: 400;
}

html {
  --mat-legacy-button-toggle-height: 36px;
  --mat-legacy-button-toggle-shape: 2px;
  --mat-legacy-button-toggle-focus-state-layer-opacity: 1;
}
html {
  --mat-standard-button-toggle-shape: 4px;
  --mat-standard-button-toggle-hover-state-layer-opacity: 0.04;
  --mat-standard-button-toggle-focus-state-layer-opacity: 0.12;
}

html {
  --mat-legacy-button-toggle-text-color: rgba(0, 0, 0, 0.38);
  --mat-legacy-button-toggle-state-layer-color: rgba(0, 0, 0, 0.12);
  --mat-legacy-button-toggle-selected-state-text-color: rgba(0, 0, 0, 0.54);
  --mat-legacy-button-toggle-selected-state-background-color: #e0e0e0;
  --mat-legacy-button-toggle-disabled-state-text-color: rgba(0, 0, 0, 0.26);
  --mat-legacy-button-toggle-disabled-state-background-color: #eeeeee;
  --mat-legacy-button-toggle-disabled-selected-state-background-color: #bdbdbd;
}
html {
  --mat-standard-button-toggle-text-color: rgba(0, 0, 0, 0.87);
  --mat-standard-button-toggle-background-color: white;
  --mat-standard-button-toggle-state-layer-color: black;
  --mat-standard-button-toggle-selected-state-background-color: #e0e0e0;
  --mat-standard-button-toggle-selected-state-text-color: rgba(0, 0, 0, 0.87);
  --mat-standard-button-toggle-disabled-state-text-color: rgba(0, 0, 0, 0.26);
  --mat-standard-button-toggle-disabled-state-background-color: white;
  --mat-standard-button-toggle-disabled-selected-state-text-color: rgba(0, 0, 0, 0.87);
  --mat-standard-button-toggle-disabled-selected-state-background-color: #bdbdbd;
  --mat-standard-button-toggle-divider-color: rgb(224.4, 224.4, 224.4);
}

html {
  --mat-standard-button-toggle-height: 48px;
}

html {
  --mat-legacy-button-toggle-label-text-font: Roboto;
  --mat-legacy-button-toggle-label-text-line-height: 1;
  --mat-legacy-button-toggle-label-text-size: 1rem;
  --mat-legacy-button-toggle-label-text-tracking: normal;
  --mat-legacy-button-toggle-label-text-weight: 500;
}
html {
  --mat-standard-button-toggle-label-text-font: Roboto;
  --mat-standard-button-toggle-label-text-line-height: 1;
  --mat-standard-button-toggle-label-text-size: 1rem;
  --mat-standard-button-toggle-label-text-tracking: normal;
  --mat-standard-button-toggle-label-text-weight: 500;
}

html {
  --mat-datepicker-calendar-container-shape: 4px;
  --mat-datepicker-calendar-container-touch-shape: 4px;
  --mat-datepicker-calendar-container-elevation-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  --mat-datepicker-calendar-container-touch-elevation-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
}

html {
  --mat-datepicker-calendar-date-selected-state-text-color: white;
  --mat-datepicker-calendar-date-selected-state-background-color: #c91019;
  --mat-datepicker-calendar-date-selected-disabled-state-background-color: rgba(201, 16, 25, 0.4);
  --mat-datepicker-calendar-date-today-selected-state-outline-color: white;
  --mat-datepicker-calendar-date-focus-state-background-color: rgba(201, 16, 25, 0.3);
  --mat-datepicker-calendar-date-hover-state-background-color: rgba(201, 16, 25, 0.3);
  --mat-datepicker-toggle-active-state-icon-color: #c91019;
  --mat-datepicker-calendar-date-in-range-state-background-color: rgba(201, 16, 25, 0.2);
  --mat-datepicker-calendar-date-in-comparison-range-state-background-color: rgba(249, 171, 0, 0.2);
  --mat-datepicker-calendar-date-in-overlap-range-state-background-color: #a8dab5;
  --mat-datepicker-calendar-date-in-overlap-range-selected-state-background-color: rgb(69.5241935484, 163.4758064516, 93.9516129032);
  --mat-datepicker-toggle-icon-color: rgba(0, 0, 0, 0.54);
  --mat-datepicker-calendar-body-label-text-color: rgba(0, 0, 0, 0.54);
  --mat-datepicker-calendar-period-button-text-color: black;
  --mat-datepicker-calendar-period-button-icon-color: rgba(0, 0, 0, 0.54);
  --mat-datepicker-calendar-navigation-button-icon-color: rgba(0, 0, 0, 0.54);
  --mat-datepicker-calendar-header-divider-color: rgba(0, 0, 0, 0.12);
  --mat-datepicker-calendar-header-text-color: rgba(0, 0, 0, 0.54);
  --mat-datepicker-calendar-date-today-outline-color: rgba(0, 0, 0, 0.38);
  --mat-datepicker-calendar-date-today-disabled-state-outline-color: rgba(0, 0, 0, 0.18);
  --mat-datepicker-calendar-date-text-color: rgba(0, 0, 0, 0.87);
  --mat-datepicker-calendar-date-outline-color: transparent;
  --mat-datepicker-calendar-date-disabled-state-text-color: rgba(0, 0, 0, 0.38);
  --mat-datepicker-calendar-date-preview-state-outline-color: rgba(0, 0, 0, 0.24);
  --mat-datepicker-range-input-separator-color: rgba(0, 0, 0, 0.87);
  --mat-datepicker-range-input-disabled-state-separator-color: rgba(0, 0, 0, 0.38);
  --mat-datepicker-range-input-disabled-state-text-color: rgba(0, 0, 0, 0.38);
  --mat-datepicker-calendar-container-background-color: white;
  --mat-datepicker-calendar-container-text-color: rgba(0, 0, 0, 0.87);
}

.mat-datepicker-content.mat-accent {
  --mat-datepicker-calendar-date-selected-state-text-color: white;
  --mat-datepicker-calendar-date-selected-state-background-color: #5d6a72;
  --mat-datepicker-calendar-date-selected-disabled-state-background-color: rgba(93, 106, 114, 0.4);
  --mat-datepicker-calendar-date-today-selected-state-outline-color: white;
  --mat-datepicker-calendar-date-focus-state-background-color: rgba(93, 106, 114, 0.3);
  --mat-datepicker-calendar-date-hover-state-background-color: rgba(93, 106, 114, 0.3);
  --mat-datepicker-calendar-date-in-range-state-background-color: rgba(93, 106, 114, 0.2);
  --mat-datepicker-calendar-date-in-comparison-range-state-background-color: rgba(249, 171, 0, 0.2);
  --mat-datepicker-calendar-date-in-overlap-range-state-background-color: #a8dab5;
  --mat-datepicker-calendar-date-in-overlap-range-selected-state-background-color: rgb(69.5241935484, 163.4758064516, 93.9516129032);
}
.mat-datepicker-content.mat-warn {
  --mat-datepicker-calendar-date-selected-state-text-color: white;
  --mat-datepicker-calendar-date-selected-state-background-color: #e67300;
  --mat-datepicker-calendar-date-selected-disabled-state-background-color: rgba(230, 115, 0, 0.4);
  --mat-datepicker-calendar-date-today-selected-state-outline-color: white;
  --mat-datepicker-calendar-date-focus-state-background-color: rgba(230, 115, 0, 0.3);
  --mat-datepicker-calendar-date-hover-state-background-color: rgba(230, 115, 0, 0.3);
  --mat-datepicker-calendar-date-in-range-state-background-color: rgba(230, 115, 0, 0.2);
  --mat-datepicker-calendar-date-in-comparison-range-state-background-color: rgba(249, 171, 0, 0.2);
  --mat-datepicker-calendar-date-in-overlap-range-state-background-color: #a8dab5;
  --mat-datepicker-calendar-date-in-overlap-range-selected-state-background-color: rgb(69.5241935484, 163.4758064516, 93.9516129032);
}

.mat-datepicker-toggle-active.mat-accent {
  --mat-datepicker-toggle-active-state-icon-color: #5d6a72;
}
.mat-datepicker-toggle-active.mat-warn {
  --mat-datepicker-toggle-active-state-icon-color: #e67300;
}

.mat-calendar-controls {
  --mat-icon-button-touch-target-display: none;
}
.mat-calendar-controls .mat-mdc-icon-button.mat-mdc-button-base {
  --mdc-icon-button-state-layer-size: 40px;
  width: var(--mdc-icon-button-state-layer-size);
  height: var(--mdc-icon-button-state-layer-size);
  padding: 8px;
}

html {
  --mat-datepicker-calendar-text-font: Roboto;
  --mat-datepicker-calendar-text-size: 13px;
  --mat-datepicker-calendar-body-label-text-size: 14px;
  --mat-datepicker-calendar-body-label-text-weight: 500;
  --mat-datepicker-calendar-period-button-text-size: 14px;
  --mat-datepicker-calendar-period-button-text-weight: 500;
  --mat-datepicker-calendar-header-text-size: 11px;
  --mat-datepicker-calendar-header-text-weight: 500;
}

html {
  --mat-divider-width: 1px;
}

html {
  --mat-divider-color: rgba(0, 0, 0, 0.12);
}

html {
  --mat-expansion-container-shape: 4px;
  --mat-expansion-legacy-header-indicator-display: inline-block;
  --mat-expansion-header-indicator-display: none;
}

html {
  --mat-expansion-container-background-color: white;
  --mat-expansion-container-text-color: rgba(0, 0, 0, 0.87);
  --mat-expansion-actions-divider-color: rgba(0, 0, 0, 0.12);
  --mat-expansion-header-hover-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-expansion-header-focus-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-expansion-header-disabled-state-text-color: rgba(0, 0, 0, 0.26);
  --mat-expansion-header-text-color: rgba(0, 0, 0, 0.87);
  --mat-expansion-header-description-color: rgba(0, 0, 0, 0.54);
  --mat-expansion-header-indicator-color: rgba(0, 0, 0, 0.54);
}

html {
  --mat-expansion-header-collapsed-state-height: 48px;
  --mat-expansion-header-expanded-state-height: 64px;
}

html {
  --mat-expansion-header-text-font: Roboto, sans-serif;
  --mat-expansion-header-text-size: 14px;
  --mat-expansion-header-text-weight: 500;
  --mat-expansion-header-text-line-height: inherit;
  --mat-expansion-header-text-tracking: inherit;
  --mat-expansion-container-text-font: Roboto;
  --mat-expansion-container-text-line-height: 1.57;
  --mat-expansion-container-text-size: 0.875rem;
  --mat-expansion-container-text-tracking: normal;
  --mat-expansion-container-text-weight: 400;
}

html {
  --mat-grid-list-tile-header-primary-text-size: 0.875rem;
  --mat-grid-list-tile-header-secondary-text-size: 12px;
  --mat-grid-list-tile-footer-primary-text-size: 0.875rem;
  --mat-grid-list-tile-footer-secondary-text-size: 12px;
}

html {
  --mat-icon-color: inherit;
}

.mat-icon.mat-primary {
  --mat-icon-color: #c91019;
}
.mat-icon.mat-accent {
  --mat-icon-color: #5d6a72;
}
.mat-icon.mat-warn {
  --mat-icon-color: #e67300;
}

html {
  --mat-sidenav-container-shape: 0;
  --mat-sidenav-container-elevation-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
  --mat-sidenav-container-width: auto;
}

html {
  --mat-sidenav-container-divider-color: rgba(0, 0, 0, 0.12);
  --mat-sidenav-container-background-color: white;
  --mat-sidenav-container-text-color: rgba(0, 0, 0, 0.87);
  --mat-sidenav-content-background-color: #fafafa;
  --mat-sidenav-content-text-color: rgba(0, 0, 0, 0.87);
  --mat-sidenav-scrim-color: rgba(0, 0, 0, 0.6);
}

html {
  --mat-stepper-header-icon-foreground-color: white;
  --mat-stepper-header-selected-state-icon-background-color: #c91019;
  --mat-stepper-header-selected-state-icon-foreground-color: white;
  --mat-stepper-header-done-state-icon-background-color: #c91019;
  --mat-stepper-header-done-state-icon-foreground-color: white;
  --mat-stepper-header-edit-state-icon-background-color: #c91019;
  --mat-stepper-header-edit-state-icon-foreground-color: white;
  --mat-stepper-container-color: white;
  --mat-stepper-line-color: rgba(0, 0, 0, 0.12);
  --mat-stepper-header-hover-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-stepper-header-focus-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-stepper-header-label-text-color: rgba(0, 0, 0, 0.54);
  --mat-stepper-header-optional-label-text-color: rgba(0, 0, 0, 0.54);
  --mat-stepper-header-selected-state-label-text-color: rgba(0, 0, 0, 0.87);
  --mat-stepper-header-error-state-label-text-color: #e67300;
  --mat-stepper-header-icon-background-color: rgba(0, 0, 0, 0.54);
  --mat-stepper-header-error-state-icon-foreground-color: #e67300;
  --mat-stepper-header-error-state-icon-background-color: transparent;
}
html .mat-step-header.mat-accent {
  --mat-stepper-header-icon-foreground-color: white;
  --mat-stepper-header-selected-state-icon-background-color: #5d6a72;
  --mat-stepper-header-selected-state-icon-foreground-color: white;
  --mat-stepper-header-done-state-icon-background-color: #5d6a72;
  --mat-stepper-header-done-state-icon-foreground-color: white;
  --mat-stepper-header-edit-state-icon-background-color: #5d6a72;
  --mat-stepper-header-edit-state-icon-foreground-color: white;
}
html .mat-step-header.mat-warn {
  --mat-stepper-header-icon-foreground-color: white;
  --mat-stepper-header-selected-state-icon-background-color: #e67300;
  --mat-stepper-header-selected-state-icon-foreground-color: white;
  --mat-stepper-header-done-state-icon-background-color: #e67300;
  --mat-stepper-header-done-state-icon-foreground-color: white;
  --mat-stepper-header-edit-state-icon-background-color: #e67300;
  --mat-stepper-header-edit-state-icon-foreground-color: white;
}

html {
  --mat-stepper-header-height: 72px;
}

html {
  --mat-stepper-container-text-font: Roboto;
  --mat-stepper-header-label-text-font: Roboto;
  --mat-stepper-header-label-text-size: 0.875rem;
  --mat-stepper-header-label-text-weight: 400;
  --mat-stepper-header-error-state-label-text-size: 1rem;
  --mat-stepper-header-selected-state-label-text-size: 1rem;
  --mat-stepper-header-selected-state-label-text-weight: 500;
}

html {
  --mat-sort-arrow-color: rgb(117.3, 117.3, 117.3);
}

html {
  --mat-toolbar-container-background-color: whitesmoke;
  --mat-toolbar-container-text-color: rgba(0, 0, 0, 0.87);
}

.mat-toolbar.mat-primary {
  --mat-toolbar-container-background-color: #c91019;
  --mat-toolbar-container-text-color: white;
}
.mat-toolbar.mat-accent {
  --mat-toolbar-container-background-color: #5d6a72;
  --mat-toolbar-container-text-color: white;
}
.mat-toolbar.mat-warn {
  --mat-toolbar-container-background-color: #e67300;
  --mat-toolbar-container-text-color: white;
}

html {
  --mat-toolbar-standard-height: 64px;
  --mat-toolbar-mobile-height: 56px;
}

html {
  --mat-toolbar-title-text-font: PalfingerHandelVersalien;
  --mat-toolbar-title-text-line-height: 1;
  --mat-toolbar-title-text-size: 1.5rem;
  --mat-toolbar-title-text-tracking: normal;
  --mat-toolbar-title-text-weight: 500;
}

html {
  --mat-tree-container-background-color: white;
  --mat-tree-node-text-color: rgba(0, 0, 0, 0.87);
}

html {
  --mat-tree-node-min-height: 48px;
}

html {
  --mat-tree-node-text-font: Roboto;
  --mat-tree-node-text-size: 0.875rem;
  --mat-tree-node-text-weight: 400;
}

html {
  --mat-timepicker-container-shape: 4px;
  --mat-timepicker-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}

html {
  --mat-timepicker-container-background-color: white;
}

@font-face {
  font-family: PalfingerIcons;
  src: url("/assets/fonts/PalfingerIcons.eot");
  src: url("/assets/fonts/PalfingerIcons.eot?#iefix") format("embedded-opentype"), url("/assets/fonts/PalfingerIcons.woff2") format("woff2"), url("/assets/fonts/PalfingerIcons.woff") format("woff"), url("/assets/fonts/PalfingerIcons.ttf") format("truetype"), url("/assets/fonts/PalfingerIcons.svg#PalfingerIcons") format("svg");
  font-style: normal;
  font-weight: 400;
}
.pd {
  display: inline-block;
  transform: translate(0, 0);
  text-rendering: auto;
  font: normal normal 400 14px/1 PalfingerIcons;
  font-size: inherit;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
}

.pd-lg {
  vertical-align: -15%;
  line-height: 0.75em;
  font-size: 1.33333333em;
}

.pd-2x {
  font-size: 2em;
}

.pd-3x {
  font-size: 3em;
}

.pd-4x {
  font-size: 4em;
}

.pd-5x {
  font-size: 5em;
}

.pd-fw {
  width: 1.28571429em;
  text-align: center;
}

.pd-access-denied::before {
  content: "\ea01";
}

.pd-alerts::before {
  content: "\ea02";
}

.pd-axis-y-rotate::before {
  content: "\ea03";
}

.pd-axis-z-rotate::before {
  content: "\ea04";
}

.pd-carrier::before {
  content: "\ea05";
}

.pd-carrier-management::before {
  content: "\ea06";
}

.pd-code-reader::before {
  content: "\ea07";
}

.pd-crane-functions::before {
  content: "\ea08";
}

.pd-crane-lifting-power::before {
  content: "\ea09";
}

.pd-crane-outreach::before {
  content: "\ea0a";
}

.pd-documents::before {
  content: "\ea0b";
}

.pd-engine::before {
  content: "\ea0c";
}

.pd-equipment-status::before {
  content: "\ea0d";
}

.pd-filter::before {
  content: "\ea0e";
}

.pd-fleet-monitor::before {
  content: "\ea0f";
}

.pd-fleet-status::before {
  content: "\ea10";
}

.pd-geofence::before {
  content: "\ea11";
}

.pd-height-limitation::before {
  content: "\ea12";
}

.pd-job-planning::before {
  content: "\ea13";
}

.pd-local_shipping_outlined::before {
  content: "\ea14";
}

.pd-location::before {
  content: "\ea15";
}

.pd-location-status::before {
  content: "\ea16";
}

.pd-lubricant-1::before {
  content: "\ea17";
}

.pd-lubricant-2::before {
  content: "\ea18";
}

.pd-lubricant-3::before {
  content: "\ea19";
}

.pd-lubricant-4::before {
  content: "\ea1a";
}

.pd-lubricant-5::before {
  content: "\ea1b";
}

.pd-mail-read-and-prio::before {
  content: "\ea1c";
}

.pd-maintenance-checklist::before {
  content: "\ea1d";
}

.pd-market-model::before {
  content: "\ea1e";
}

.pd-no-search-result::before {
  content: "\ea1f";
}

.pd-oh-crane::before {
  content: "\ea20";
}

.pd-oh-dailyPTO::before {
  content: "\ea21";
}

.pd-oh-emergencyModeActivation::before {
  content: "\ea22";
}

.pd-oh-flyjib::before {
  content: "\ea23";
}

.pd-oh-hookloader::before {
  content: "\ea24";
}

.pd-oh-leverNotNeutral::before {
  content: "\ea25";
}

.pd-oh-loadingOperations::before {
  content: "\ea26";
}

.pd-oh-manualOverride::before {
  content: "\ea27";
}

.pd-oh-mechv::before {
  content: "\ea28";
}

.pd-oh-platforms::before {
  content: "\ea29";
}

.pd-oh-taillift::before {
  content: "\ea2a";
}

.pd-oh-tippingOperations::before {
  content: "\ea2b";
}

.pd-oh-tool::before {
  content: "\ea2c";
}

.pd-oh-truckMountedForklifts::before {
  content: "\ea2d";
}

.pd-oh-winch::before {
  content: "\ea2e";
}

.pd-oh-winch_rtc::before {
  content: "\ea2f";
}

.pd-oh-workingBasket::before {
  content: "\ea30";
}

.pd-oh-workingBasket_leverNotNeutral::before {
  content: "\ea31";
}

.pd-pac-online::before {
  content: "\ea32";
}

.pd-palcode::before {
  content: "\ea33";
}

.pd-paldrive::before {
  content: "\ea34";
}

.pd-palshow-web::before {
  content: "\ea35";
}

.pd-palsoft-updates::before {
  content: "\ea36";
}

.pd-route::before {
  content: "\ea37";
}

.pd-sentiment-dissatisfied::before {
  content: "\ea38";
}

.pd-sentiment-satisfied::before {
  content: "\ea39";
}

.pd-service::before {
  content: "\ea3a";
}

.pd-service-cockpit::before {
  content: "\ea3b";
}

.pd-service-partner-search::before {
  content: "\ea3c";
}

.pd-smarteye::before {
  content: "\ea3d";
}

.pd-software-options::before {
  content: "\ea3e";
}

.pd-stability-chart::before {
  content: "\ea3f";
}

.pd-stabilizer-left::before {
  content: "\ea40";
}

.pd-stabilizer-left-disabled::before {
  content: "\ea41";
}

.pd-stabilizer-right::before {
  content: "\ea42";
}

.pd-stabilizer-right-disabled::before {
  content: "\ea43";
}

.pd-telematic::before {
  content: "\ea44";
}

.pd-telematic-doctor::before {
  content: "\ea45";
}

.pd-telematic-lab::before {
  content: "\ea46";
}

.pd-utilization::before {
  content: "\ea47";
}

.pd {
  text-align: center;
  font-size: var(--ds-icon-size);
}

/* roboto-100 - vietnamese_latin-ext_latin_greek-ext_greek_cyrillic-ext_cyrillic */
@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 100;
  src: url("/assets/fonts/roboto-v20-vietnamese_latin-ext_latin_greek-ext_greek_cyrillic-ext_cyrillic-100.eot"); /* IE9 Compat Modes */
  src: local("Roboto Thin"), local("Roboto-Thin"), url("/assets/fonts/roboto-v20-vietnamese_latin-ext_latin_greek-ext_greek_cyrillic-ext_cyrillic-100.eot?#iefix") format("embedded-opentype"), url("/assets/fonts/roboto-v20-vietnamese_latin-ext_latin_greek-ext_greek_cyrillic-ext_cyrillic-100.woff2") format("woff2"), url("/assets/fonts/roboto-v20-vietnamese_latin-ext_latin_greek-ext_greek_cyrillic-ext_cyrillic-100.woff") format("woff"), url("/assets/fonts/roboto-v20-vietnamese_latin-ext_latin_greek-ext_greek_cyrillic-ext_cyrillic-100.ttf") format("truetype"), url("/assets/fonts/roboto-v20-vietnamese_latin-ext_latin_greek-ext_greek_cyrillic-ext_cyrillic-100.svg#Roboto") format("svg"); /* Legacy iOS */
}
/* roboto-300 - vietnamese_latin-ext_latin_greek-ext_greek_cyrillic-ext_cyrillic */
@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 300;
  src: url("/assets/fonts/roboto-v20-vietnamese_latin-ext_latin_greek-ext_greek_cyrillic-ext_cyrillic-300.eot"); /* IE9 Compat Modes */
  src: local("Roboto Light"), local("Roboto-Light"), url("/assets/fonts/roboto-v20-vietnamese_latin-ext_latin_greek-ext_greek_cyrillic-ext_cyrillic-300.eot?#iefix") format("embedded-opentype"), url("/assets/fonts/roboto-v20-vietnamese_latin-ext_latin_greek-ext_greek_cyrillic-ext_cyrillic-300.woff2") format("woff2"), url("/assets/fonts/roboto-v20-vietnamese_latin-ext_latin_greek-ext_greek_cyrillic-ext_cyrillic-300.woff") format("woff"), url("/assets/fonts/roboto-v20-vietnamese_latin-ext_latin_greek-ext_greek_cyrillic-ext_cyrillic-300.ttf") format("truetype"), url("/assets/fonts/roboto-v20-vietnamese_latin-ext_latin_greek-ext_greek_cyrillic-ext_cyrillic-300.svg#Roboto") format("svg"); /* Legacy iOS */
}
/* roboto-regular - vietnamese_latin-ext_latin_greek-ext_greek_cyrillic-ext_cyrillic */
@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  src: url("/assets/fonts/roboto-v20-vietnamese_latin-ext_latin_greek-ext_greek_cyrillic-ext_cyrillic-regular.eot"); /* IE9 Compat Modes */
  src: local("Roboto"), local("Roboto Regular"), local("Roboto-Regular"), url("/assets/fonts/roboto-v20-vietnamese_latin-ext_latin_greek-ext_greek_cyrillic-ext_cyrillic-regular.eot?#iefix") format("embedded-opentype"), url("/assets/fonts/roboto-v20-vietnamese_latin-ext_latin_greek-ext_greek_cyrillic-ext_cyrillic-regular.woff2") format("woff2"), url("/assets/fonts/roboto-v20-vietnamese_latin-ext_latin_greek-ext_greek_cyrillic-ext_cyrillic-regular.woff") format("woff"), url("/assets/fonts/roboto-v20-vietnamese_latin-ext_latin_greek-ext_greek_cyrillic-ext_cyrillic-regular.ttf") format("truetype"), url("/assets/fonts/roboto-v20-vietnamese_latin-ext_latin_greek-ext_greek_cyrillic-ext_cyrillic-regular.svg#Roboto") format("svg"); /* Legacy iOS */
}
/* roboto-500 - vietnamese_latin-ext_latin_greek-ext_greek_cyrillic-ext_cyrillic */
@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  src: url("/assets/fonts/roboto-v20-vietnamese_latin-ext_latin_greek-ext_greek_cyrillic-ext_cyrillic-500.eot"); /* IE9 Compat Modes */
  src: local("Roboto Medium"), local("Roboto-Medium"), url("/assets/fonts/roboto-v20-vietnamese_latin-ext_latin_greek-ext_greek_cyrillic-ext_cyrillic-500.eot?#iefix") format("embedded-opentype"), url("/assets/fonts/roboto-v20-vietnamese_latin-ext_latin_greek-ext_greek_cyrillic-ext_cyrillic-500.woff2") format("woff2"), url("/assets/fonts/roboto-v20-vietnamese_latin-ext_latin_greek-ext_greek_cyrillic-ext_cyrillic-500.woff") format("woff"), url("/assets/fonts/roboto-v20-vietnamese_latin-ext_latin_greek-ext_greek_cyrillic-ext_cyrillic-500.ttf") format("truetype"), url("/assets/fonts/roboto-v20-vietnamese_latin-ext_latin_greek-ext_greek_cyrillic-ext_cyrillic-500.svg#Roboto") format("svg"); /* Legacy iOS */
}
/* roboto-700 - vietnamese_latin-ext_latin_greek-ext_greek_cyrillic-ext_cyrillic */
@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  src: url("/assets/fonts/roboto-v20-vietnamese_latin-ext_latin_greek-ext_greek_cyrillic-ext_cyrillic-700.eot"); /* IE9 Compat Modes */
  src: local("Roboto Bold"), local("Roboto-Bold"), url("/assets/fonts/roboto-v20-vietnamese_latin-ext_latin_greek-ext_greek_cyrillic-ext_cyrillic-700.eot?#iefix") format("embedded-opentype"), url("/assets/fonts/roboto-v20-vietnamese_latin-ext_latin_greek-ext_greek_cyrillic-ext_cyrillic-700.woff2") format("woff2"), url("/assets/fonts/roboto-v20-vietnamese_latin-ext_latin_greek-ext_greek_cyrillic-ext_cyrillic-700.woff") format("woff"), url("/assets/fonts/roboto-v20-vietnamese_latin-ext_latin_greek-ext_greek_cyrillic-ext_cyrillic-700.ttf") format("truetype"), url("/assets/fonts/roboto-v20-vietnamese_latin-ext_latin_greek-ext_greek_cyrillic-ext_cyrillic-700.svg#Roboto") format("svg"); /* Legacy iOS */
}
/* roboto-900 - vietnamese_latin-ext_latin_greek-ext_greek_cyrillic-ext_cyrillic */
@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 900;
  src: url("/assets/fonts/roboto-v20-vietnamese_latin-ext_latin_greek-ext_greek_cyrillic-ext_cyrillic-900.eot"); /* IE9 Compat Modes */
  src: local("Roboto Black"), local("Roboto-Black"), url("/assets/fonts/roboto-v20-vietnamese_latin-ext_latin_greek-ext_greek_cyrillic-ext_cyrillic-900.eot?#iefix") format("embedded-opentype"), url("/assets/fonts/roboto-v20-vietnamese_latin-ext_latin_greek-ext_greek_cyrillic-ext_cyrillic-900.woff2") format("woff2"), url("/assets/fonts/roboto-v20-vietnamese_latin-ext_latin_greek-ext_greek_cyrillic-ext_cyrillic-900.woff") format("woff"), url("/assets/fonts/roboto-v20-vietnamese_latin-ext_latin_greek-ext_greek_cyrillic-ext_cyrillic-900.ttf") format("truetype"), url("/assets/fonts/roboto-v20-vietnamese_latin-ext_latin_greek-ext_greek_cyrillic-ext_cyrillic-900.svg#Roboto") format("svg"); /* Legacy iOS */
}
@font-face {
  font-family: "PalfingerHandelVersalien";
  src: url("/assets/fonts/palfingerhandelversalien-webfont.woff2") format("woff2"), url("/assets/fonts/palfingerhandelversalien-webfont.woff") format("woff");
}
@font-face {
  font-family: "Material Icons";
  font-style: normal;
  font-weight: 400;
  src: local("Material Icons"), local("MaterialIcons-Regular"), url("/assets/materialicons/MaterialIcons-Regular.woff2") format("woff2"), url("/assets/materialicons/MaterialIcons-Regular.woff") format("woff"), url("/assets/materialicons/MaterialIcons-Regular.ttf") format("truetype");
}
.material-icons, :root .mat-mdc-select-arrow::before {
  font-family: "Material Icons";
  font-weight: normal;
  font-style: normal;
  font-size: var(--ds-icon-size);
  display: inline-block;
  line-height: 1;
  text-transform: none;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: "liga";
}

* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
  color: var(--ds-neutral-900);
}

a {
  color: var(--ds-primary-500);
  cursor: pointer;
  text-decoration: underline;
}

a.no-underline {
  cursor: pointer;
  text-decoration: none !important;
}

*:not(.mdc-list-item__content) > a[href*=http]:not(.mat-mdc-button-base):not(.mat-mdc-list-item):not(.mat-mdc-menu-item):not([href*=map]) {
  position: relative;
  padding-right: calc(0.8 * var(--ds-spacing));
}
*:not(.mdc-list-item__content) > a[href*=http]:not(.mat-mdc-button-base):not(.mat-mdc-list-item):not(.mat-mdc-menu-item):not([href*=map])::after {
  position: absolute;
  font-family: "Material Icons";
  content: "\e89e";
  color: var(--ds-neutral-900);
  padding-left: calc(0.1 * var(--ds-spacing));
}
*:not(.mdc-list-item__content) > a[href*=http]:not(.mat-mdc-button-base):not(.mat-mdc-list-item):not(.mat-mdc-menu-item):not([href*=map]).no-icon {
  padding-right: 0;
}
*:not(.mdc-list-item__content) > a[href*=http]:not(.mat-mdc-button-base):not(.mat-mdc-list-item):not(.mat-mdc-menu-item):not([href*=map]).no-icon::after {
  display: none;
}

.mat-mdc-menu-item .mat-icon-no-color,
.mat-mdc-menu-submenu-icon {
  color: var(--mdc-theme-text-primary-on-background, var(--ds-neutral-900));
}

strong {
  font-weight: 500;
}

h5:not([class*=default]),
.h5,
h6:not([class*=default]),
.h6 {
  line-height: 1;
}

h1:not([class*=default]),
.h1 {
  font-family: "PalfingerHandelVersalien";
}
@media screen and (max-width: 599px) {
  h1:not([class*=default]),
  .h1 {
    font-size: 1.5rem;
    font-weight: 400;
  }
}

h2:not([class*=default]),
.h2 {
  font-family: "PalfingerHandelVersalien";
}
@media screen and (max-width: 599px) {
  h2:not([class*=default]),
  .h2 {
    font-size: 1.5rem;
  }
}

h3:not([class*=default]),
.h3 {
  font-family: "PalfingerHandelVersalien";
  font-size: 1.25rem;
}
@media screen and (max-width: 599px) {
  h3:not([class*=default]),
  .h3 {
    font-size: 1rem;
  }
}

h4:not([class*=default]),
.h4 {
  font-weight: 500;
}
@media screen and (max-width: 599px) {
  h4:not([class*=default]),
  .h4 {
    font-size: 0.875rem;
  }
}

h5:not([class*=default]),
.h5 {
  font-size: 0.875rem;
  font-weight: 500;
  line-height: inherit;
}
@media screen and (max-width: 599px) {
  h5:not([class*=default]),
  .h5 {
    font-size: 0.875rem;
    font-weight: normal;
    text-decoration: underline;
  }
}

h6:not([class*=default]),
.h6 {
  text-transform: uppercase;
  font-weight: 500;
  font-size: 0.65rem;
  color: var(--ds-neutral-500);
}

h1.no-palfinger-font,
.h1.no-palfinger-font,
h2.no-palfinger-font,
.h2.no-palfinger-font,
h3.no-palfinger-font,
.h3.no-palfinger-font,
h4.no-palfinger-font,
.h4.no-palfinger-font {
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
}

h1 small,
h2 small,
h3 small,
h4 small,
h5 small,
h6 small,
.h1 small,
.h2 small,
.h3 small,
.h4 small,
.h5 small,
.h6 small,
h1 .small,
h2 .small,
h3 .small,
h4 .small,
h5 .small,
h6 .small,
.h1 .small,
.h2 .small,
.h3 .small,
.h4 .small,
.h5 .small,
.h6 .small {
  color: var(--ds-neutral-700);
}

h1 small,
.h1 small,
h2 small,
.h2 small,
h3 small,
.h3 small,
h1 .small,
.h1 .small,
h2 .small,
.h2 .small,
h3 .small,
.h3 .small {
  font-size: 65%;
}

h4 small,
.h4 small,
h5 small,
.h5 small,
h6 small,
.h6 small,
h4 .small,
.h4 .small,
h5 .small,
.h5 .small,
h6 .small,
.h6 .small {
  font-size: 70%;
}

small:not([class*=default]),
.small {
  font-size: 85%;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.text-left {
  text-align: left;
}

.text-bold {
  font-weight: 500;
}

html {
  height: 100%;
}

body {
  margin: 0;
  height: 100%;
  background: var(--ds-neutral-10);
  padding: 0;
  box-sizing: border-box;
  position: relative;
}

code {
  background: var(--ds-neutral-50);
  -webkit-print-color-adjust: exact;
  padding: calc(0.25 * var(--ds-spacing));
  display: inline-block;
  font-size: 1rem;
}
code strong {
  font-weight: 600;
}

.multiline-tooltip {
  height: auto !important;
  white-space: pre-line;
}

.bigger-font-tooltip {
  font-size: 0.8rem;
}

.mat-drawer {
  border-right: none;
}

.mat-drawer-container,
.mat-drawer-container h1 {
  background: none;
  color: var(--ds-neutral-900) !important;
}

.mat-mdc-card:not([class*=mat-elevation-z]),
.ds-styles .mat-expansion-panel:not([class*=default], [class*=mat-elevation-z]),
ds-app-wrapper .mat-expansion-panel:not([class*=default], [class*=mat-elevation-z]) {
  box-shadow: 0px calc(0.5 * var(--ds-spacing)) var(--ds-spacing) calc(0.5 * var(--ds-spacing)) rgba(0, 0, 0, 0.03);
}

mat-tab-body .mat-mdc-card:not([class*=mat-elevation-z]) {
  box-shadow: none;
}

.mdc-fab:not([class*=mat-elevation-z]) {
  box-shadow: 0px calc(0.5 * var(--ds-spacing)) var(--ds-spacing) calc(0.5 * var(--ds-spacing)) rgba(0, 0, 0, 0.12);
}

.mdc-fab:not([disabled]):active:not([class*=mat-elevation-z]) {
  box-shadow: 0px calc(0.5 * var(--ds-spacing)) var(--ds-spacing) calc(0.5 * var(--ds-spacing)) rgba(0, 0, 0, 0.22);
}

.mat-mdc-snack-bar-container:not([class*=mat-elevation-z]) {
  box-shadow: 0px calc(0.5 * var(--ds-spacing)) var(--ds-spacing) calc(0.5 * var(--ds-spacing)) rgba(0, 0, 0, 0.12);
}

.mat-elevation-z0 {
  box-shadow: 0px calc(0.5 * var(--ds-spacing)) var(--ds-spacing) calc(0.5 * var(--ds-spacing)) rgba(0, 0, 0, 0.02);
}

.mat-elevation-z1 {
  box-shadow: 0px calc(0.5 * var(--ds-spacing)) var(--ds-spacing) calc(0.5 * var(--ds-spacing)) rgba(0, 0, 0, 0.03);
}

.mat-elevation-z2 {
  box-shadow: 0px calc(0.5 * var(--ds-spacing)) var(--ds-spacing) calc(0.5 * var(--ds-spacing)) rgba(0, 0, 0, 0.04);
}

.mat-elevation-z3 {
  box-shadow: 0px calc(0.5 * var(--ds-spacing)) var(--ds-spacing) calc(0.5 * var(--ds-spacing)) rgba(0, 0, 0, 0.05);
}

.mat-elevation-z4 {
  box-shadow: 0px calc(0.5 * var(--ds-spacing)) var(--ds-spacing) calc(0.5 * var(--ds-spacing)) rgba(0, 0, 0, 0.06);
}

.mat-elevation-z5 {
  box-shadow: 0px calc(0.5 * var(--ds-spacing)) var(--ds-spacing) calc(0.5 * var(--ds-spacing)) rgba(0, 0, 0, 0.07);
}

.mat-elevation-z6 {
  box-shadow: 0px calc(0.5 * var(--ds-spacing)) var(--ds-spacing) calc(0.5 * var(--ds-spacing)) rgba(0, 0, 0, 0.08);
}

.mat-elevation-z7 {
  box-shadow: 0px calc(0.5 * var(--ds-spacing)) var(--ds-spacing) calc(0.5 * var(--ds-spacing)) rgba(0, 0, 0, 0.09);
}

.mat-elevation-z8 {
  box-shadow: 0px calc(0.5 * var(--ds-spacing)) var(--ds-spacing) calc(0.5 * var(--ds-spacing)) rgba(0, 0, 0, 0.1);
}

.mat-elevation-z9 {
  box-shadow: 0px calc(0.5 * var(--ds-spacing)) var(--ds-spacing) calc(0.5 * var(--ds-spacing)) rgba(0, 0, 0, 0.11);
}

.mat-elevation-z10 {
  box-shadow: 0px calc(0.5 * var(--ds-spacing)) var(--ds-spacing) calc(0.5 * var(--ds-spacing)) rgba(0, 0, 0, 0.12);
}

.mat-elevation-z11 {
  box-shadow: 0px calc(0.5 * var(--ds-spacing)) var(--ds-spacing) calc(0.5 * var(--ds-spacing)) rgba(0, 0, 0, 0.13);
}

.mat-elevation-z12 {
  box-shadow: 0px calc(0.5 * var(--ds-spacing)) var(--ds-spacing) calc(0.5 * var(--ds-spacing)) rgba(0, 0, 0, 0.14);
}

.mat-elevation-z13 {
  box-shadow: 0px calc(0.5 * var(--ds-spacing)) var(--ds-spacing) calc(0.5 * var(--ds-spacing)) rgba(0, 0, 0, 0.15);
}

.mat-elevation-z14 {
  box-shadow: 0px calc(0.5 * var(--ds-spacing)) var(--ds-spacing) calc(0.5 * var(--ds-spacing)) rgba(0, 0, 0, 0.16);
}

.mat-elevation-z15 {
  box-shadow: 0px calc(0.5 * var(--ds-spacing)) var(--ds-spacing) calc(0.5 * var(--ds-spacing)) rgba(0, 0, 0, 0.17);
}

.mat-elevation-z16 {
  box-shadow: 0px calc(0.5 * var(--ds-spacing)) var(--ds-spacing) calc(0.5 * var(--ds-spacing)) rgba(0, 0, 0, 0.18);
}

.mat-elevation-z17 {
  box-shadow: 0px calc(0.5 * var(--ds-spacing)) var(--ds-spacing) calc(0.5 * var(--ds-spacing)) rgba(0, 0, 0, 0.19);
}

.mat-elevation-z18 {
  box-shadow: 0px calc(0.5 * var(--ds-spacing)) var(--ds-spacing) calc(0.5 * var(--ds-spacing)) rgba(0, 0, 0, 0.2);
}

.mat-elevation-z19 {
  box-shadow: 0px calc(0.5 * var(--ds-spacing)) var(--ds-spacing) calc(0.5 * var(--ds-spacing)) rgba(0, 0, 0, 0.21);
}

.mat-elevation-z20 {
  box-shadow: 0px calc(0.5 * var(--ds-spacing)) var(--ds-spacing) calc(0.5 * var(--ds-spacing)) rgba(0, 0, 0, 0.22);
}

.mat-elevation-z21 {
  box-shadow: 0px calc(0.5 * var(--ds-spacing)) var(--ds-spacing) calc(0.5 * var(--ds-spacing)) rgba(0, 0, 0, 0.23);
}

.mat-elevation-z22 {
  box-shadow: 0px calc(0.5 * var(--ds-spacing)) var(--ds-spacing) calc(0.5 * var(--ds-spacing)) rgba(0, 0, 0, 0.24);
}

.mat-elevation-z23 {
  box-shadow: 0px calc(0.5 * var(--ds-spacing)) var(--ds-spacing) calc(0.5 * var(--ds-spacing)) rgba(0, 0, 0, 0.25);
}

.mat-elevation-z24 {
  box-shadow: 0px calc(0.5 * var(--ds-spacing)) var(--ds-spacing) calc(0.5 * var(--ds-spacing)) rgba(0, 0, 0, 0.26);
}

.page-content {
  display: flex;
  flex-direction: column;
  padding-top: var(--ds-spacing);
  padding-bottom: calc(2 * var(--ds-spacing));
  padding-left: calc(2 * var(--ds-spacing));
  padding-right: calc(2 * var(--ds-spacing));
  box-sizing: border-box;
  padding: calc(1 * var(--ds-spacing)) calc(1.5 * var(--ds-spacing)) calc(2 * var(--ds-spacing));
}

@media screen and (min-width: 600px) and (max-width: 959px) {
  .page-content {
    padding-left: calc(1.5 * var(--ds-spacing));
    padding-right: calc(1.5 * var(--ds-spacing));
  }
}
@media screen and (max-width: 599px) {
  .page-content {
    padding-left: var(--ds-spacing);
    padding-right: var(--ds-spacing);
  }
}
@media screen and (min-width: 600px) and (max-width: 959px) {
  .page-content {
    padding-right: calc(2 * var(--ds-spacing));
  }
  .page-content.no-sidebar {
    padding-left: calc(2 * var(--ds-spacing));
  }
}
@media print {
  .page-content {
    display: unset;
  }
}
:root .mdc-dialog {
  --mdc-dialog-subhead-color: var(--ds-info-A100);
  --mdc-dialog-container-shape: var(--ds-border-radius);
  --mdc-dialog-subhead-size: 1.25rem;
}
:root .mat-mdc-dialog-container:not([class*=default]) {
  border-radius: var(--ds-border-radius);
  overflow: hidden;
}
:root .mat-mdc-dialog-content,
:root .mat-mdc-dialog-actions,
:root .mat-mdc-dialog-title {
  padding: var(--ds-spacing);
}
:root .mat-mdc-dialog-title + .mat-mdc-dialog-content {
  padding-top: var(--ds-spacing) !important;
  margin-top: calc(-1 * var(--ds-spacing)) !important;
}
:root .mat-mdc-dialog-title:not([class*=default]) {
  margin: 0 0 var(--ds-spacing);
  display: block;
  padding-top: 0;
  color: var(--ds-info-A100);
  background: var(--ds-neutral-900);
  font-family: PalfingerHandelVersalien, Helvetica, sans-serif;
}
:root .mat-mdc-dialog-actions:not([class*=default]) {
  background: var(--ds-neutral-50);
  padding-top: calc(0.5 * var(--ds-spacing));
  padding-bottom: calc(0.5 * var(--ds-spacing));
  justify-content: flex-end;
  min-height: unset !important;
}
:root .mat-mdc-dialog-container .mdc-dialog__content {
  color: var(--ds-neutral-900);
  font-size: 1rem;
  font-weight: normal;
  line-height: 1.5;
}
:root .mat-mdc-dialog-container .mdc-dialog__title.default {
  color: var(--ds-neutral-900);
}

.cdk-global-scrollblock {
  overflow-y: hidden !important;
}

table {
  border-spacing: 0;
}

table.pd-table:not([class*=default]),
table.mat-mdc-table:not([class*=default]) {
  border-spacing: 0;
  background: none;
}

table.mat-mdc-table thead tr,
table.pd-table thead tr,
tr.mat-mdc-header-row:not([class*=default]) {
  height: calc(3 * var(--ds-spacing));
  background: var(--ds-neutral-contrast-500) !important;
}

table.mat-mdc-table tbody tr,
table.mat-mdc-table tfoot tr,
table.pd-table tbody tr,
table.pd-table tfoot tr,
tr.mat-mdc-row:not([class*=default]),
tr.mat-mdc-footer-row:not([class*=default]) {
  height: calc(2.5 * var(--ds-spacing));
}

table.mat-mdc-table thead th,
table.pd-table thead th,
th.mat-mdc-header-cell:not([class*=default]) {
  font-size: inherit;
  color: inherit;
  font-weight: 500;
}
table.mat-mdc-table thead th:not([class*=text]),
table.pd-table thead th:not([class*=text]),
th.mat-mdc-header-cell:not([class*=default]):not([class*=text]) {
  text-align: left;
}

ds-table tr:hover:not(.no-hover-effect) td,
.pd-table tr:hover:not(.no-hover-effect) td,
ds-table tr.mat-mdc-row:hover:not(.no-hover-effect),
.pd-table tr.mat-mdc-row:hover:not(.no-hover-effect),
ds-table tr.mat-mdc-row:hover:not(.no-hover-effect) .mat-mdc-table-sticky,
.pd-table tr.mat-mdc-row:hover:not(.no-hover-effect) .mat-mdc-table-sticky,
ds-table tr.mat-row:hover:not(.no-hover-effect) .mat-table-sticky,
.pd-table tr.mat-row:hover:not(.no-hover-effect) .mat-table-sticky {
  background: var(--ds-neutral-50) !important;
  opacity: 100%;
  transition-duration: var(--ds-duration);
  transition-transition-property: background;
}

table.mat-mdc-table thead th,
table.mat-mdc-table td,
table.pd-table thead th,
table.pd-table td,
th.mat-mdc-header-cell:not([class*=default]),
td.mat-mdc-cell:not([class*=default]),
td.mat-mdc-footer-cell:not([class*=default]) {
  padding: calc(0.5 * var(--ds-spacing));
  border-width: 0 0 1px;
  border-style: solid;
  border-color: var(--ds-neutral-50);
}
table.mat-mdc-table thead th:not([class*=white-space]),
table.mat-mdc-table td:not([class*=white-space]),
table.pd-table thead th:not([class*=white-space]),
table.pd-table td:not([class*=white-space]),
th.mat-mdc-header-cell:not([class*=default]):not([class*=white-space]),
td.mat-mdc-cell:not([class*=default]):not([class*=white-space]),
td.mat-mdc-footer-cell:not([class*=default]):not([class*=white-space]) {
  white-space: nowrap;
}
table.mat-mdc-table thead th > .mat-mdc-icon-button,
table.mat-mdc-table td > .mat-mdc-icon-button,
table.pd-table thead th > .mat-mdc-icon-button,
table.pd-table td > .mat-mdc-icon-button,
th.mat-mdc-header-cell:not([class*=default]) > .mat-mdc-icon-button,
td.mat-mdc-cell:not([class*=default]) > .mat-mdc-icon-button,
td.mat-mdc-footer-cell:not([class*=default]) > .mat-mdc-icon-button {
  vertical-align: inherit;
}

table.mat-mdc-table tfoot td,
table.pd-table tfoot td {
  border-width: 1px 0 0;
}

table.mat-mdc-table tr th:first-child,
table.mat-mdc-table tr td:first-child,
table.pd-table tr th:first-child,
table.pd-table tr td:first-child,
th.mat-mdc-header-cell:first-child:not([class*=default]),
td.mat-mdc-cell:first-child:not([class*=default]),
td.mat-mdc-footer-cell:first-child:not([class*=default]) {
  padding-left: var(--ds-spacing);
}

table.mat-mdc-table th:last-child:not([class*=default]):not(.mat-action-cell),
table.mat-mdc-table td:last-child:not([class*=default]):not(.mat-action-cell),
table.pd-table th:last-child:not([class*=default]):not(.mat-action-cell),
table.pd-table td:last-child:not([class*=default]):not(.mat-action-cell) {
  padding-right: var(--ds-spacing);
  white-space: nowrap;
}

table.mat-mdc-table tbody tr:last-child td,
table.pd-table tbody tr:last-child td,
tr.mat-mdc-row:last-child:not([class*=default]) td,
tr.mat-mdc-header-row:last-child:not([class*=default]) td,
tr.mat-mdc-footer-row:last-child:not([class*=default]) td {
  border-width: 0;
}

tr.mat-mdc-footer-row:last-child td.mat-mdc-table-sticky:not([class*=default]),
tr.mat-footer-row:last-child td.mat-table-sticky:not([class*=default]),
tr.mat-mdc-footer-row:last-child td.mat-mdc-footer-cell:not([class*=default]),
tr.mat-footer-row:last-child td.mat-table-sticky:not([class*=default]) {
  border-width: 1px 0 0;
}

td.mat-column-action {
  width: 1%;
  padding-right: calc(0.5 * var(--ds-spacing)) !important;
  white-space: nowrap;
}

:root [matlistitemtitle] {
  justify-content: flex-start !important;
}
:root .mat-mdc-list-item {
  padding: calc(0.5 * var(--ds-spacing));
  height: auto !important;
  font-size: 0.875rem !important;
}
:root .mdc-list-item--with-trailing-meta .mdc-list-item__end {
  align-self: center !important;
}

:root .mat-mdc-select {
  font-weight: normal;
  line-height: 1.1;
}
:root .mat-mdc-select-arrow:not([class*=default]) {
  border: none;
  height: auto;
  width: auto;
}
:root .mat-mdc-select-arrow:not([class*=default]) svg {
  display: none;
}
:root .mat-mdc-select-arrow::before {
  content: "expand_more";
}
:root .mat-mdc-option {
  font-size: 0.875rem !important;
}
:root .mat-mdc-select-panel:not([class*=default]), :root .mat-mdc-select-panel:not([class*=default]) .mat-mdc-form-field-text-suffix {
  font-family: 400 0.875rem / 1.57 Roboto !important;
  font-size: 0.875rem !important;
}
:root .mat-mdc-option:not(.mat-mdc-option-disabled) .mdc-list-item__primary-text,
:root .mdc-list-item__primary-text {
  color: var(--ds-neutral-900) !important;
  line-height: 1.2;
}
:root .mat-mdc-option-pseudo-checkbox.mat-pseudo-checkbox-checked {
  background-color: var(--ds-neutral-600);
}
:root .mat-mdc-option.mdc-list-item--selected {
  color: var(--ds-neutral-300);
}
:root .mat-primary .mat-mdc-option.mdc-list-item--selected:not(.mat-mdc-option-disabled):not([class*=default]) {
  color: var(--ds-neutral-900);
  background-color: var(--ds-neutral-50);
}
:root .mat-mdc-option-multiple .mat-pseudo-checkbox.mat-pseudo-checkbox-checked:not([class*=default]) {
  background-color: var(--ds-neutral-600);
}
:root .mat-pseudo-checkbox-minimal.mat-pseudo-checkbox-checked:not([class*=default]) {
  display: none;
}

:root .mat-mdc-chip.mat-mdc-standard-chip {
  --mdc-chip-label-text-color: var(--ds-neutral-contrast-500);
  --mdc-chip-disabled-label-text-color: var(--ds-neutral-contrast-500);
  --mdc-chip-with-icon-icon-color: var(--ds-neutral-contrast-500);
  --mdc-chip-with-icon-disabled-icon-color: var(--ds-neutral-contrast-500);
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: var(--ds-neutral-contrast-500);
  --mdc-chip-with-trailing-icon-trailing-icon-color: var(--ds-neutral-contrast-500);
  --mdc-chip-with-icon-selected-icon-color: var(--ds-neutral-contrast-500);
  background: var(--ds-neutral-300);
}

.page-content {
  display: flex;
  flex-direction: column;
  padding-top: var(--ds-spacing);
  padding-bottom: calc(2 * var(--ds-spacing));
  padding-left: calc(2 * var(--ds-spacing));
  padding-right: calc(2 * var(--ds-spacing));
  box-sizing: border-box;
  padding: calc(1 * var(--ds-spacing)) calc(1.5 * var(--ds-spacing)) calc(2 * var(--ds-spacing));
}

@media screen and (min-width: 600px) and (max-width: 959px) {
  .page-content {
    padding-left: calc(1.5 * var(--ds-spacing));
    padding-right: calc(1.5 * var(--ds-spacing));
  }
}
@media screen and (max-width: 599px) {
  .page-content {
    padding-left: var(--ds-spacing);
    padding-right: var(--ds-spacing);
  }
}
@media screen and (min-width: 600px) and (max-width: 959px) {
  .page-content {
    padding-right: calc(2 * var(--ds-spacing));
  }
  .page-content.no-sidebar {
    padding-left: calc(2 * var(--ds-spacing));
  }
}
@media print {
  .page-content {
    display: unset;
  }
}
.mat-stepper-horizontal.deprecated-style,
.mat-stepper-vertical.deprecated-style {
  display: flex;
  flex-direction: column;
  height: 100%;
  background: var(--ds-neutral-10);
}
.mat-stepper-horizontal.deprecated-style .mat-horizontal-stepper-header-container,
.mat-stepper-vertical.deprecated-style .mat-horizontal-stepper-header-container {
  flex: 0 0 auto;
}
.mat-stepper-horizontal.deprecated-style .mat-horizontal-stepper-header:not([class*=default]),
.mat-stepper-vertical.deprecated-style .mat-horizontal-stepper-header:not([class*=default]) {
  flex: 1;
  height: calc(3 * var(--ds-spacing));
  justify-content: center;
  padding: 0;
  overflow: visible;
}
.mat-stepper-horizontal.deprecated-style .mat-horizontal-content-container:not([class*=default]),
.mat-stepper-vertical.deprecated-style .mat-horizontal-content-container:not([class*=default]) {
  padding-top: var(--ds-spacing);
  padding-bottom: calc(2 * var(--ds-spacing));
  padding-left: calc(2 * var(--ds-spacing));
  padding-right: calc(2 * var(--ds-spacing));
  padding-top: 0;
  padding-bottom: 0;
  overflow: auto;
  overflow-x: hidden;
  flex: 1 1 auto;
}
@media screen and (min-width: 600px) and (max-width: 959px) {
  .mat-stepper-horizontal.deprecated-style .mat-horizontal-content-container:not([class*=default]),
  .mat-stepper-vertical.deprecated-style .mat-horizontal-content-container:not([class*=default]) {
    padding-left: calc(1.5 * var(--ds-spacing));
    padding-right: calc(1.5 * var(--ds-spacing));
  }
}
@media screen and (max-width: 599px) {
  .mat-stepper-horizontal.deprecated-style .mat-horizontal-content-container:not([class*=default]),
  .mat-stepper-vertical.deprecated-style .mat-horizontal-content-container:not([class*=default]) {
    padding-left: var(--ds-spacing);
    padding-right: var(--ds-spacing);
  }
}
.mat-stepper-horizontal.deprecated-style .mat-horizontal-stepper-content:not([class*=default]):not(.mat-horizontal-stepper-content-inactive),
.mat-stepper-vertical.deprecated-style .mat-horizontal-stepper-content:not([class*=default]):not(.mat-horizontal-stepper-content-inactive) {
  box-sizing: border-box;
  transition-duration: var(--ds-duration);
  transition-timing-function: var(--ds-timing-function);
  transition-property: padding-right;
  height: 100%;
}
.mat-stepper-horizontal.deprecated-style .mat-horizontal-stepper-content:not([class*=default]):not(.mat-horizontal-stepper-content-inactive) h2,
.mat-stepper-vertical.deprecated-style .mat-horizontal-stepper-content:not([class*=default]):not(.mat-horizontal-stepper-content-inactive) h2 {
  margin-top: calc(2 * var(--ds-spacing));
}
.mat-stepper-horizontal.deprecated-style .mat-horizontal-stepper-content:not([class*=default]):not(.mat-horizontal-stepper-content-inactive):not(:empty)::after,
.mat-stepper-vertical.deprecated-style .mat-horizontal-stepper-content:not([class*=default]):not(.mat-horizontal-stepper-content-inactive):not(:empty)::after {
  content: "";
  height: calc(2 * var(--ds-spacing));
  display: block;
  width: 100%;
}
.mat-stepper-horizontal.deprecated-style .mat-horizontal-stepper-content[aria-expanded=false],
.mat-stepper-vertical.deprecated-style .mat-horizontal-stepper-content[aria-expanded=false] {
  padding: 0;
}
.mat-stepper-horizontal.deprecated-style .mat-step-optional,
.mat-stepper-vertical.deprecated-style .mat-step-optional {
  display: none;
}
.mat-stepper-horizontal.deprecated-style .mat-step-header .mat-step-label:not([class*=default]),
.mat-stepper-vertical.deprecated-style .mat-step-header .mat-step-label:not([class*=default]) {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--ds-neutral-contrast-700);
  background: linear-gradient(75deg, var(--ds-neutral-400), var(--ds-neutral-300));
  overflow: visible;
  cursor: default;
}
@media print {
  .mat-stepper-horizontal.deprecated-style .mat-step-header .mat-step-label:not([class*=default]),
  .mat-stepper-vertical.deprecated-style .mat-step-header .mat-step-label:not([class*=default]) {
    background: linear-gradient(75deg, var(--ds-neutral-400), var(--ds-neutral-300));
    -webkit-print-color-adjust: exact;
  }
}
.mat-stepper-horizontal.deprecated-style .mat-step-header .mat-step-label:not([class*=default])::before,
.mat-stepper-vertical.deprecated-style .mat-step-header .mat-step-label:not([class*=default])::before {
  content: "";
  position: absolute;
  left: calc(-0.5 * var(--ds-spacing));
  width: 0;
  height: 0;
  border-top: calc(0.5 * 3 * var(--ds-spacing)) solid var(--ds-neutral-400);
  border-bottom: calc(0.5 * 3 * var(--ds-spacing)) solid var(--ds-neutral-400);
  border-left: calc(0.5 * var(--ds-spacing)) solid transparent;
}
.mat-stepper-horizontal.deprecated-style .mat-step-header .mat-step-label:not([class*=default]).mat-step-label-active,
.mat-stepper-vertical.deprecated-style .mat-step-header .mat-step-label:not([class*=default]).mat-step-label-active {
  cursor: pointer;
  font-weight: unset;
  background: linear-gradient(75deg, var(--ds-neutral-900), var(--ds-neutral-700));
}
@media print {
  .mat-stepper-horizontal.deprecated-style .mat-step-header .mat-step-label:not([class*=default]).mat-step-label-active,
  .mat-stepper-vertical.deprecated-style .mat-step-header .mat-step-label:not([class*=default]).mat-step-label-active {
    background: linear-gradient(75deg, var(--ds-neutral-900), var(--ds-neutral-700));
    -webkit-print-color-adjust: exact;
  }
}
.mat-stepper-horizontal.deprecated-style .mat-step-header .mat-step-label:not([class*=default]).mat-step-label-active::before,
.mat-stepper-vertical.deprecated-style .mat-step-header .mat-step-label:not([class*=default]).mat-step-label-active::before {
  border-top: calc(0.5 * 3 * var(--ds-spacing)) solid var(--ds-neutral-900);
  border-bottom: calc(0.5 * 3 * var(--ds-spacing)) solid var(--ds-neutral-900);
}
@media screen and (max-width: 959px) {
  .mat-stepper-horizontal.deprecated-style .mat-step-header .mat-step-label:not([class*=default]).mat-step-label-active,
  .mat-stepper-vertical.deprecated-style .mat-step-header .mat-step-label:not([class*=default]).mat-step-label-active {
    background: linear-gradient(180deg, var(--ds-neutral-900), var(--ds-neutral-700));
  }
  .mat-stepper-horizontal.deprecated-style .mat-step-header .mat-step-label:not([class*=default]).mat-step-label-active::before,
  .mat-stepper-vertical.deprecated-style .mat-step-header .mat-step-label:not([class*=default]).mat-step-label-active::before {
    display: none;
  }
}
.mat-stepper-horizontal.deprecated-style .mat-step-header .mat-step-label:not([class*=default]).mat-step-label-selected,
.mat-stepper-vertical.deprecated-style .mat-step-header .mat-step-label:not([class*=default]).mat-step-label-selected {
  cursor: pointer;
  font-weight: unset;
  background: linear-gradient(75deg, var(--ds-primary-900), var(--ds-primary-700));
  font-size: 0.875rem;
}
@media print {
  .mat-stepper-horizontal.deprecated-style .mat-step-header .mat-step-label:not([class*=default]).mat-step-label-selected,
  .mat-stepper-vertical.deprecated-style .mat-step-header .mat-step-label:not([class*=default]).mat-step-label-selected {
    background: linear-gradient(75deg, var(--ds-primary-900), var(--ds-primary-700));
    -webkit-print-color-adjust: exact;
  }
}
.mat-stepper-horizontal.deprecated-style .mat-step-header .mat-step-label:not([class*=default]).mat-step-label-selected::before,
.mat-stepper-vertical.deprecated-style .mat-step-header .mat-step-label:not([class*=default]).mat-step-label-selected::before {
  border-top: calc(0.5 * 3 * var(--ds-spacing)) solid var(--ds-primary-900);
  border-bottom: calc(0.5 * 3 * var(--ds-spacing)) solid var(--ds-primary-900);
}
@media screen and (max-width: 959px) {
  .mat-stepper-horizontal.deprecated-style .mat-step-header .mat-step-label:not([class*=default]).mat-step-label-selected,
  .mat-stepper-vertical.deprecated-style .mat-step-header .mat-step-label:not([class*=default]).mat-step-label-selected {
    background: linear-gradient(180deg, var(--ds-primary-900), var(--ds-primary-700));
  }
  .mat-stepper-horizontal.deprecated-style .mat-step-header .mat-step-label:not([class*=default]).mat-step-label-selected::before,
  .mat-stepper-vertical.deprecated-style .mat-step-header .mat-step-label:not([class*=default]).mat-step-label-selected::before {
    display: none;
  }
}
.mat-stepper-horizontal.deprecated-style .mat-horizontal-stepper-header:first-of-type .mat-step-label::before,
.mat-stepper-vertical.deprecated-style .mat-horizontal-stepper-header:first-of-type .mat-step-label::before {
  display: none;
}
.mat-stepper-horizontal.deprecated-style .mat-step-icon:not([class*=default]),
.mat-stepper-vertical.deprecated-style .mat-step-icon:not([class*=default]) {
  display: none;
}
.mat-stepper-horizontal.deprecated-style .mat-stepper-horizontal-line:not([class*=default]),
.mat-stepper-vertical.deprecated-style .mat-stepper-horizontal-line:not([class*=default]) {
  flex: 0;
  min-width: 0;
  margin: 0;
}

.mat-stepper-vertical:not([class*=default]),
.mat-stepper-horizontal:not([class*=default]) {
  background: var(--ds-neutral-10);
  height: 100%;
}
.mat-stepper-vertical:not([class*=default]) .mat-step-icon-state-done,
.mat-stepper-vertical:not([class*=default]) .mat-step-icon-state-edit,
.mat-stepper-horizontal:not([class*=default]) .mat-step-icon-state-done,
.mat-stepper-horizontal:not([class*=default]) .mat-step-icon-state-edit {
  background-color: var(--ds-success-500);
  color: var(--ds-white);
}
.mat-stepper-vertical:not([class*=default]) .mat-step-icon-selected,
.mat-stepper-horizontal:not([class*=default]) .mat-step-icon-selected {
  background-color: var(--ds-info-500);
  color: var(--ds-white);
}

.mat-stepper-vertical:not([class*=default]) .mat-step-header[ng-reflect-active=true]:not([ng-reflect-selected=true]) + .mat-vertical-content-container::before {
  border-left-color: var(--ds-success-500);
  border-width: 2px;
}

.mat-stepper-horizontal:not([class*=default]) .mat-horizontal-stepper-wrapper {
  height: 100%;
}
.mat-stepper-horizontal:not([class*=default]) .mat-horizontal-stepper-header-container:not([class*=default]) {
  flex: 0 0 auto;
}
.mat-stepper-horizontal:not([class*=default]) .mat-horizontal-content-container:not([class*=default]) {
  flex: 1 1 auto;
  min-height: 0;
  overflow: auto;
}
.mat-stepper-horizontal:not([class*=default]) div.mat-horizontal-stepper-content:not([class*=mat-horizontal-stepper-content-inactive]) {
  height: 100%;
}
.mat-stepper-horizontal:not([class*=default]) .mat-stepper-horizontal-line:has(~ .mat-step-header[aria-selected=true]) {
  border-top-color: var(--ds-success-500);
  border-width: 2px;
}

.mat-expansion-panel:not([class*=default]) {
  border-bottom: 1px solid var(--ds-neutral-50);
}
.mat-expansion-panel:not([class*=default]) .mat-expansion-panel-header {
  font-size: inherit;
  padding: calc((2.5 * var(--ds-spacing) - 48px) / 2) var(--ds-spacing);
}
.mat-expansion-panel:not([class*=default]) .mat-expansion-panel-header.mat-expanded {
  padding: calc((3.5 * var(--ds-spacing) - 64px) / 2) var(--ds-spacing);
}
.mat-expansion-panel:not([class*=default]) .mat-expansion-panel-header .mat-content {
  margin-right: var(--ds-spacing);
}
.mat-expansion-panel:not([class*=default]) .mat-expansion-panel-body {
  padding: 0 var(--ds-spacing) var(--ds-spacing);
}

.mat-expansion-panel:last-of-type,
.mat-expansion-panel.mat-expanded {
  border-bottom: 0;
}

mat-card .mat-accordion .mat-expansion-panel:not([class*=mat-elevation-z]) {
  box-shadow: none;
  border-bottom: 1px solid var(--ds-neutral-50);
}
mat-card .mat-accordion .mat-expansion-panel:first-of-type {
  border-top: 1px solid var(--ds-neutral-50);
}
mat-card .mat-accordion .mat-expansion-panel {
  border-radius: 0;
}
mat-card .mat-accordion .mat-expansion-panel:first-of-type {
  border-top-right-radius: 0;
  border-top-left-radius: 0;
}
mat-card .mat-accordion .mat-expansion-panel:last-of-type {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
mat-card .mat-accordion .mat-expansion-panel-spacing {
  margin: 0;
}

.page-content {
  display: flex;
  flex-direction: column;
  padding-top: var(--ds-spacing);
  padding-bottom: calc(2 * var(--ds-spacing));
  padding-left: calc(2 * var(--ds-spacing));
  padding-right: calc(2 * var(--ds-spacing));
  box-sizing: border-box;
  padding: calc(1 * var(--ds-spacing)) calc(1.5 * var(--ds-spacing)) calc(2 * var(--ds-spacing));
}

@media screen and (min-width: 600px) and (max-width: 959px) {
  .page-content {
    padding-left: calc(1.5 * var(--ds-spacing));
    padding-right: calc(1.5 * var(--ds-spacing));
  }
}
@media screen and (max-width: 599px) {
  .page-content {
    padding-left: var(--ds-spacing);
    padding-right: var(--ds-spacing);
  }
}
@media screen and (min-width: 600px) and (max-width: 959px) {
  .page-content {
    padding-right: calc(2 * var(--ds-spacing));
  }
  .page-content.no-sidebar {
    padding-left: calc(2 * var(--ds-spacing));
  }
}
@media print {
  .page-content {
    display: unset;
  }
}
:root .mat-mdc-snack-bar-container:not([class*=default]) {
  position: absolute;
  background: var(--ds-neutral-contrast-500);
  border-radius: var(--ds-border-radius);
  color: unset;
  max-width: calc(100% - 2.8 * var(--ds-spacing)) !important;
  margin: 0;
  bottom: var(--ds-spacing);
  left: var(--ds-spacing);
  right: auto;
  --mat-mdc-snack-bar-button-color: var(--ds-neutral-contrast-500);
}
:root .mat-mdc-snack-bar-container:not([class*=default]) ds-text-indicator {
  overflow: hidden;
}
:root .mat-mdc-snack-bar-container:not([class*=default]) ds-text-indicator span {
  display: inline-block;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
@media screen and (max-width: 599px) {
  :root .mat-mdc-snack-bar-container:not([class*=default]) {
    left: var(--ds-spacing);
    bottom: var(--ds-spacing);
    right: var(--ds-spacing);
  }
}
:root .mdc-snackbar__surface {
  padding-right: 0;
}
:root .mat-mdc-snack-bar-container.snackbar-with-component {
  padding: 0;
}

.mat-mdc-form-field {
  --mdc-theme-primary: var(--ds-neutral-300);
  line-height: 1.5 !important;
  color: var(--ds-neutral-300);
  font-weight: normal;
}
.mat-mdc-form-field mat-label,
.mat-mdc-form-field .mat-mdc-form-field-text-suffix {
  font-weight: normal;
}
.mat-mdc-form-field [class^=mdc-notched-outline] {
  border-width: 1px !important;
  border-color: var(--ds-neutral-300);
}
.mat-mdc-form-field .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__leading, .mat-mdc-form-field .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__notch, .mat-mdc-form-field .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__trailing {
  border-color: var(--ds-neutral-900);
  border-width: 1px !important;
}
.mat-mdc-form-field .mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-text-field__input {
  caret-color: var(--ds-neutral-900);
  color: var(--ds-neutral-900);
  font-size: calc(0.8 * var(--ds-spacing));
  font-weight: 400;
}
.mat-mdc-form-field.mat-focused .mat-mdc-select-trigger .mat-mdc-select-arrow, .mat-mdc-form-field.mat-focused:not(.mat-form-field-invalid) .mat-mdc-form-field-required-marker {
  color: var(--ds-neutral-900);
}

.mat-mdc-form-field:not(.mat-form-field-invalid) mat-label {
  color: var(--ds-neutral-300);
}

.mat-calendar-body-selected {
  color: var(--ds-neutral-contrast-700);
}

.mat-mdc-form-field-error:before {
  display: none !important;
}

mat-hint,
mat-error {
  letter-spacing: normal;
}

.mdc-menu-surface.mat-mdc-autocomplete-panel:empty {
  padding: 0;
}

.gm-style .gm-style-iw {
  border-radius: var(--ds-border-radius);
  box-shadow: 0px calc(0.5 * var(--ds-spacing)) var(--ds-spacing) calc(0.5 * var(--ds-spacing)) rgba(0, 0, 0, 0.07);
}

.map-container {
  width: 100% !important;
}

:root .mat-mdc-card-header h4 {
  margin: 0;
}
:root .mat-mdc-card-title:not([class*=default]) {
  font-family: PalfingerHandelVersalien;
  font-weight: 500;
  font-size: 1rem;
  line-height: 1;
}
:root .mat-mdc-card-subtitle:not([class*=default]) {
  font-size: inherit;
  color: var(--ds-neutral-700);
}
:root .mat-mdc-card-content,
:root .mat-mdc-card-image {
  padding-top: calc(0.5 * var(--ds-spacing));
  padding-bottom: calc(0.5 * var(--ds-spacing));
}

.page-content {
  display: flex;
  flex-direction: column;
  padding-top: var(--ds-spacing);
  padding-bottom: calc(2 * var(--ds-spacing));
  padding-left: calc(2 * var(--ds-spacing));
  padding-right: calc(2 * var(--ds-spacing));
  box-sizing: border-box;
  padding: calc(1 * var(--ds-spacing)) calc(1.5 * var(--ds-spacing)) calc(2 * var(--ds-spacing));
}

@media screen and (min-width: 600px) and (max-width: 959px) {
  .page-content {
    padding-left: calc(1.5 * var(--ds-spacing));
    padding-right: calc(1.5 * var(--ds-spacing));
  }
}
@media screen and (max-width: 599px) {
  .page-content {
    padding-left: var(--ds-spacing);
    padding-right: var(--ds-spacing);
  }
}
@media screen and (min-width: 600px) and (max-width: 959px) {
  .page-content {
    padding-right: calc(2 * var(--ds-spacing));
  }
  .page-content.no-sidebar {
    padding-left: calc(2 * var(--ds-spacing));
  }
}
@media print {
  .page-content {
    display: unset;
  }
}
.mat-mdc-paginator:not([class*=default]) {
  color: var(--ds-neutral-900);
  background: none;
}
.mat-mdc-paginator:not([class*=default]) [disabled],
.mat-mdc-paginator:not([class*=default]) button[disabled] {
  color: var(--ds-neutral-300);
}
.mat-mdc-paginator:not([class*=default]) .mat-mdc-select {
  line-height: 1;
}

@media screen and (max-width: 959px) {
  .mat-mdc-paginator:not([class*=default]):not(.no-mobile) .mat-mdc-paginator-range-label {
    order: 2;
    margin: 0 var(--ds-spacing);
  }
  .mat-mdc-paginator:not([class*=default]):not(.no-mobile) .mat-mdc-paginator-navigation-previous {
    order: 1;
    box-shadow: 0px calc(0.5 * 0.5 * var(--ds-spacing)) calc(0.5 * var(--ds-spacing)) calc(0.5 * 0.5 * var(--ds-spacing)) rgba(0, 0, 0, 0.05);
    width: 48px !important;
    height: 48px !important;
    line-height: 48px !important;
    padding: 0 !important;
    min-width: 0 !important;
    border-radius: 50% !important;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .mat-mdc-paginator:not([class*=default]):not(.no-mobile) .mat-mdc-paginator-navigation-next {
    order: 3;
    box-shadow: 0px calc(0.5 * 0.5 * var(--ds-spacing)) calc(0.5 * var(--ds-spacing)) calc(0.5 * 0.5 * var(--ds-spacing)) rgba(0, 0, 0, 0.05);
    width: 48px !important;
    height: 48px !important;
    line-height: 48px !important;
    padding: 0 !important;
    min-width: 0 !important;
    border-radius: 50% !important;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .mat-mdc-paginator:not([class*=default]):not(.no-mobile) .mat-mdc-paginator-page-size {
    display: none;
  }
  .mat-mdc-paginator:not([class*=default]):not(.no-mobile) .mat-mdc-paginator-navigation-first {
    display: none;
  }
  .mat-mdc-paginator:not([class*=default]):not(.no-mobile) .mat-mdc-paginator-navigation-last {
    display: none;
  }
  .mat-mdc-paginator:not([class*=default]):not(.no-mobile) .mat-mdc-paginator-container {
    display: flex;
    justify-content: center;
    padding-bottom: var(--ds-spacing);
  }
}

.mat-badge-content:not([class*=default]) {
  width: auto;
  padding: 0 calc(0.25 * var(--ds-spacing));
  font-weight: normal;
}

.mat-badge-medium .mat-badge-content:not([class*=default]) {
  height: var(--ds-spacing);
  line-height: var(--ds-spacing);
  border-radius: var(--ds-spacing);
  min-width: calc(0.5 * var(--ds-spacing));
}

.mat-badge-small .mat-badge-content:not([class*=default]) {
  height: calc(0.75 * var(--ds-spacing));
  line-height: calc(0.75 * var(--ds-spacing));
  border-radius: calc(0.75 * var(--ds-spacing));
  min-width: calc(0.75 * var(--ds-spacing));
  box-sizing: border-box;
}

.mat-mdc-input-element {
  caret-color: var(--ds-neutral-900);
}

.loading.mat-progress-bar {
  bottom: calc(0.2 * var(--ds-spacing));
  margin-bottom: calc(-0.2 * var(--ds-spacing));
}
.loading .mat-progress-bar-buffer {
  background-color: var(--ds-info-100);
}
.loading .mat-progress-bar-fill::after {
  background-color: var(--ds-info-600);
}

#freshworks-container #launcher-frame {
  z-index: 100 !important;
}

.mat-tooltip {
  color: var(--ds-neutral-10);
  background: linear-gradient(75deg, var(--ds-neutral-900), var(--ds-neutral-700));
}
@media print {
  .mat-tooltip {
    background: linear-gradient(75deg, var(--ds-neutral-900), var(--ds-neutral-700));
    -webkit-print-color-adjust: exact;
  }
}

.mat-mdc-slide-toggle.mat-checked:not([class*=default]) .mat-mdc-slide-toggle-thumb {
  background-color: var(--ds-neutral-700);
}

.mat-mdc-slide-toggle.mat-checked:not([class*=default]) .mat-mdc-slide-toggle-bar {
  background-color: var(--ds-neutral-300);
}

.mat-mdc-slide-toggle-bar:not([class*=default]) {
  background-color: var(--ds-neutral-100);
}

.mat-mdc-slide-toggle-thumb:not([class*=default]) {
  background-color: var(--ds-neutral-50);
}

:root .mat-mdc-slider-ticks:not([class*=default]) {
  background-color: var(--ds-neutral-200);
}
:root mat-slider {
  --mdc-slider-label-container-color: var(--ds-neutral-700);
  --mat-mdc-slider-value-indicator-opacity: 0.9;
}
:root .mat-mdc-slider .mdc-slider__value-indicator-container {
  --mat-slider-value-indicator-opacity: 1;
}

.mat-mdc-slide-toggle .mdc-form-field {
  color: var(--mdc-theme-text-primary-on-background, var(--ds-neutral-900));
}

.mat-icon:not([class*=default]) {
  overflow: unset;
}

.mat-mdc-radio-button:not([class*=default]) .mat-radio-label-content {
  padding-left: 12px;
}

.mat-mdc-radio-button .mdc-form-field {
  color: var(--mdc-theme-text-primary-on-background, var(--ds-neutral-900));
}

.mat-button-toggle:not([class*=default]) {
  background-color: var(--ds-neutral-50);
}

.mat-button-toggle-checked:not([class*=default]) {
  background-color: var(--ds-neutral-200);
  color: var(--ds-neutral-900);
}

.mat-mdc-checkbox:not([class*=default]) .mat-mdc-checkbox-label {
  padding-left: calc(0.25 * var(--ds-spacing));
}

.mat-mdc-checkbox .mdc-form-field {
  color: var(--mdc-theme-text-primary-on-background, var(--ds-neutral-900));
}

mat-nested-tree-node .mat-tree-node .mdc-button.mdc-primary,
mat-nested-tree-node .mat-tree-node .mdc-icon-button.mdc-primary,
mat-nested-tree-node .mat-tree-node .mdc-stroked-button.mdc-primary .mdc-stroked-button.mdc-focus-overlay {
  color: var(--ds-neutral-900);
  background-color: var(--ds-neutral-50);
}
mat-nested-tree-node .mat-tree-node:hover .mdc-button.mdc-primary:hover,
mat-nested-tree-node .mat-tree-node:hover .mdc-icon-button.mdc-primary:hover,
mat-nested-tree-node .mat-tree-node:hover .mdc-stroked-button.mdc-primary:hover {
  background-color: var(--ds-neutral-100);
}

.tree-container .mdc-button {
  color: var(--ds-neutral-900);
}

.mdc-stroked-button .mdc-focus-overlay,
.mdc-button.mdc-primary .mdc-button-focus-overlay {
  display: none;
}

mat-tree .mdc-icon-button {
  width: calc(1.5 * var(--ds-spacing)) !important;
  height: calc(1.5 * var(--ds-spacing)) !important;
  padding: 0 !important;
  line-height: 1.5 !important;
}

.mat-mdc-tab-group .mat-mdc-tab .mdc-tab__content {
  pointer-events: all;
  letter-spacing: normal;
}

.mat-mdc-tab-header {
  background-color: var(--ds-info-A100);
  border-bottom: 2px solid var(--ds-neutral-50);
}

.mat-mdc-tab .mdc-tab__content .mdc-tab__text-label {
  color: var(--ds-neutral-900);
}

.page-content {
  display: flex;
  flex-direction: column;
  padding-top: var(--ds-spacing);
  padding-bottom: calc(2 * var(--ds-spacing));
  padding-left: calc(2 * var(--ds-spacing));
  padding-right: calc(2 * var(--ds-spacing));
  box-sizing: border-box;
  padding: calc(1 * var(--ds-spacing)) calc(1.5 * var(--ds-spacing)) calc(2 * var(--ds-spacing));
}

@media screen and (min-width: 600px) and (max-width: 959px) {
  .page-content {
    padding-left: calc(1.5 * var(--ds-spacing));
    padding-right: calc(1.5 * var(--ds-spacing));
  }
}
@media screen and (max-width: 599px) {
  .page-content {
    padding-left: var(--ds-spacing);
    padding-right: var(--ds-spacing);
  }
}
@media screen and (min-width: 600px) and (max-width: 959px) {
  .page-content {
    padding-right: calc(2 * var(--ds-spacing));
  }
  .page-content.no-sidebar {
    padding-left: calc(2 * var(--ds-spacing));
  }
}
@media print {
  .page-content {
    display: unset;
  }
}
h1:not([class*=default]),
.h1 {
  margin-top: calc(2 * var(--ds-spacing));
  margin-bottom: var(--ds-spacing);
}
@media screen and (max-width: 599px) {
  h1:not([class*=default]),
  .h1 {
    margin-top: calc(0.5 * var(--ds-spacing));
    margin-bottom: calc(0.5 * var(--ds-spacing));
  }
}

h2:not([class*=default]),
.h2 {
  margin-top: calc(2 * var(--ds-spacing));
  margin-bottom: var(--ds-spacing);
}

h3:not([class*=default]),
.h3 {
  margin-top: calc(1.5 * var(--ds-spacing));
  margin-bottom: calc(0.5 * var(--ds-spacing));
}

h4:not([class*=default]),
.h4 {
  margin-top: calc(1.5 * var(--ds-spacing));
  margin-bottom: calc(0.25 * var(--ds-spacing));
}

h5:not([class*=default]),
.h5 {
  margin-top: var(--ds-spacing);
  margin-bottom: 0;
}

h6:not([class*=default]),
.h6 {
  margin-top: calc(0.5 * var(--ds-spacing));
  margin-bottom: calc(0.5 * var(--ds-spacing));
}

@media print {
  body {
    height: auto;
  }
  .pd-table td,
  .mat-mdc-table td {
    color: var(--ds-neutral-900) !important;
  }
  .pd-table .mat-mdc-form-field:not([class*=default]),
  .mat-mdc-table .mat-mdc-form-field:not([class*=default]) {
    width: 100%;
  }
  .pd-table .mat-mdc-form-field:not([class*=default]) .mat-mdc-form-field-infix,
  .mat-mdc-table .mat-mdc-form-field:not([class*=default]) .mat-mdc-form-field-infix {
    width: 0;
  }
  ds-breadcrumbs {
    display: none;
  }
  .mat-form-field-appearance-outline:not([class*=default]) {
    /* remove borders to make inputs look like text */
    /* no border, no padding needed here*/
    /* make all labels float to give a continously look */
    /* default text color on input - even if disabled = readonly */
    /* hide interactive elements */
  }
  .mat-form-field-appearance-outline:not([class*=default]) .mdc-text-field--outlined .mdc-notched-outline__leading,
  .mat-form-field-appearance-outline:not([class*=default]) .mdc-text-field--outlined .mdc-notched-outline__notch,
  .mat-form-field-appearance-outline:not([class*=default]) .mdc-text-field--outlined .mdc-notched-outline__trailing {
    border: none;
  }
  .mat-form-field-appearance-outline:not([class*=default]) .mat-mdc-form-field-flex {
    padding: 0;
  }
  .mat-form-field-appearance-outline:not([class*=default]) .mat-mdc-form-field-label {
    transform: translateY(-1.34375em) !important;
  }
  .mat-form-field-appearance-outline:not([class*=default]).mat-mdc-form-field-disabled .mat-mdc-form-field-outline {
    color: var(--ds-neutral-900);
  }
  .mat-form-field-appearance-outline:not([class*=default]) .mat-input-element:disabled,
  .mat-form-field-appearance-outline:not([class*=default]) .mat-mdc-form-field-type-mat-native-select.mat-mdc-form-field-disabled .mat-mdc-form-field-infix::after {
    color: var(--ds-neutral-900);
  }
  .mat-form-field-appearance-outline:not([class*=default]) mat-datepicker-toggle,
  .mat-form-field-appearance-outline:not([class*=default]) .mat-mdc-select-arrow-wrapper,
  .mat-form-field-appearance-outline:not([class*=default]) .mat-mdc-form-field-required-marker {
    display: none;
  }
  .mat-mdc-card {
    box-shadow: none !important;
    border: 1px solid var(--ds-neutral-900) !important;
  }
  .mat-mdc-snack-bar-container {
    display: none !important;
  }
  ::-webkit-scrollbar {
    display: none;
  }
}
@media screen and (max-width: 959px) {
  .mat-mdc-button-touch-target {
    padding: 8px;
  }
  .mat-mdc-radio-touch-target {
    padding: 12px;
  }
  .mat-mdc-checkbox-touch-target {
    padding: 12px;
  }
}
.large-touch-target {
  padding: 12px;
  height: 48px;
  width: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.standard-touch-target {
  padding: 12px;
  height: 24px;
  width: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.page-content {
  display: flex;
  flex-direction: column;
  padding-top: var(--ds-spacing);
  padding-bottom: calc(2 * var(--ds-spacing));
  padding-left: calc(2 * var(--ds-spacing));
  padding-right: calc(2 * var(--ds-spacing));
  box-sizing: border-box;
  padding: calc(1 * var(--ds-spacing)) calc(1.5 * var(--ds-spacing)) calc(2 * var(--ds-spacing));
}

@media screen and (min-width: 600px) and (max-width: 959px) {
  .page-content {
    padding-left: calc(1.5 * var(--ds-spacing));
    padding-right: calc(1.5 * var(--ds-spacing));
  }
}
@media screen and (max-width: 599px) {
  .page-content {
    padding-left: var(--ds-spacing);
    padding-right: var(--ds-spacing);
  }
}
@media screen and (min-width: 600px) and (max-width: 959px) {
  .page-content {
    padding-right: calc(2 * var(--ds-spacing));
  }
  .page-content.no-sidebar {
    padding-left: calc(2 * var(--ds-spacing));
  }
}
@media print {
  .page-content {
    display: unset;
  }
}
.info,
table.mat-mdc-table .info {
  background: linear-gradient(180deg, var(--ds-info-400), var(--ds-info-500));
  color: var(--ds-info-contrast-500);
}
@media print {
  .info,
  table.mat-mdc-table .info {
    background: linear-gradient(180deg, var(--ds-info-400), var(--ds-info-500));
    -webkit-print-color-adjust: exact;
  }
}

.info-text,
table.mat-mdc-table .info-text {
  color: var(--ds-info-500) !important;
}

.success,
table.mat-mdc-table .success {
  background: linear-gradient(180deg, var(--ds-success-600), var(--ds-success-700));
  color: var(--ds-success-contrast-500);
}
@media print {
  .success,
  table.mat-mdc-table .success {
    background: linear-gradient(180deg, var(--ds-success-600), var(--ds-success-700));
    -webkit-print-color-adjust: exact;
  }
}

.success-text,
table.mat-mdc-table .success-text {
  color: var(--ds-success-500) !important;
}

.primary-text,
table.mat-mdc-table .primary-text {
  color: var(--ds-primary-600) !important;
}

.warning,
table.mat-mdc-table .warning {
  background: linear-gradient(180deg, var(--ds-warning-400), var(--ds-warning-500));
  color: var(--ds-warning-contrast-500);
}
@media print {
  .warning,
  table.mat-mdc-table .warning {
    background: linear-gradient(180deg, var(--ds-warning-400), var(--ds-warning-500));
    -webkit-print-color-adjust: exact;
  }
}

.warning-text,
table.mat-mdc-table .warning-text {
  color: var(--ds-warning-500) !important;
}

.error,
table.mat-mdc-table .error {
  background: linear-gradient(180deg, var(--ds-error-600), var(--ds-error-500));
  color: var(--ds-error-contrast-500);
}
@media print {
  .error,
  table.mat-mdc-table .error {
    background: linear-gradient(180deg, var(--ds-error-600), var(--ds-error-500));
    -webkit-print-color-adjust: exact;
  }
}

.error-text,
table.mat-mdc-table .error-text {
  color: var(--ds-error-500) !important;
}

table.mat-mdc-table .error-text,
table.mat-mdc-table .warning-text,
table.mat-mdc-table .success-text,
table.mat-mdc-table .info-text {
  font-weight: 500;
}

.neutral,
table.mat-mdc-table .neutral {
  background: linear-gradient(180deg, var(--ds-neutral-400), var(--ds-neutral-500));
  color: var(--ds-neutral-contrast-500);
}
@media print {
  .neutral,
  table.mat-mdc-table .neutral {
    background: linear-gradient(180deg, var(--ds-neutral-400), var(--ds-neutral-500));
    -webkit-print-color-adjust: exact;
  }
}

mat-icon[mini-icon] {
  font-size: 18px;
  width: 18px;
  height: 18px;
}

.page-content {
  display: flex;
  flex-direction: column;
  padding-top: var(--ds-spacing);
  padding-bottom: calc(2 * var(--ds-spacing));
  padding-left: calc(2 * var(--ds-spacing));
  padding-right: calc(2 * var(--ds-spacing));
  box-sizing: border-box;
  padding: calc(1 * var(--ds-spacing)) calc(1.5 * var(--ds-spacing)) calc(2 * var(--ds-spacing));
}

@media screen and (min-width: 600px) and (max-width: 959px) {
  .page-content {
    padding-left: calc(1.5 * var(--ds-spacing));
    padding-right: calc(1.5 * var(--ds-spacing));
  }
}
@media screen and (max-width: 599px) {
  .page-content {
    padding-left: var(--ds-spacing);
    padding-right: var(--ds-spacing);
  }
}
@media screen and (min-width: 600px) and (max-width: 959px) {
  .page-content {
    padding-right: calc(2 * var(--ds-spacing));
  }
  .page-content.no-sidebar {
    padding-left: calc(2 * var(--ds-spacing));
  }
}
@media print {
  .page-content {
    display: unset;
  }
}
.ribbon {
  position: relative;
  overflow: hidden;
}
.ribbon:after {
  content: " ";
  position: absolute;
  width: 4px !important;
  left: 0;
  top: 0;
  bottom: 0;
  background: linear-gradient(180deg, var(--ds-primary-400), var(--ds-primary-500));
}
@media print {
  .ribbon:after {
    background: linear-gradient(180deg, var(--ds-primary-400), var(--ds-primary-500));
    -webkit-print-color-adjust: exact;
  }
}

.neutral-ribbon {
  position: relative;
  overflow: hidden;
}
.neutral-ribbon:after {
  content: " ";
  position: absolute;
  width: 4px !important;
  left: 0;
  top: 0;
  bottom: 0;
  background: linear-gradient(180deg, var(--ds-neutral-400), var(--ds-neutral-500));
}
@media print {
  .neutral-ribbon:after {
    background: linear-gradient(180deg, var(--ds-neutral-400), var(--ds-neutral-500));
    -webkit-print-color-adjust: exact;
  }
}

.info-ribbon {
  position: relative;
  overflow: hidden;
}
.info-ribbon:after {
  content: " ";
  position: absolute;
  width: 4px !important;
  left: 0;
  top: 0;
  bottom: 0;
  background: linear-gradient(180deg, var(--ds-info-300), var(--ds-info-400));
}
@media print {
  .info-ribbon:after {
    background: linear-gradient(180deg, var(--ds-info-300), var(--ds-info-400));
    -webkit-print-color-adjust: exact;
  }
}

.success-ribbon {
  position: relative;
  overflow: hidden;
}
.success-ribbon:after {
  content: " ";
  position: absolute;
  width: 4px !important;
  left: 0;
  top: 0;
  bottom: 0;
  background: linear-gradient(180deg, var(--ds-success-400), var(--ds-success-500));
}
@media print {
  .success-ribbon:after {
    background: linear-gradient(180deg, var(--ds-success-400), var(--ds-success-500));
    -webkit-print-color-adjust: exact;
  }
}

.warning-ribbon {
  position: relative;
  overflow: hidden;
}
.warning-ribbon:after {
  content: " ";
  position: absolute;
  width: 4px !important;
  left: 0;
  top: 0;
  bottom: 0;
  background: linear-gradient(180deg, var(--ds-warning-200), var(--ds-warning-300));
}
@media print {
  .warning-ribbon:after {
    background: linear-gradient(180deg, var(--ds-warning-200), var(--ds-warning-300));
    -webkit-print-color-adjust: exact;
  }
}

.error-ribbon {
  position: relative;
  overflow: hidden;
}
.error-ribbon:after {
  content: " ";
  position: absolute;
  width: 4px !important;
  left: 0;
  top: 0;
  bottom: 0;
  background: linear-gradient(180deg, var(--ds-error-400), var(--ds-error-500));
}
@media print {
  .error-ribbon:after {
    background: linear-gradient(180deg, var(--ds-error-400), var(--ds-error-500));
    -webkit-print-color-adjust: exact;
  }
}

.pd-loading {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
}
.pd-loading svg {
  transform: rotate(-90deg);
  transform-origin: center;
  overflow: visible;
  animation: mat-progress-spinner-linear-rotate 2s linear infinite;
  width: 100px;
  height: 100px;
}
.pd-loading svg circle {
  fill: rgba(0, 0, 0, 0);
  transition: stroke-dashoffset 225ms linear;
  transition-property: stroke;
  stroke: #5d6a72;
  stroke-dasharray: 282.743px;
  stroke-width: 10%;
  transform-origin: 50% 50%;
  animation-name: mat-progress-spinner-stroke-rotate-100;
  animation-duration: 4s;
  animation-timing-function: cubic-bezier(0.35, 0, 0.25, 1);
  animation-iteration-count: infinite;
}

@keyframes mat-progress-spinner-linear-rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@keyframes mat-progress-spinner-stroke-rotate-100 {
  0% {
    stroke-dashoffset: 268.47px;
    transform: rotate(0);
  }
  12.5% {
    stroke-dashoffset: 56.52px;
    transform: rotate(0);
  }
  12.5001% {
    stroke-dashoffset: 56.52px;
    transform: rotateX(180deg) rotate(72.5deg);
  }
  25% {
    stroke-dashoffset: 268.47px;
    transform: rotateX(180deg) rotate(72.5deg);
  }
  25.0001% {
    stroke-dashoffset: 268.47px;
    transform: rotate(270deg);
  }
  37.5% {
    stroke-dashoffset: 56.52px;
    transform: rotate(270deg);
  }
  37.5001% {
    stroke-dashoffset: 56.52px;
    transform: rotateX(180deg) rotate(161.5deg);
  }
  50% {
    stroke-dashoffset: 268.47px;
    transform: rotateX(180deg) rotate(161.5deg);
  }
  50.0001% {
    stroke-dashoffset: 268.47px;
    transform: rotate(180deg);
  }
  62.5% {
    stroke-dashoffset: 56.52px;
    transform: rotate(180deg);
  }
  62.5001% {
    stroke-dashoffset: 56.52px;
    transform: rotateX(180deg) rotate(251.5deg);
  }
  75% {
    stroke-dashoffset: 268.47px;
    transform: rotateX(180deg) rotate(251.5deg);
  }
  75.0001% {
    stroke-dashoffset: 268.47px;
    transform: rotate(90deg);
  }
  87.5% {
    stroke-dashoffset: 56.52px;
    transform: rotate(90deg);
  }
  87.5001% {
    stroke-dashoffset: 56.52px;
    transform: rotateX(180deg) rotate(341.5deg);
  }
  100% {
    stroke-dashoffset: 268.47px;
    transform: rotateX(180deg) rotate(341.5deg);
  }
}
.full-width {
  width: 100%;
}

.full-height {
  height: 100%;
}

.full-max-height {
  max-height: 100%;
}

.overflow-auto {
  overflow: auto;
}

.overflow-hidden {
  overflow: hidden;
}

.relative-position {
  position: relative;
}

.scroll-container-vertical {
  overflow-y: auto;
  overflow-x: hidden;
}

.scroll-container-horizontal {
  overflow-y: hidden;
  overflow-x: auto;
}

.hidden-scollbar {
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* IE 10+ */
}
.hidden-scollbar::-webkit-scrollbar {
  display: none; /* Chrome Safari */
}

.flex-page-container {
  min-height: 0;
}
.flex-page-container .table-wrapper {
  overflow: hidden;
}
.flex-page-container::after {
  content: "";
  height: calc(2 * var(--ds-spacing));
  display: block;
  width: 100%;
}

ds-full-page-body {
  min-height: 0;
  flex: 1 1 auto;
}
@media screen and (max-height: 400px) {
  div[role=listbox] {
    max-height: 50vh !important;
  }
}
.no-margin {
  margin: 0 !important;
}

.no-margin-top {
  margin-top: 0 !important;
}

.no-margin-bottom {
  margin-bottom: 0 !important;
}

.padding {
  padding: var(--ds-spacing);
}

.padding-top {
  padding-top: var(--ds-spacing);
}

.no-padding {
  padding: 0 !important;
}

.no-padding-bottom {
  padding-bottom: 0 !important;
}

.mini-padding {
  padding: calc(0.5 * var(--ds-spacing));
}

.no-min-height {
  min-height: 0;
}

.no-min-width {
  min-width: 0;
}

.normal-white-space {
  white-space: normal !important;
}

.uppercase {
  text-transform: uppercase;
}

.lowercase {
  text-transform: uppercase;
}

.center-text {
  text-align: center;
}

.left-text {
  text-align: left;
}

.right-text {
  text-align: right;
}

.cut-text {
  text-overflow: ellipsis;
  overflow: hidden !important;
  white-space: nowrap;
}

.nowrap-text {
  white-space: nowrap;
}

.inherit-color {
  color: inherit;
}

div[pair] {
  width: 100%;
  display: flex;
  flex-direction: column;
}
div[pair] *[title] {
  color: var(--ds-neutral-700);
  font-weight: 500;
}
div[pair] *:last-child {
  font-weight: normal;
}

mat-optgroup mat-option,
mat-optgroup .mat-optgroup-label {
  font-size: inherit !important;
  font-family: "Roboto" !important;
}

table {
  width: 100%;
}
table .mat-table-sticky,
table .mat-mdc-table-sticky {
  background: var(--ds-info-A100);
}
table tbody td.mat-mdc-cell.mat-mdc-table-sticky,
table tbody td.mat-cell.mat-table-sticky {
  width: 1%;
  box-shadow: var(--ds-spacing) calc(0.5 * var(--ds-spacing)) var(--ds-spacing) var(--ds-spacing)/10 rgba(0, 0, 0, 0.03);
}
table tbody td.mat-mdc-cell.mat-mdc-table-sticky:last-child,
table tbody td.mat-cell.mat-table-sticky:last-child {
  padding-right: calc(0.5 * var(--ds-spacing));
  box-shadow: 0px calc(0.5 * var(--ds-spacing)) var(--ds-spacing) calc(0.5 * var(--ds-spacing)) rgba(0, 0, 0, 0.03);
}
table thead th.mat-mdc-header-cell.mat-mdc-table-sticky:last-child,
table thead th.mat-header-cell.mat-table-sticky:last-child {
  padding-right: calc(0.5 * var(--ds-spacing));
}
table thead th.mat-mdc-header-cell.mat-mdc-table-sticky:last-child:not([class*=text-]),
table thead th.mat-header-cell.mat-table-sticky:last-child:not([class*=text-]) {
  text-align: center;
}
table td.mat-action-cell,
table td.mat-mdc-cell.mat-action-cell:not([class*=default]),
table mat-form-field {
  color: var(--ds-neutral-900);
}
table td.mat-action-cell,
table td.mat-mdc-cell.mat-action-cell:not([class*=default]) {
  width: 0.0001%;
  padding-right: calc(0.5 * var(--ds-spacing));
}
table th mat-hint {
  font-size: 75%;
  margin-bottom: -1em;
  margin-top: -0.75em;
  display: block;
  line-height: 1;
}
table th.mat-mdc-header-cell.text-right {
  text-align: right;
}
table th.mat-mdc-header-cell.text-right .mat-sort-header-content {
  text-align: right;
}
table th.mat-mdc-header-cell.text-center {
  text-align: center;
}
table th.mat-mdc-header-cell.text-center .mat-sort-header-content {
  text-align: center;
}
table[list] > span {
  font-weight: 500;
  display: table-caption;
  width: 100%;
  margin-bottom: calc(0.5 * var(--ds-spacing));
}
table[list] td,
table[list] th {
  vertical-align: top;
  padding-bottom: calc(0.5 * var(--ds-spacing));
}
table[list] td:first-child,
table[list] th:first-child {
  padding-right: calc(0.5 * var(--ds-spacing));
}
table[list] tr > td:nth-child(odd) {
  font-weight: 500;
  color: var(--ds-neutral-700);
  white-space: nowrap;
  padding-right: calc(0.5 * var(--ds-spacing));
}
table .min-width-cell {
  width: 1%;
}

.mat-sort-header-content {
  display: block !important;
}

.text-right .mat-sort-header-content {
  text-align: right;
}

.table-column-group-header {
  text-align: center !important;
}
.table-column-group-header:not(:first-child) {
  border-left-width: 1px !important;
}

.ds-table-group-border {
  border-left-width: 1px !important;
}

.pd.mat-icon {
  color: var(--ds-neutral-900);
}

.mdc-button {
  white-space: nowrap;
  text-transform: none;
  border-radius: var(--ds-border-radius) !important;
  text-align: center;
}
.mdc-button.mat-mdc-button, .mdc-button[class*=raised], .mdc-button[class*=unelevated], .mdc-button[class*=outlined] {
  letter-spacing: normal;
}
.mdc-button .mat-icon {
  line-height: 1 !important;
}
.mdc-button.mat-mdc-snack-bar-action {
  color: var(--ds-white) !important;
}
.mdc-button mat-progress-spinner {
  display: inline-block;
}
.mdc-button[class*=raised]:not(:disabled), .mdc-button[class*=unelevated]:not(:disabled) {
  color: var(--ds-neutral-900);
}
.mdc-button[class*=raised].mat-accent, .mdc-button[class*=raised].mat-primary, .mdc-button[class*=unelevated].mat-accent, .mdc-button[class*=unelevated].mat-primary {
  background: linear-gradient(75deg, var(--ds-neutral-900), var(--ds-neutral-700));
  color: var(--ds-neutral-contrast-500);
}
@media print {
  .mdc-button[class*=raised].mat-accent, .mdc-button[class*=raised].mat-primary, .mdc-button[class*=unelevated].mat-accent, .mdc-button[class*=unelevated].mat-primary {
    background: linear-gradient(75deg, var(--ds-neutral-900), var(--ds-neutral-700));
    -webkit-print-color-adjust: exact;
  }
}
.mdc-button[class*=raised].mat-accent mat-progress-spinner circle, .mdc-button[class*=raised].mat-primary mat-progress-spinner circle, .mdc-button[class*=unelevated].mat-accent mat-progress-spinner circle, .mdc-button[class*=unelevated].mat-primary mat-progress-spinner circle {
  stroke: var(--ds-neutral-contrast-500);
}
.mdc-button[class*=raised].mat-accent[disabled], .mdc-button[class*=raised].mat-primary[disabled], .mdc-button[class*=unelevated].mat-accent[disabled], .mdc-button[class*=unelevated].mat-primary[disabled] {
  color: var(--ds-neutral-contrast-500);
  opacity: 0.4;
}
.mdc-button[class*=outlined]:not(:disabled) {
  border-color: var(--ds-neutral-900) !important;
}
.mdc-button[class*=outlined]:not(:disabled).mat-accent, .mdc-button[class*=outlined]:not(:disabled).mat-primary, .mdc-button[class*=outlined]:not(:disabled).mat-warn, .mdc-button[class*=outlined]:not(:disabled).mat-unthemed {
  color: var(--ds-neutral-900);
}
.mdc-button.mat-mdc-button:not(:disabled) {
  color: var(--ds-neutral-900);
}
.mdc-button.mat-mdc-button:not(:disabled).mat-accent, .mdc-button.mat-mdc-button:not(:disabled).mat-primary, .mdc-button.mat-mdc-button:not(:disabled).mat-warn {
  color: var(--mdc-text-button-label-text-color, inherit) !important;
}
.mdc-button[class*=raised].mat-warn, .mdc-button[class*=unelevated].mat-warn {
  background: linear-gradient(75deg, var(--ds-warning-900), var(--ds-warning-500));
  color: var(--ds-neutral-contrast-500);
}
@media print {
  .mdc-button[class*=raised].mat-warn, .mdc-button[class*=unelevated].mat-warn {
    background: linear-gradient(75deg, var(--ds-warning-900), var(--ds-warning-500));
    -webkit-print-color-adjust: exact;
  }
}
.mdc-button[class*=raised].mat-warn mat-progress-spinner circle, .mdc-button[class*=unelevated].mat-warn mat-progress-spinner circle {
  stroke: var(--ds-neutral-contrast-500);
}
.mdc-button[class*=raised].mat-warn[disabled], .mdc-button[class*=unelevated].mat-warn[disabled] {
  color: var(--ds-warning-contrast-500);
  opacity: 0.4;
}
.mdc-button[back-button] {
  margin-top: var(--ds-spacing);
  margin-bottom: var(--ds-spacing);
}

.mdc-button .pending-spinner {
  margin-right: calc(0.5 * var(--ds-spacing));
}

.mat-mdc-calendar-body-selected {
  color: var(--ds-neutral-10);
}

html .mat-mdc-mini-fab.mat-warn,
html .mat-mdc-fab.mat-warn {
  --mat-fab-foreground-color: var(--ds-neutral-10);
}

.page-content {
  display: flex;
  flex-direction: column;
  padding-top: var(--ds-spacing);
  padding-bottom: calc(2 * var(--ds-spacing));
  padding-left: calc(2 * var(--ds-spacing));
  padding-right: calc(2 * var(--ds-spacing));
  box-sizing: border-box;
  padding: calc(1 * var(--ds-spacing)) calc(1.5 * var(--ds-spacing)) calc(2 * var(--ds-spacing));
}

@media screen and (min-width: 600px) and (max-width: 959px) {
  .page-content {
    padding-left: calc(1.5 * var(--ds-spacing));
    padding-right: calc(1.5 * var(--ds-spacing));
  }
}
@media screen and (max-width: 599px) {
  .page-content {
    padding-left: var(--ds-spacing);
    padding-right: var(--ds-spacing);
  }
}
@media screen and (min-width: 600px) and (max-width: 959px) {
  .page-content {
    padding-right: calc(2 * var(--ds-spacing));
  }
  .page-content.no-sidebar {
    padding-left: calc(2 * var(--ds-spacing));
  }
}
@media print {
  .page-content {
    display: unset;
  }
}
.stepper-with-sidebar-content {
  padding-right: calc(18 * var(--ds-spacing));
}
@media screen and (max-width: 959px) {
  .stepper-with-sidebar-content {
    padding-right: unset;
  }
}

ds-deprecated-responsive-stepper .mat-stepper-horizontal {
  height: auto;
}
ds-deprecated-responsive-stepper .mat-stepper-horizontal .mat-horizontal-stepper-content {
  padding: 0;
}

@media screen and (max-width: 959px) {
  .deprecated-style .mat-horizontal-stepper-header-container:not([class*=default]) {
    align-items: stretch;
    flex-direction: column;
    display: block;
  }
  .deprecated-style .mat-stepper-horizontal-line {
    display: none;
  }
}

.mat-expansion-panel:not([class*=default]) {
  color: var(--ds-neutral-900);
}
.mat-expansion-panel:not([class*=default]).no-collapsed-background:not([class*=mat-expanded]) {
  background: none;
  box-shadow: none;
}
.mat-expansion-panel:not([class*=default]).no-background {
  background: none;
  box-shadow: none;
}
.mat-expansion-panel:not([class*=default]).no-padding .mat-expansion-panel-body {
  padding: 0;
}

.print-only {
  display: none !important;
}

@media print {
  .print-only {
    display: initial !important;
  }
  .print-hidden {
    display: none !important;
  }
}
.spotlight {
  border: 2px solid var(--ds-info-500);
  animation: pulse 2s infinite;
  animation-timing-function: cubic-bezier(0.35, 0, 0.25, 1);
}

@keyframes pulse {
  0% {
    box-shadow: 0 0 0 0 var(--ds-info-500);
  }
  100% {
    box-shadow: 0 0 0 calc(0.5 * var(--ds-spacing)) var(--ds-info-500)00;
  }
}
.mat-badge-content {
  color: var(--ds-info-A100);
}

.mat-badge-info .mat-badge-content:not([class*=default]) {
  background: var(--ds-info-500);
}

.mat-badge-success .mat-badge-content:not([class*=default]) {
  background: var(--ds-success-500);
}

.cursor-pointer {
  cursor: pointer;
}

html {
  --mdc-slider-active-track-height: 6px;
  --mdc-slider-active-track-shape: 9999px;
  --mdc-slider-handle-height: 20px;
  --mdc-slider-handle-shape: 50%;
  --mdc-slider-handle-width: 20px;
  --mdc-slider-inactive-track-height: 4px;
  --mdc-slider-inactive-track-shape: 9999px;
  --mdc-slider-with-overlap-handle-outline-width: 1px;
  --mdc-slider-with-tick-marks-active-container-opacity: 0.6;
  --mdc-slider-with-tick-marks-container-shape: 50%;
  --mdc-slider-with-tick-marks-container-size: 2px;
  --mdc-slider-with-tick-marks-inactive-container-opacity: 0.6;
  --mdc-slider-handle-elevation: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}
html {
  --mat-slider-value-indicator-width: auto;
  --mat-slider-value-indicator-height: 32px;
  --mat-slider-value-indicator-caret-display: block;
  --mat-slider-value-indicator-border-radius: 4px;
  --mat-slider-value-indicator-padding: 0 12px;
  --mat-slider-value-indicator-text-transform: none;
  --mat-slider-value-indicator-container-transform: translateX(-50%);
}

html {
  --mdc-slider-handle-color: #c91019;
  --mdc-slider-focus-handle-color: #c91019;
  --mdc-slider-hover-handle-color: #c91019;
  --mdc-slider-active-track-color: #c91019;
  --mdc-slider-inactive-track-color: #c91019;
  --mdc-slider-with-tick-marks-inactive-container-color: #c91019;
  --mdc-slider-with-tick-marks-active-container-color: white;
  --mdc-slider-disabled-active-track-color: #000;
  --mdc-slider-disabled-handle-color: #000;
  --mdc-slider-disabled-inactive-track-color: #000;
  --mdc-slider-label-container-color: #000;
  --mdc-slider-label-label-text-color: #fff;
  --mdc-slider-with-overlap-handle-outline-color: #fff;
  --mdc-slider-with-tick-marks-disabled-container-color: #000;
}
html {
  --mat-slider-ripple-color: #c91019;
  --mat-slider-hover-state-layer-color: rgba(201, 16, 25, 0.05);
  --mat-slider-focus-state-layer-color: rgba(201, 16, 25, 0.2);
  --mat-slider-value-indicator-opacity: 0.6;
}
html .mat-accent {
  --mdc-slider-handle-color: #5d6a72;
  --mdc-slider-focus-handle-color: #5d6a72;
  --mdc-slider-hover-handle-color: #5d6a72;
  --mdc-slider-active-track-color: #5d6a72;
  --mdc-slider-inactive-track-color: #5d6a72;
  --mdc-slider-with-tick-marks-inactive-container-color: #5d6a72;
  --mdc-slider-with-tick-marks-active-container-color: white;
}
html .mat-accent {
  --mat-slider-ripple-color: #5d6a72;
  --mat-slider-hover-state-layer-color: rgba(93, 106, 114, 0.05);
  --mat-slider-focus-state-layer-color: rgba(93, 106, 114, 0.2);
}
html .mat-warn {
  --mdc-slider-handle-color: #e67300;
  --mdc-slider-focus-handle-color: #e67300;
  --mdc-slider-hover-handle-color: #e67300;
  --mdc-slider-active-track-color: #e67300;
  --mdc-slider-inactive-track-color: #e67300;
  --mdc-slider-with-tick-marks-inactive-container-color: #e67300;
  --mdc-slider-with-tick-marks-active-container-color: white;
}
html .mat-warn {
  --mat-slider-ripple-color: #e67300;
  --mat-slider-hover-state-layer-color: rgba(230, 115, 0, 0.05);
  --mat-slider-focus-state-layer-color: rgba(230, 115, 0, 0.2);
}

html {
  --mdc-slider-label-label-text-font: Roboto, sans-serif;
  --mdc-slider-label-label-text-size: 14px;
  --mdc-slider-label-label-text-line-height: 22px;
  --mdc-slider-label-label-text-tracking: 0.0071428571em;
  --mdc-slider-label-label-text-weight: 500;
}